import {
  Record as DatoCmsStructuredRecord,
  Paragraph,
  Span,
  StructuredText,
  isHeading,
  isLink,
  isStructuredText,
} from "datocms-structured-text-utils";
import { graphql } from "gatsby";
import {
  DatoCmsBlockList,
  DatoCmsCalloutBanner,
  DatoCmsChecklistBlock,
  DatoCmsCollapsableSection,
  DatoCmsCtaBlock,
  DatoCmsCustomHtmlBlock,
  DatoCmsDataCardGrid,
  DatoCmsDocsPage,
  DatoCmsFaqQuestion,
  DatoCmsFeatureBlockWithIcon,
  DatoCmsFileField,
  DatoCmsFluid,
  DatoCmsHtmlWysiwygBlock,
  DatoCmsIframeEmbed,
  DatoCmsImageBlock,
  DatoCmsInArticleStoryGrid,
  DatoCmsPerson,
  DatoCmsProductFeatureCard,
  DatoCmsProductFeatureCardGrid,
  DatoCmsPullQuote,
  DatoCmsStoryDigest,
  DatoCmsStoryEmbed,
  DatoCmsStructuredTextBlock,
  DatoCmsVideoBlock,
  Scalars,
} from "../../graphql-types";
import {
  DeepNonNullableAndRequired,
  UnknownObject,
} from "../types/helper.types";
import { isObject } from "./typeChecks.utils";
import { RenderBlockContext, renderNodeRule } from "react-datocms";
import BlockList from "../components/blocks/BlockList.block";
import CTABlock from "../components/blocks/CTABlock.block";
import CalloutBanner from "../components/blocks/CalloutBanner.block";
import ChecklistBlock from "../components/blocks/Checklist.block";
import CollapsableSection from "../components/blocks/CollapsableSection.block";
import CustomHtmlBlock from "../components/blocks/CustomHtml.block";
import DataCardGrid from "../components/blocks/DataCardGrid.block";
import DevUnknownBlock from "../components/blocks/DevUnknownBlock.block";
import HtmlWysiwygBlock from "../components/blocks/HtmlWysiwygBlock.block";
import IframeEmbed from "../components/blocks/IframeEmbed.block";
import ImageBlock from "../components/blocks/ImageBlock.block";
import PullQuote from "../components/blocks/PullQuote.block";
import StoryEmbed from "../components/blocks/StoryEmbed.block";
import StructuredTextBlock from "../components/blocks/StructuredTextBlock.block";
import VideoBlock from "../components/blocks/VideoBlock.block";
import { createElement } from "react";
import SmartLink from "../components/basic/SmartLink";
import LinkedHeading from "../components/utilities/LinkedHeading";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import ProductFeatureCardGrid from "../components/blocks/ProductFeatureCardGrid.block";
import ProductFeatureCard from "../components/blocks/ProductFeatureCard.block";
import InArticleStoryGrid from "../components/blocks/InArticleStoryGrid.block";

export const TinesOrganizationRecordId = "69358276";

export const isTinesEmployee = (person?: DatoCmsPerson | null) =>
  `${person?.organization?.id}` === TinesOrganizationRecordId;

export type ValidFluidImageField = DatoCmsFileField & { fluid: ValidFluid };
export const isValidFluidImageField = (
  field?: DatoCmsFileField | UnknownObject | null | unknown
): field is ValidFluidImageField => {
  return isObject(field) && isValidFluid(field.fluid);
};
export const isValidImageField = (
  field?: DatoCmsFileField | UnknownObject | null | unknown
) => isObject(field) && ("url" in field || isValidFluidImageField(field));

export type ValidFluid = DeepNonNullableAndRequired<DatoCmsFluid>;
export const isValidFluid = (
  fluid?: DatoCmsFluid | unknown | null
): fluid is ValidFluid => {
  return !!(
    isObject(fluid) &&
    fluid.base64 &&
    fluid.src &&
    fluid.srcSet &&
    fluid.aspectRatio &&
    fluid.width &&
    fluid.height
  );
};

/**
 * Block: Image Block
 */
export type ValidImageBlock = Required<DatoCmsImageBlock> & {
  image?: ValidFluidImageField;
  caption?: string;
};
export const isValidImageBlockRecord = (
  record?: DatoCmsStructuredRecord | null
): record is ValidImageBlock & {
  __typename: "DatoCmsImageBlock";
} => {
  return !!(
    record?.__typename.includes("ImageBlock") && isValidImageField(record.image)
  );
};

/**
 * Block: Pull Quote
 */
export type ValidPullQuote = DatoCmsPullQuote & {
  __typename: "DatoCmsPullQuote";
  content: string;
};
export const isValidPullQuoteRecord = (
  record?: DatoCmsStructuredRecord | null
): record is ValidPullQuote => {
  return !!(
    record?.__typename.includes("PullQuote") &&
    (record as Partial<DatoCmsPullQuote>).content
  );
};

/**
 * Block: Custom HTML Block
 */
export type ValidCustomHtmlBlock = DatoCmsCustomHtmlBlock & {
  __typename: "DatoCmsCustomHtmlBlock";
};
export const isValidCustomHtmlBlock = (
  record?: DatoCmsStructuredRecord | null
): record is ValidCustomHtmlBlock => {
  const r = record as ValidCustomHtmlBlock;
  return !!(r?.__typename.includes("CustomHtmlBlock") && r.htmlContent);
};

/**
 * Block: Checklist Block
 */
export type ValidChecklistBlock = DatoCmsChecklistBlock & {
  __typename: "DatoCmsChecklistBlock";
  listContent: StructuredText;
};
export const isValidChecklistBlock = (
  record?: DatoCmsStructuredRecord | null
): record is ValidChecklistBlock => {
  const r = record as ValidChecklistBlock;
  return !!(
    r?.__typename.includes("ChecklistBlock") &&
    r.listContent &&
    isStructuredText(r.listContent)
  );
};

/**
 * Block: Video Embed
 */
export type ValidVideoBlock = DatoCmsVideoBlock & {
  __typename: "DatoCmsVideoBlock";
};
export const isValidVideoBlock = (
  record?: DatoCmsStructuredRecord | null
): record is ValidVideoBlock => {
  const r = record as ValidVideoBlock;
  return !!(r?.__typename.includes("VideoBlock") && (r.url || r.videoFile));
};

/**
 * Block: Iframe Embed
 */
export type ValidIframeEmbed = DatoCmsIframeEmbed & {
  __typename: "DatoCmsIframeEmbed";
  src: string;
};
export const isValidIframeEmbed = (
  record?: DatoCmsStructuredRecord | null
): record is ValidIframeEmbed => {
  return !!(
    record?.__typename.includes("IframeEmbed") &&
    (record as Partial<DatoCmsIframeEmbed>).src
  );
};

/**
 * Block: Story Embed
 */
export type ValidStoryEmbed = DatoCmsStoryEmbed & {
  __typename: "DatoCmsStoryEmbed";
  storyUrl?: string;
  story?: DatoCmsStoryDigest;
};
export const isValidStoryEmbed = (
  record?: DatoCmsStructuredRecord | null
): record is ValidStoryEmbed => {
  return !!record?.__typename.includes("StoryEmbed");
};

/**
 * Block: Story Embed
 */
export type ValidInArticleStoryGrid = DatoCmsInArticleStoryGrid & {
  __typename: "DatoCmsInArticleStoryGrid";
};
export const isValidInArticleStoryGrid = (
  record?: DatoCmsStructuredRecord | null
): record is ValidInArticleStoryGrid => {
  return !!record?.__typename.includes("InArticleStoryGrid");
};

/**
 * Block: CTA Block
 */

export type ValidCtaBlock = DatoCmsCtaBlock & {
  __typename: "DatoCmsCtaBlock";
};
export const isValidCtaBlock = (
  record?: DatoCmsStructuredRecord | null
): record is ValidCtaBlock => {
  const r = record as Partial<DatoCmsCtaBlock>;
  return !!(
    record?.__typename.includes("CtaBlock") &&
    r.heading &&
    r.buttonLink
  );
};

/**
 * Block: Structured Text Block
 */

export type ValidStructuredTextBlock = DatoCmsStructuredTextBlock & {
  __typename: "DatoCmsStructuredTextBlock";
  structuredText: DatoCmsStructuredTextBlock["structuredText"] & {
    blocks?: DatoCmsStructuredRecord[] | null | undefined;
    value: Scalars["JSON"];
  };
};
export const isValidStructuredTextBlock = (
  record?: DatoCmsStructuredRecord | null
): record is ValidStructuredTextBlock => {
  return !!(
    record?.__typename.includes("StructuredTextBlock") &&
    (record as Partial<DatoCmsStructuredTextBlock>).structuredText?.value
  );
};

/**
 * Block: Feature Block with Icon
 */

export type ValidFeatureBlockWithIcon = DatoCmsFeatureBlockWithIcon & {
  __typename: "DatoCmsFeatureBlockWithIcon";
  heading?: string | null;
  content?: string | null;
};
export const isValidFeatureBlockWithIcon = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidFeatureBlockWithIcon => {
  return !!record?.__typename?.includes("FeatureBlockWithIcon");
};

/**
 * Block: FAQ Question
 */

export type ValidFaqQuestion = DatoCmsFaqQuestion & {
  __typename: "DatoCmsFaqQuestion";
  question: string;
  answer: string;
};
export const isValidFaqQuestion = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidFaqQuestion => {
  return !!(
    (record as DatoCmsFaqQuestion).question &&
    (record as DatoCmsFaqQuestion).answer
  );
};

/**
 * Block: HTML Wysiwyg Block
 */

export type ValidHtmlWysiwygBlock = DatoCmsHtmlWysiwygBlock & {
  __typename: "DatoCmsHtmlWysiwygBlock";
  content: string;
};
export const isValidHtmlWysiwygBlock = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidHtmlWysiwygBlock => {
  return !!(
    record?.__typename?.includes("HtmlWysiwygBlock") &&
    (record as DatoCmsHtmlWysiwygBlock).content
  );
};

/**
 * Block: Data Card Grid block
 */

export type ValidDataCardGridBlock = DatoCmsDataCardGrid & {
  __typename: "DatoCmsDataCardGrid";
  content: string;
};
export const isValidDataCardGridBlock = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidDataCardGridBlock => {
  return !!(
    record?.__typename?.includes("DataCardGrid") &&
    (record as DatoCmsDataCardGrid).cards
  );
};

/**
 * Block: Product feature card grid
 */

export type ValidProductFeatureCardGrid = DatoCmsProductFeatureCardGrid & {
  __typename: "DatoCmsProductFeatureCardGrid";
  features: ValidProductFeatureCard[];
};
export const isValidProductFeatureCardGrid = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidProductFeatureCardGrid => {
  return !!(
    record?.__typename?.includes("ProductFeatureCardGrid") &&
    (record as DatoCmsProductFeatureCardGrid).features
  );
};

/**
 * Block: Product feature card
 */

export type ValidProductFeatureCard = DatoCmsProductFeatureCard & {
  __typename: "DatoCmsProductFeatureCard";
  featureName: string;
};
export const isValidProductFeatureCard = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidProductFeatureCard => {
  return !!(
    record?.__typename?.includes("ProductFeatureCard") &&
    !record?.__typename?.includes("ProductFeatureCardGrid") &&
    (record as DatoCmsProductFeatureCard).featureName
  );
};

/**
 * Block: Callout Banner Block
 */

export type ValidCalloutBanner = DatoCmsCalloutBanner & {
  __typename: "DatoCmsCalloutBanner";
  content: string;
};
export const isValidCalloutBanner = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidCalloutBanner => {
  return !!(
    record?.__typename?.includes("CalloutBanner") &&
    (record as DatoCmsCalloutBanner).content
  );
};

export const docsPageTreeFragment = graphql`
  fragment DocsPageTree on DatoCmsDocsPage {
    # Walk 6 layers up. Shouldn't really be any deeper than this.
    treeParent {
      id: originalId
      slug
      title
      treeParent {
        id: originalId
        slug
        title
        treeParent {
          id: originalId
          slug
          title
          treeParent {
            id: originalId
            slug
            title
            treeParent {
              id: originalId
              slug
              title
              treeParent {
                id: originalId
                slug
                title
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Block: Block list
 */

export type ValidBlockList = DatoCmsBlockList & {
  __typename: "DatoCmsBlockList";
  content: string;
};
export const isValidBlockListBlock = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidHtmlWysiwygBlock => {
  return !!(
    record?.__typename?.includes("BlockList") &&
    (record as DatoCmsBlockList).listType &&
    (record as DatoCmsBlockList).listItems
  );
};

/**
 * Block: Collapsable Section
 */

export type ValidCollapsableSection = DatoCmsCollapsableSection & {
  __typename: "DatoCmsCollapsableSection";
  content: string;
};
export const isValidCollapsableSectionBlock = (
  record?: Partial<DatoCmsStructuredRecord> | null
): record is ValidCollapsableSection => {
  return !!(
    record?.__typename?.includes("CollapsableSection") &&
    (record as DatoCmsCollapsableSection).heading
  );
};

export const createDocsLinkFromRecursiveTreeQuery = (
  docsPage: DatoCmsDocsPage
) => {
  const snippetsReversed = [`${docsPage.slug ?? ""}`];
  let treeParent = docsPage.treeParent;
  while (treeParent?.slug) {
    snippetsReversed.push(treeParent.slug);
    treeParent = treeParent.treeParent;
  }
  snippetsReversed.push("/docs");
  return snippetsReversed.reverse().join("/");
};

export const isEmptyStructuredText = (
  structuredText?: StructuredText | null
) => {
  if (!structuredText || !structuredText.value) return true;
  const { children } = structuredText.value.document;
  return (
    children.length === 0 ||
    (children.length === 1 &&
      (children[0] as Paragraph).children?.every(c => !(c as Span).value))
  );
};

export const blockRendererFactory = (options?: {
  lazyLoadImages?: boolean;
  linkedHeadingMaxLevel?: 2 | 3 | 4 | 5 | 6 | "off";
}) =>
  function RenderBlock(context: RenderBlockContext<DatoCmsStructuredRecord>) {
    const { record } = context;
    if (isValidImageBlockRecord(record))
      return <ImageBlock record={record} lazy={options?.lazyLoadImages} />;
    if (isValidPullQuoteRecord(record)) return <PullQuote record={record} />;
    if (isValidVideoBlock(record)) return <VideoBlock record={record} />;
    if (isValidIframeEmbed(record)) return <IframeEmbed record={record} />;
    if (isValidStoryEmbed(record)) return <StoryEmbed record={record} />;
    if (isValidCtaBlock(record)) return <CTABlock record={record} />;
    if (isValidCustomHtmlBlock(record))
      return <CustomHtmlBlock record={record} />;
    if (isValidChecklistBlock(record))
      return <ChecklistBlock record={record} />;
    if (isValidHtmlWysiwygBlock(record))
      return <HtmlWysiwygBlock record={record} />;
    if (isValidCalloutBanner(record)) return <CalloutBanner record={record} />;
    if (isValidBlockListBlock(record)) return <BlockList record={record} />;
    if (isValidCollapsableSectionBlock(record))
      return <CollapsableSection record={record} />;
    if (isValidStructuredTextBlock(record))
      return (
        <StructuredTextBlock
          record={record}
          lazyLoadImages={options?.lazyLoadImages}
          linkedHeadingMaxLevel={options?.linkedHeadingMaxLevel}
        />
      );
    if (isValidDataCardGridBlock(record))
      return <DataCardGrid record={record} />;
    if (isValidProductFeatureCardGrid(record))
      return <ProductFeatureCardGrid record={record} />;
    if (isValidProductFeatureCard(record))
      return <ProductFeatureCard record={record} />;
    if (isValidInArticleStoryGrid(record))
      return <InArticleStoryGrid record={record} />;
    return <DevUnknownBlock record={record} />;
  };

export const customNodeRulesFactory = (options?: {
  linkedHeadingMaxLevel?: 2 | 3 | 4 | 5 | 6 | "off";
}) => [
  renderNodeRule(isHeading, transform => {
    const { node, children, key } = transform;
    if (
      options &&
      options.linkedHeadingMaxLevel !== "off" &&
      node.level <= (options.linkedHeadingMaxLevel ?? 3)
    ) {
      const plainText = toPlainText(node) ?? "";
      return (
        <LinkedHeading
          key={key}
          level={node.level as 1 | 2 | 3}
          plainText={plainText}
          children={children}
        />
      );
    } else {
      return createElement(`h${node.level}`, {
        key,
        children,
      });
    }
  }),
  renderNodeRule(isLink, transform => {
    const { node, children, key } = transform;
    const attr = {} as Record<string, unknown>;
    node.meta?.forEach(({ id, value }) => {
      attr[id] = value;
    });
    return (
      <SmartLink key={key} src={node.url} attr={attr} children={children} />
    );
  }),
];
