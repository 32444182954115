import {
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import productDemoVideoPreview from "../../../static/demoVideos/tines-product-demo-preview-1652w.mp4";
import productDemoVideo from "../../../static/demoVideos/tines-product-demo-1652w.mp4";
import mainDemoVideoPreview from "../../../static/demoVideos/tines-main-demo-with-eoin-hinchy-preview.mp4";
import mainDemoVideo from "../../../static/demoVideos/tines-main-demo-with-eoin-hinchy.mp4";
import { useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { cx } from "linaria";
import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";

const VideoFrame = styled.div`
  position: relative;
  border-radius: 12px;
  border: 6px solid ${colors.purple200};
  background-color: ${colors.purple50};
  overflow: hidden;
  ${fromTabletMd} {
    border-radius: 24px;
    border-width: 8px;
  }
  z-index: 1;
  transform: translateZ(0);
  background-color: ${colors.white};
  video {
    display: block;
    background-color: ${colors.white};
    width: calc(100% + 2px);
    margin: -1px;
  }
`;

const DemoVideo = styled.video`
  position: absolute;
  top: -1px;
  left: 0px;
  bottom: -2px;
  right: -1px;
  @keyframes demoVideoEnter {
    from {
      transform: scale(0.9);
      filter: brightness(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      filter: brightness(1);
      opacity: 1;
    }
  }
  animation: demoVideoEnter 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 1;
  border-radius: 6px;
  ${fromTabletMd} {
    border-radius: 12px;
  }
`;

const PreviewVideoWrap = styled.div`
  background-color: ${colors.white};
  transition: opacity 0.75s, transform 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  @keyframes previewVideoEnter {
    from {
      transform: scale(1.2);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: previewVideoEnter 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  .hasStartedPlaying & {
    transform: scale(1.2);
    opacity: 0;
    pointer-events: none;
  }
`;

const PreviewVideo = styled.video`
  opacity: 0.9;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

const PlayButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.purple300};
  border: 1px solid ${colors.purple500};
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;
  svg {
    display: block;
  }
  transform: scale(0.9);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const PlayButtonPulse = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.purple500};
  border: 1px solid ${colors.purple400};
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 0;
  border: 0;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.2rem;
  height: 7.2rem;
  ${fromTablet} {
    top: 48%;
    width: 8.2rem;
    height: 8.2rem;
  }
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
  ${PlayButtonInner} {
    transform: scale(0.5);
  }
  &.visible {
    opacity: 1;
    ${PlayButtonPulse} {
      @keyframes PlayButtonPulseAnimation {
        from {
          opacity: 0.2;
          transform: scale(1);
        }
        to {
          opacity: 0;
          transform: scale(2.5);
        }
      }
      animation: PlayButtonPulseAnimation 1.8s
        cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
      animation-delay: 1.5s;
    }
    ${PlayButtonInner} {
      transform: scale(1);
    }
  }
  .hasStartedPlaying & {
    opacity: 0;
    ${PlayButtonInner} {
      transform: scale(0.9);
    }
  }
  svg {
    ${onlyPhones} {
      width: 28px;
    }
  }
  &:hover {
    ${PlayButtonInner} {
      background-color: ${colors.purple50};
      transition: transform 0.2s;
      transform: scale(1.05);
    }
    svg {
      fill: ${colors.purple400};
    }
  }
`;

export const ProductDemoVideoFrame = (props: {
  version: "home" | "product";
}) => {
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const [playButtonInView, setPlayButtonInView] = useState(false);
  const videoFrameRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const playButtonRef = useRef<HTMLButtonElement>(null);
  const play = () => {
    setHasStartedPlaying(true);
    setTimeout(() => {
      const video = videoRef.current;
      if (video) {
        for (let i = 0; i < video.textTracks.length; i++) {
          video.textTracks[i].mode = "hidden";
        }
        try {
          video.play();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      }
    }, 400);
  };
  useOnMount(() => {
    const videoFrameObserver = new IntersectionObserver(entries => {
      if (!entries[0].isIntersecting) {
        if (videoRef.current) {
          if (!videoRef.current.paused) {
            videoRef.current?.pause();
          }
        }
      }
    });
    if (videoFrameRef.current)
      videoFrameObserver.observe(videoFrameRef.current);
    const playButtonObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setTimeout(() => {
          setPlayButtonInView(true);
        }, 1000);
        playButtonObserver.disconnect();
      }
    });
    if (playButtonRef.current)
      playButtonObserver.observe(playButtonRef.current);
    return () => {
      videoFrameObserver.disconnect();
      playButtonObserver.disconnect();
    };
  });
  const reset = () => {
    setHasStartedPlaying(false);
    setPlayButtonInView(false);
    setTimeout(() => {
      setPlayButtonInView(true);
    }, 100);
  };

  const videoAspectRatio =
    props.version === "product" ? "1652/1020" : "1920/1080";

  return (
    <VideoFrame
      className={cx(hasStartedPlaying && "hasStartedPlaying")}
      ref={videoFrameRef}
      style={{
        aspectRatio:
          props.version === "product"
            ? `${1652 + 8 * 2}/${1020 + 8 * 2}`
            : `${1920 + 8 * 2}/${1080 + 8 * 2}`,
      }}
    >
      {hasStartedPlaying && (
        <DemoVideo
          controls
          preload="metadata"
          ref={videoRef}
          onEnded={reset}
          style={{
            aspectRatio: videoAspectRatio,
          }}
        >
          <source
            src={props.version === "product" ? productDemoVideo : mainDemoVideo}
            type="video/mp4"
          />
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={
              props.version === "product"
                ? "/demoVideos/tines-product-demo-subtitles.vtt"
                : "/demoVideos/tines-main-demo-with-eoin-hinchy-subtitles.vtt"
            }
            default
          />
        </DemoVideo>
      )}
      <PreviewVideoWrap onClick={play}>
        <PreviewVideo
          src={
            props.version === "product"
              ? productDemoVideoPreview
              : mainDemoVideoPreview
          }
          autoPlay
          playsInline
          loop
          muted
          style={{
            aspectRatio: videoAspectRatio,
          }}
        />
      </PreviewVideoWrap>
      <PlayButton
        onClick={play}
        ref={playButtonRef}
        className={cx(playButtonInView && "visible")}
      >
        <PlayButtonPulse />
        <PlayButtonPulse />
        <PlayButtonInner>
          <svg
            width="33"
            height="31"
            viewBox="0 0 33 31"
            fill={colors.purple50}
          >
            <path
              d="M8.58399 30.077L31.582 16.799C32.582 16.2217 32.582 14.7783 31.582 14.201L8.58399 0.923033C7.58399 0.345682 6.33399 1.06737 6.33399 2.22207L6.33399 28.7779C6.33399 29.9326 7.58399 30.6543 8.58399 30.077Z"
              stroke={colors.purple500}
            />
          </svg>
        </PlayButtonInner>
      </PlayButton>
    </VideoFrame>
  );
};
