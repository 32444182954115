import { styled } from "@linaria/react";
import Spacing from "../layout/Spacing";
import {
  BuildRunMonitorAfterHeading,
  BuildRunMonitorHeading,
  BuildRunMonitorSection,
  BuildRunMonitorSectionHeader,
  BuildRunMonitorSectionHeaderIllo,
  ProductPageH3,
  ProductPageSubSection,
  SplitTwoCol,
} from "./_ProductPageSharedComponents";
import { PagesAndPromptsIlloCard } from "./illustrations/PagesAndPromptsIlloCard";
import { rSize } from "../../styles/responsiveSizes.styles";
import { Link } from "gatsby";
import { Paths } from "../../utils/pathBuilders.utils";
import { SafeguardsIlloCard } from "./illustrations/SafeguardsIlloCard";
import {
  GRID_COL_WIDTH_MAX,
  widthInGrid,
} from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  onlyTablets,
  uptoDesktopMl,
} from "../../styles/breakpointsAndMediaQueries.styles";
import runOnAnyEnvSvg from "./illustrations/runOnAnyEnv.svg";
import sectionIllo from "./illustrations/runSectionIllo.svg";
import { ThreeRowCustomerLogoMarquee } from "./ThreeRowCustomerLogoMarquee";

const Figure = styled.figure`
  margin: 0;
  figcaption {
    margin-top: ${rSize("gap")};
    padding-right: 1em;
    ${fromTabletLg} {
      padding-right: ${widthInGrid(1, 1)};
    }
    p {
      font-size: 1.4rem;
      line-height: 1.35;
    }
    a {
      font-weight: 700;
    }
  }
`;

const CustomerLogosCard = styled.div`
  position: relative;
  border-radius: ${rSize("radius")};
  --unit: 2em;
  padding-top: var(--unit);
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "figure" "header";
  grid-row-gap: 2em;
  padding-bottom: ${rSize("xl")};
  grid-template-rows: minmax(0, 1fr) auto;
  ${onlyPhones} {
    border: 1px solid ${colors.green200};
    border-radius: ${rSize("radius")};
  }
  ${fromTablet} {
    grid-template-rows: minmax(0, 1fr) minmax(12em, auto);
    --unit: 4em;
    grid-row-gap: 3em;
    --leftOffset: calc(
      ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
    );
    &:before {
      z-index: 0;
      content: "";
      border: 1px solid ${colors.green200};
      border-radius: ${rSize("radius")};
      position: absolute;
      pointer-events: none;
      top: 0;
      left: var(--leftOffset);
      right: 0;
      bottom: 0;
    }
  }
  ${fromDesktopMd} {
    grid-template-rows: minmax(0, 1fr) minmax(10em, auto);
  }
  header {
    grid-area: header;
    position: relative;
    ${onlyPhones} {
      padding-left: 1em;
      padding-right: 1em;
    }
    ${fromTablet} {
      max-width: 16em;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.35;
    }
  }
  figure {
    grid-area: figure;
    position: relative;
    margin: 2.5em 0 0 var(--leftOffset);
    ${onlyPhones} {
      margin-top: 2em;
      margin-bottom: 2em;
    }
    ${onlyTablets} {
      margin-top: 1em;
    }
  }
`;

const RunOnAnyEnvCard = styled.div`
  position: relative;
  border-radius: ${rSize("radius")};
  --unit: 2em;
  padding-top: var(--unit);
  display: grid;
  grid-template-areas: "figure" "header";
  grid-row-gap: 2em;
  padding-bottom: ${rSize("xl")};
  grid-template-rows: minmax(0, 1fr) auto;
  ${onlyPhones} {
    border: 1px solid ${colors.green200};
    border-radius: ${rSize("radius")};
  }
  ${fromTablet} {
    grid-template-rows: minmax(0, 1fr) minmax(12em, auto);
    --unit: 4em;
    grid-row-gap: 3em;
    &:before {
      z-index: 0;
      content: "";
      border: 1px solid ${colors.green200};
      border-radius: ${rSize("radius")};
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
      bottom: 0;
    }
  }
  ${fromDesktopMd} {
    grid-template-rows: minmax(0, 1fr) minmax(10em, auto);
  }
  header {
    grid-area: header;
    position: relative;
    ${onlyPhones} {
      padding-left: 1em;
      padding-right: 1em;
    }
    ${fromTablet} {
      max-width: 23em;
    }
    ${fromTablet} {
      padding-left: ${GRID_COL_WIDTH_MAX / 2}px;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.35;
    }
  }
  figure {
    grid-area: figure;
    position: relative;
    margin: 0;
    img {
      ${uptoDesktopMl} {
        max-width: 100%;
      }
      ${fromTablet} {
        margin-left: 16px;
      }
      ${fromDesktop} {
        margin-left: 33px;
      }
    }
  }
`;

const SplitTwoOne = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktop} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    > * {
      &:first-child {
        grid-column-end: span 8;
      }
      &:last-child {
        grid-column-end: span 4;
      }
    }
  }
`;

export const ProductPageRunSection = () => (
  <BuildRunMonitorSection id="run" theme="green">
    <BuildRunMonitorSectionHeader>
      <BuildRunMonitorHeading heading="Run" />
      <BuildRunMonitorAfterHeading maxWidth="23em">
        The build and execution environments are the same, which means your most
        important workflows are ready to run and make an immediate impact. All
        without compromising on security or scale.
      </BuildRunMonitorAfterHeading>
      <BuildRunMonitorSectionHeaderIllo>
        <img src={sectionIllo} />
      </BuildRunMonitorSectionHeaderIllo>
    </BuildRunMonitorSectionHeader>

    <Spacing size="subsectionMargin" />

    <ProductPageSubSection>
      <SplitTwoCol>
        <Figure>
          <PagesAndPromptsIlloCard />
          <figcaption>
            <p>
              Create interactions outside of the storyboard through{" "}
              <Link to={Paths.buildApps()}>pages</Link> and{" "}
              <strong>prompts</strong>. Use them to gather context, confirm
              information, or add transparency to workflow outputs. Control and
              pause workflows through human confirmation or interactions.
            </p>
          </figcaption>
        </Figure>
        <Figure>
          <SafeguardsIlloCard />
          <figcaption>
            <p>
              <strong>Built-in safeguards</strong> like credential management,
              roles, change control, error handling, smart retries, and so much
              more at the action or workflow level, mean your team is always in
              the loop. If external tools behave unexpectedly, any member can go
              into the workflow and fix the issue.
            </p>
          </figcaption>
        </Figure>
      </SplitTwoCol>
    </ProductPageSubSection>

    <Spacing size="subsectionMargin" />

    <ProductPageSubSection>
      <SplitTwoOne>
        <CustomerLogosCard>
          <header>
            <ProductPageH3 style={{ maxWidth: "8em" }}>
              Trusted by industry leaders
            </ProductPageH3>
            <p>
              Businesses of all sizes from global enterprises to national
              laboratories automate processes at scale with Tines.
            </p>
          </header>
          <figure>
            <ThreeRowCustomerLogoMarquee />
          </figure>
        </CustomerLogosCard>
        <RunOnAnyEnvCard>
          <header>
            <ProductPageH3 style={{ maxWidth: "8em" }}>
              Run on any environment
            </ProductPageH3>
            <p>
              Tines supports every tech stack, wherever it’s hosted. We offer
              self-hosted deployments or hybrid deployments through our tunnels
              with controls defined by you.
            </p>
          </header>
          <figure>
            <img src={runOnAnyEnvSvg} />
          </figure>
        </RunOnAnyEnvCard>
      </SplitTwoOne>
    </ProductPageSubSection>
    <Spacing size="xl" />
  </BuildRunMonitorSection>
);
