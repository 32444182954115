import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode } from "react";
import { color } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";
import { fromDesktopMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { cx } from "linaria";

type Props = {
  className?: string;
  color: BrandColorNameV4;
  sourceName: ReactNode;
  sourceDetails: ReactNode;
  sourceLogo?: ReactNode;
  fontSize?: "large" | "medium" | "small";
  footerLayout?: "even-split" | "space-between";
};

type P = {
  color: BrandColorNameV4;
};

const Card = styled.div<P>`
  background-color: ${p => color(p.color, 100)};
  color: ${p => color(p.color, 700)};
  border: ${p => `1px solid ${color(p.color, 400)}`};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
`;

const Content = styled.div<P>`
  padding: 1.5em;
`;

const QuoteBody = styled.div`
  font-family: ${font("serif")};
  line-height: 1.4;
  max-width: 20em;
  letter-spacing: -0.01em;
  font-size: 1.6rem;
  line-height: 1.4;
  font-size: 500;
  .medium & {
    font-size: 1.8rem;
    line-height: 1.35;
    font-size: 400;
    ${fromDesktopMd} {
      font-size: 2rem;
    }
  }
  .large & {
    font-size: 2.2rem;
    line-height: 1.3;
    font-size: 400;
    ${fromDesktopMd} {
      font-size: 2.4rem;
      line-height: 1.3;
    }
  }
  svg {
    height: 1em;
    width: auto;
    margin-right: 0.25em;
    transform: translateY(0.15em);
  }
`;

const SourceName = styled.div`
  font-weight: 600;
`;

const Footer = styled.footer`
  padding: 0 1.5em 1.5em;
  font-size: 1.4rem;
  line-height: 1.25;
  font-weight: 500;
  svg,
  img {
    display: block;
    max-width: 100%;
  }
  &.even-split {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${rSize("gap")};
  }
  &.space-between.hasLogo {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    > *:nth-child(1) {
      margin-left: 1em;
    }
  }
`;

const QuoteCardNeue = (props: PropsWithChildren<Props>) => {
  return (
    <Card
      color={props.color}
      className={cx(props.className, props.fontSize ?? "medium")}
    >
      <Content color={props.color}>
        <QuoteBody>
          <svg width="38" height="34" viewBox="0 0 38 34" fill="none">
            <rect width="38" height="34" rx="8" fill="currentColor" />
            <path
              d="M18.6647 15.646H18.1341C17.179 15.646 16.613 16.4283 16.613 17.9574V21.229H13.1816V17.7085C13.1816 14.5792 14.915 12.7656 17.7803 12.7656H18.6647V15.646ZM24.8199 15.646H24.2892C23.2988 15.646 22.7328 16.4283 22.7328 17.9574V21.229H19.3014V17.7085C19.3014 14.5792 21.0348 12.7656 23.9355 12.7656H24.8199V15.646Z"
              fill="#FCF9F5"
            />
          </svg>
          {props.children}
        </QuoteBody>
      </Content>
      <Footer
        className={cx(
          props.footerLayout ?? "space-between",
          props.sourceLogo && "hasLogo"
        )}
      >
        {props.sourceLogo && <div>{props.sourceLogo}</div>}
        <div>
          <SourceName>{props.sourceName}</SourceName>
          <div>{props.sourceDetails}</div>
        </div>
      </Footer>
    </Card>
  );
};

export default QuoteCardNeue;
