import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import CustomersPageCaseStudyGridSection from "../components/customers/CustomersPageCaseStudyGridSection";
import { graphql } from "gatsby";
import { CustomersPageQuery, DatoCmsCaseStudy } from "../../graphql-types";
import AccreditationParallaxGrid from "../components/customers/AccreditationParallaxGrid";
import { styled } from "@linaria/react";
import ogImage from "../../static/images/og/tines-customers.png";
import UptoTabletMd from "../components/mediaQueries/UptoTabletMd";
import RecognitionAndBadgesSection from "../components/reusableSections/RecognitionAndBadgesSection";
import HeroSection, {
  HeroSectionCenteredIllustrationWrap,
  HeroSectionDescription,
  HeroSectionSplit8Col,
  HeroSectionSplitHeaderWrap,
} from "../components/sections/HeroSection";
import AllCaps from "../components/typography/AllCaps";
import { CaseStudiesPageIllustration } from "../components/illustrations/neue/CaseStudiesPageIllustration";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";

const PageContent = styled.div`
  overflow: hidden;
  overflow: clip;
`;

const PageCaseStudies: PageComponent<{
  data: CustomersPageQuery;
}> = props => {
  const caseStudies = props.data.caseStudies.nodes as DatoCmsCaseStudy[];
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title="Case studies"
        description="Discover how companies – from Fortune 10 to startups – apply Tines to transform the way their teams operate."
        image={ogImage}
      />
      <AdminToolBarWithEditInDatoCmsButton itemType="caseStudy" />

      <PageContent>
        <HeroSection
          backdrop="grid"
          backdropColor={colors.purple100}
          contentRelative
        >
          <HeroSectionCenteredIllustrationWrap>
            <CaseStudiesPageIllustration />
          </HeroSectionCenteredIllustrationWrap>

          <HeroSectionSplit8Col>
            <HeroSectionSplitHeaderWrap>
              <AllCaps>Power users, powerful stories</AllCaps>
              <h1>Case studies</h1>
            </HeroSectionSplitHeaderWrap>
            <HeroSectionDescription>
              Our customers share their experiences and successes bringing Tines
              into their organizations. Discover how companies – from Fortune 10
              to startups – apply Tines to transform the way their teams
              operate.
            </HeroSectionDescription>
          </HeroSectionSplit8Col>
        </HeroSection>

        <Spacing size="sectionMargin" />

        <CustomersPageCaseStudyGridSection
          caseStudies={caseStudies.slice(0, 9)}
        />

        <Spacing size="sectionMargin" />

        <UptoTabletMd>
          <RecognitionAndBadgesSection />
          <Spacing size="sectionMargin" />
        </UptoTabletMd>

        <AccreditationParallaxGrid caseStudies={caseStudies.slice(9)} />

        <Spacing size="sectionMargin" />

        <ExplosionCTASection />
      </PageContent>
    </Page>
  );
};

export const customersPageQuery = graphql`
  query CustomersPage {
    caseStudies: allDatoCmsCaseStudy(
      sort: { position: ASC }
      filter: { hiddenInIndex: { ne: true } }
    ) {
      nodes {
        title
        slug
        datePublished
        position
        excerpt
        color
        featuredQuote
        featuredQuoteCover
        featuredData
        featuredDataLabel
        featuredTags
        coverImage {
          url
          width
          height
        }
        customer {
          name
          logoDarkGray {
            url
          }
        }
      }
    }
  }
`;

export default PageCaseStudies;
