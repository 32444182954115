export const FedIdentityVerificationIcon = () => (
  <svg width="83" height="67" viewBox="0 0 83 67" fill="none">
    <path
      d="M47.869 66.5162C65.4662 66.5162 79.7316 65.6818 79.7316 64.6524C79.7316 63.623 65.4662 62.7886 47.869 62.7886C30.2717 62.7886 16.0063 63.623 16.0063 64.6524C16.0063 65.6818 30.2717 66.5162 47.869 66.5162Z"
      fill="#195642"
    />
    <path
      d="M81.6219 37.6492C82.3371 39.6525 81.2931 41.8566 79.2898 42.572L18.0607 64.4388C16.0575 65.1541 13.8532 64.1102 13.138 62.1069L0.805311 27.5752C0.0903097 25.5717 1.13405 23.3678 3.13741 22.6524L64.3665 0.785434C66.3697 0.0700777 68.5738 1.11418 69.2892 3.11735L81.6219 37.6492Z"
      fill="#FCF9F5"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.5319 40.1616L28.8901 41.1647L27.8872 41.5227"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
    />
    <path
      d="M25.6185 42.3335L17.6787 45.169"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
      strokeDasharray="2.41 2.41"
    />
    <path
      d="M16.5444 45.5741L15.5415 45.9321L15.1831 44.9292"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
    />
    <path
      d="M14.3923 42.7143L10.0417 30.5332"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
      strokeDasharray="2.35 2.35"
    />
    <path
      d="M9.64648 29.4261L9.28809 28.423L10.2912 28.0649"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
    />
    <path
      d="M12.5596 27.2546L20.4993 24.4189"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
      strokeDasharray="2.41 2.41"
    />
    <path
      d="M21.6335 24.014L22.6366 23.6558L22.9949 24.6587"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
    />
    <path
      d="M23.7859 26.8735L28.1364 39.0546"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeLinejoin="round"
      strokeDasharray="2.35 2.35"
    />
    <path
      d="M29.5129 24.3566L63.4451 12.2383"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      strokeDasharray="2.13 2.13"
    />
    <path
      d="M31.4819 29.8702L65.4145 17.752"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      strokeDasharray="2.13 2.13"
    />
    <path
      d="M33.5217 35.3579L48.4663 30.021"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      strokeDasharray="2.13 2.13"
    />
    <path
      d="M71.9056 31.2748L17.0066 50.8804L19.0271 56.538L73.9261 36.9325L71.9056 31.2748Z"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.13 2.13"
    />
    <path
      d="M27.4675 37.3045C27.4675 37.3045 25.4665 35.513 24.6656 34.7669C23.8927 34.0475 22.5074 34.2718 21.5388 34.5894C22.4031 33.4888 22.7075 31.9754 22.2126 30.5415C21.4427 28.3104 19.032 27.1179 16.8281 27.8782C14.6247 28.6388 13.4625 31.0639 14.2322 33.2954C14.7269 34.7289 15.8995 35.7331 17.2589 36.0662C16.3003 36.414 15.0713 37.0912 14.9069 38.1342C14.7363 39.2156 14.2904 42.4295 14.2904 42.4295"
      stroke="#195642"
      strokeWidth="0.934426"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.13 2.13"
    />
  </svg>
);
