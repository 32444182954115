import {
  DatoCmsArticleCategory,
  DatoCmsEventCategory,
  DatoCmsWebinarCategory,
} from "../../../graphql-types";
import { PillProps } from "./PillLegacy";
import { getStandardColorThemeName } from "../../styles/colors.styles";

type Props = {
  category:
    | DatoCmsWebinarCategory
    | DatoCmsArticleCategory
    | DatoCmsEventCategory;
} & PillProps;

const CategoryPill = (props: Props) => {
  const color = (props.category as DatoCmsWebinarCategory).color;
  return (
    <strong color={getStandardColorThemeName(color)} {...props}>
      #{props.category.name}
    </strong>
  );
};

export default CategoryPill;
