export const FedProcessAutomationIcon = () => (
  <svg width="87" height="71" viewBox="0 0 87 71" fill="none">
    <path
      d="M47.5989 67.4399C61.5846 67.4399 72.9223 65.4769 72.9223 63.0554C72.9223 60.6339 61.5846 58.6709 47.5989 58.6709C33.6131 58.6709 22.2754 60.6339 22.2754 63.0554C22.2754 65.4769 33.6131 67.4399 47.5989 67.4399Z"
      fill="#32313B"
    />
    <path
      d="M40.4899 65.962C35.2238 65.962 30.242 65.5601 26.4621 64.83C23.9966 64.3539 18.2234 63.2392 18.2234 58.9848C18.2234 54.7306 23.9968 53.6154 26.4621 53.1393C30.242 52.4093 35.2239 52.0073 40.4899 52.0073C45.7558 52.0073 50.7376 52.4093 54.5177 53.1393C56.983 53.6154 62.7564 54.7306 62.7564 58.9848C62.7564 63.2392 56.9829 64.3539 54.5175 64.83C50.7376 65.5601 45.756 65.962 40.4899 65.962ZM32.6597 58.9848C34.8876 59.1918 37.51 59.3264 40.4899 59.3264C43.47 59.3264 46.0922 59.1916 48.3199 58.9848C46.0922 58.7775 43.47 58.6432 40.4899 58.6432C37.51 58.6432 34.8876 58.7775 32.6597 58.9848Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.4899 59.9486C35.2238 59.9486 30.242 59.5464 26.4621 58.8165C23.9966 58.3402 18.2234 57.2254 18.2234 52.9711C18.2234 48.7167 23.9968 47.6017 26.4621 47.1256C30.242 46.3957 35.2239 45.9937 40.4899 45.9937C45.7558 45.9937 50.7376 46.3957 54.5177 47.1256C56.983 47.6017 62.7564 48.7167 62.7564 52.9711C62.7564 57.2254 56.9829 58.3402 54.5175 58.8165C50.7376 59.5464 45.756 59.9486 40.4899 59.9486ZM32.6597 52.9711C34.8876 53.1781 37.51 53.3126 40.4899 53.3126C43.47 53.3126 46.0922 53.1779 48.3199 52.9711C46.0922 52.7638 43.47 52.6295 40.4899 52.6295C37.51 52.6295 34.8876 52.764 32.6597 52.9711Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.49 53.8159C51.8337 53.8159 61.0296 51.9808 61.0296 49.7173C61.0296 47.4537 51.8337 45.6187 40.49 45.6187C29.1463 45.6187 19.9504 47.4537 19.9504 49.7173C19.9504 51.9808 29.1463 53.8159 40.49 53.8159Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.0118 51.1092C54.4964 51.1092 60.5639 50.0261 60.5639 48.6901C60.5639 47.3541 54.4964 46.271 47.0118 46.271C39.5272 46.271 33.4597 47.3541 33.4597 48.6901C33.4597 50.0261 39.5272 51.1092 47.0118 51.1092Z"
      fill="#32313B"
    />
    <path
      d="M40.4899 49.8778C53.8712 49.8778 64.7189 39.0302 64.7189 25.6489C64.7189 12.2676 53.8712 1.41992 40.4899 1.41992C27.1087 1.41992 16.261 12.2676 16.261 25.6489C16.261 39.0302 27.1087 49.8778 40.4899 49.8778Z"
      fill="#FFCCE2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.8359 13.0669C60.4032 16.6001 61.9176 20.9479 61.9176 25.649C61.9176 26.4162 61.8773 27.1738 61.7985 27.9204"
      stroke="#F8F7FC"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.8987 8.93457C54.6136 9.50888 55.2908 10.128 55.9261 10.7878"
      stroke="#F8F7FC"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.2227 59.9294C39.2227 59.9294 47.2588 60.1208 52.3289 59.1641C57.3993 58.2076 61.6086 56.0073 61.6086 56.0073L62.1825 57.3464C62.1824 57.3464 58.9298 61.8427 39.2227 59.9294Z"
      fill="#32313B"
    />
    <path
      d="M26.6799 45.5571C30.5978 48.2804 35.3571 49.8777 40.4899 49.8777C45.6226 49.8777 50.3819 48.2804 54.2998 45.5573C50.3426 45.0333 45.5955 44.7275 40.4899 44.7275C35.3844 44.7275 30.6371 45.0331 26.6799 45.5571Z"
      fill="#B8E5D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.2 10.0991L34.9168 13.2377L38.0555 14.9545L34.9168 16.6713L33.2 19.81L31.4832 16.6713L28.3445 14.9545L31.4832 13.2377L33.2 10.0991Z"
      fill="#F8F7FC"
    />
    <path
      d="M39.5466 20.3364L40.7565 22.5487L42.9689 23.7586L40.7565 24.9687L39.5466 27.1808L38.3366 24.9687L36.1243 23.7586L38.3366 22.5487L39.5466 20.3364Z"
      fill="#F8F7FC"
    />
    <path
      d="M11.3772 70.2471C16.4616 70.2471 20.5833 69.405 20.5833 68.3662C20.5833 67.3274 16.4616 66.4854 11.3772 66.4854C6.29285 66.4854 2.17114 67.3274 2.17114 68.3662C2.17114 69.405 6.29285 70.2471 11.3772 70.2471Z"
      fill="#32313B"
    />
    <path
      d="M1.1001 67.9154C1.1001 69.0039 4.3579 69.8862 8.37683 69.8862C12.3958 69.8862 15.6534 69.0039 15.6534 67.9154V56.394C15.6534 55.3056 12.3956 54.4233 8.37683 54.4233C4.35807 54.4233 1.1001 55.3056 1.1001 56.394V67.9154Z"
      fill="#FBB598"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.37665 58.3646C12.3954 58.3646 15.6532 57.4822 15.6532 56.3937C15.6532 55.3052 12.3954 54.4229 8.37665 54.4229C4.35793 54.4229 1.1001 55.3052 1.1001 56.3937C1.1001 57.4822 4.35793 58.3646 8.37665 58.3646Z"
      fill="#FFD2BF"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8836 57.1521C13.9385 57.1521 15.6043 56.6621 15.6043 56.0577C15.6043 55.4533 13.9385 54.9634 11.8836 54.9634C9.82868 54.9634 8.16284 55.4533 8.16284 56.0577C8.16284 56.6621 9.82868 57.1521 11.8836 57.1521Z"
      fill="#32313B"
    />
    <path
      d="M12.4524 51.8996C12.4524 54.2205 10.7277 56.1019 8.60036 56.1019C6.47297 56.1019 4.74829 54.2205 4.74829 51.8996C4.74829 49.5788 8.60036 44.3706 8.60036 44.3706C8.60036 44.3706 12.4524 49.5788 12.4524 51.8996Z"
      fill="#FFF0D9"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.1414 70.2471C82.2258 70.2471 86.3475 69.405 86.3475 68.3662C86.3475 67.3274 82.2258 66.4854 77.1414 66.4854C72.057 66.4854 67.9353 67.3274 67.9353 68.3662C67.9353 69.405 72.057 70.2471 77.1414 70.2471Z"
      fill="#32313B"
    />
    <path
      d="M66.864 67.9154C66.864 69.0039 70.1218 69.8862 74.1406 69.8862C78.1595 69.8862 81.4173 69.0039 81.4173 67.9154V56.394C81.4173 55.3056 78.1597 54.4233 74.1406 54.4233C70.122 54.4233 66.864 55.3056 66.864 56.394V67.9154Z"
      fill="#FBB598"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.1406 58.3646C78.1593 58.3646 81.4171 57.4822 81.4171 56.3937C81.4171 55.3052 78.1593 54.4229 74.1406 54.4229C70.1218 54.4229 66.864 55.3052 66.864 56.3937C66.864 57.4822 70.1218 58.3646 74.1406 58.3646Z"
      fill="#FFD2BF"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.1416 57.0646C78.3416 57.0646 80.125 56.5158 80.125 55.8389C80.125 55.162 78.3416 54.6133 76.1416 54.6133C73.9416 54.6133 72.1582 55.162 72.1582 55.8389C72.1582 56.5158 73.9416 57.0646 76.1416 57.0646Z"
      fill="#32313B"
    />
    <path
      d="M77.4986 52.0744C77.4986 54.3953 75.7739 56.2767 73.6465 56.2767C71.5191 56.2767 69.7944 54.3953 69.7944 52.0744C69.7944 49.7536 73.6465 44.5454 73.6465 44.5454C73.6465 44.5454 77.4986 49.7536 77.4986 52.0744Z"
      fill="#FFF0D9"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
