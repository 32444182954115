import { styled } from "@linaria/react";
import { brandColorThemeVar } from "../../styles/colors.styles";
import { PropsWithChildren } from "react";
import { cx } from "linaria";

type Props = {
  className?: string;
  dark?: boolean;
  allCaps?: boolean;
  noLetterSpacing?: boolean;
};

const PillGroupDiv = styled.div`
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: 1;
  margin-top: -0.2em;
  margin-left: -0.2em;
  > * {
    margin: 0.2em;
  }
  &.allCaps {
    font-weight: 600;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    font-size: 1.15rem;
  }
  &.noLetterSpacing {
    letter-spacing: 0;
  }
`;

export const PillGroup = (props: PropsWithChildren<Props>) => {
  return (
    <PillGroupDiv
      className={cx(
        props.className,
        props.dark && "dark",
        props.allCaps && "allCaps",
        props.noLetterSpacing && "noLetterSpacing"
      )}
    >
      {props.children}
    </PillGroupDiv>
  );
};

const PillSpan = styled.span`
  white-space: nowrap;
  display: inline-block;
  padding: 0.4em 0.75em;
  border-radius: 2em;
  background-color: ${brandColorThemeVar(200)};
  color: ${brandColorThemeVar(700)};
  line-height: 1;
  &.dark {
    background-color: ${brandColorThemeVar(700)};
    color: ${brandColorThemeVar(50)};
  }
  &.allCaps {
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 1.15rem;
  }
  &.noLetterSpacing {
    letter-spacing: 0;
  }
  &.circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 1.8em;
    height: 1.8em;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Pill = (
  props: PropsWithChildren<
    Props & {
      circle?: boolean;
    }
  >
) => {
  return (
    <PillSpan
      className={cx(
        props.className,
        props.dark && "dark",
        props.allCaps && "allCaps",
        props.circle && "circle"
      )}
    >
      {props.children}
    </PillSpan>
  );
};
