/* eslint-disable @typescript-eslint/no-unsafe-argument */
import dayjs from "dayjs";
import { graphql } from "gatsby";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import ArticlePageHeader from "../components/articles/ArticlePageHeader";
import InfoBox from "../components/general/InfoBox";
import PageSection from "../components/reusableSections/PageSection";
import SEO from "../components/utilities/SEO";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import Spacing from "../components/layout/Spacing";
import { PageComponentProps } from "../types/gatsby.types";
import { ArticleBySlugQuery, DatoCmsArticle } from "../../graphql-types";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import Page from "./Page.template";
import ArticleCoverImage from "../components/sections/ArticleCoverImage";
import { ArticleLikePageContent } from "../components/articles/ArticleLikePageContent";
import StandardCTASection from "../components/sections/StandardCTASection";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import {
  WiderArticlePageBody,
  WiderPageBodyInner,
  WiderPageBodyWrap,
} from "../components/hub/HubPageComponents";
import { styled } from "@linaria/react";
import { colors } from "../styles/colors.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { widthInGrid } from "../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";

const WithTOC = styled.div`
  ${WiderPageBodyWrap} {
    padding-left: 0;
    padding-right: 0;
    &.hasRightColumn {
      ${fromTabletMd} {
        grid-template-columns: minmax(0, 1fr) 22.5rem;
      }
      ${fromDesktopMd} {
        grid-template-columns: minmax(0, 1fr) ${widthInGrid(3)};
      }
    }
  }
  ${WiderPageBodyInner} {
    margin-left: 0;
    ${fromTablet} {
      padding-right: 1em;
    }
  }
  h1 {
    max-width: 20em;
  }
  aside {
    background-color: ${colors.light100};
    padding: 1em 1.5em;
    border-radius: ${rSize("radius")};
    max-height: calc(100vh - var(--pageTopFixedElementsHeight, 64px));
  }
`;

const ArticlePageTemplate = (
  props: PageComponentProps & {
    pageContext: {
      slug: string;
      tags: string[];
      // stories: StoryMeta[];
    };
    data: ArticleBySlugQuery;
  }
) => {
  const article = props.data.article as DatoCmsArticle;

  useOnMount(() => {
    reportIntent("Viewed blog post");
  });

  if (!article) return null;

  const isOldArticle = dayjs(article.datePublished)
    .add(18, "months")
    .isBefore(dayjs());

  const header = <ArticlePageHeader entry={article} />;
  const mainContent = (
    <>
      {article.coverImage && !article.hideCoverImageOnPage && (
        <>
          <ArticleCoverImage entry={article} />
          <Spacing size="lg" />
        </>
      )}
      {isOldArticle && (
        <>
          <InfoBox>
            <p data-date-published={article.datePublished}>
              This article was posted more than 18 months ago.
            </p>
          </InfoBox>
          <Spacing size="lg" />
        </>
      )}
      <StandardArticleStructuredTextWithCustomBlocks value={article.content} />
    </>
  );
  return (
    <Page {...props} identifier="article-template">
      <SEO
        title={article.title || "Tines Blog Article"}
        seoTitle={article.seoTitle}
        description={article.seoDescription || article.excerpt}
        image={article.ogImage?.url || article.coverImage?.url}
        ogType="article"
        canonical={article.canonicalUrl}
        noindex={article.hiddenInBlogIndex ?? false}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="article"
        recordId={article.id}
      />

      <SiteNavHeightPlaceholder withSpacingTop />

      <Spacing size="lg" multiplier={1.5} />

      <PageSection>
        {article.enableTableOfContents ? (
          <WithTOC>
            {header}
            <Spacing size="lg" />
            <WiderArticlePageBody
              largerText
              includeLevelsInToc={["h2", "h3", "h4"]}
              tocMainHeading="Table of contents"
            >
              {mainContent}
            </WiderArticlePageBody>
          </WithTOC>
        ) : (
          <ArticleLikePageContent>
            {header}
            <Spacing size="lg" />
            {mainContent}
          </ArticleLikePageContent>
        )}
      </PageSection>

      {/* <PageSection>
        <RelatedContentSection
          record={article}
          articles={props.data.articles.nodes as DatoCmsArticle[]}
          webinars={props.data.webinars.nodes as DatoCmsWebinar[]}
          stories={[]}
          includeAutoRecommendations={!article.relatedContent?.length}
        >
          <Spacing size="sectionPadding" />
          <AlignCenter>
            <SectionHeading2>Related content</SectionHeading2>
          </AlignCenter>
          <Spacing size="lg" />
        </RelatedContentSection>
      </PageSection> */}

      <Spacing size="sectionPadding" />

      <StandardCTASection />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const articleBySlugQuery = graphql`
  query ArticleBySlug(
    $slug: String # $tags: [String]
  ) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      id: originalId
      title
      slug
      excerpt
      canonicalUrl
      hiddenInBlogIndex
      enableTableOfContents
      seoTitle
      seoDescription
      meta {
        updatedAt
      }
      coverImage {
        url
        width
        height
      }
      ogImage {
        url
        width
        height
      }
      hideCoverImageOnPage
      showAuthorWorkTitle
      authors {
        id
        name
        middleName
        surname
        title
        organization {
          name
        }
        photoIcon {
          fluid(sizes: "64px") {
            ...GatsbyDatoCmsFluid
            width
            height
          }
        }
      }
      categories {
        name
        slug
      }
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              width
              height
              url
            }
            link
            caption
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
            color
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            title
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
            aspectRatio
            aspectRatioMobile
            height
            heightMobile
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            storyUrl
            pillText
            fullWidth
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsInArticleStoryGrid {
            id: originalId
            preheading
            heading
            description
            linkTextLabel
            linkUrl
            image {
              url
              width
              height
            }
            maxNumberOfStories
            stories {
              id: originalId
              name
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            collection {
              id: originalId
              name
              slug
            }
            tool {
              id: originalId
              name
              slug
            }
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
          ... on DatoCmsDataCardGrid {
            id: originalId
            cards {
              data
              label
              color
            }
          }
          ... on DatoCmsStructuredTextBlock {
            id: originalId
            structuredText {
              value
              blocks
            }
            backdropColor
            appearance
          }
        }
      }
      #   relatedContent {
      #     ... on DatoCmsWebinar {
      #       id: originalId
      #       title
      #       slug
      #       recordedVideoLink
      #       excerpt
      #       coverImage {
      #         width
      #         height
      #         url
      #       }
      #       model {
      #         apiKey
      #       }
      #     }
      #     ... on DatoCmsArticle {
      #       id: originalId
      #       title
      #       slug
      #       datePublished
      #       excerpt
      #       coverImage {
      #         width
      #         height
      #         url
      #       }
      #       model {
      #         apiKey
      #       }
      #     }
      #   }
    }
    # articles: allDatoCmsArticle(
    #   filter: {
    #     slug: { ne: $slug }
    #     tags: { elemMatch: { name: { in: $tags } } }
    #     hiddenInBlogIndex: { ne: true }
    #   }
    # ) {
    #   nodes {
    #     id: originalId
    #     title
    #     slug
    #     datePublished
    #     excerpt
    #     coverImage {
    #       width
    #       height
    #       url
    #     }
    #   }
    # }
    # webinars: allDatoCmsWebinar(
    #   filter: {
    #     category: { slug: { eq: "webinars" } }
    #     tags: { elemMatch: { name: { in: $tags } } }
    #     recordedVideoLink: { ne: "" }
    #   }
    # ) {
    #   nodes {
    #     id: originalId
    #     title
    #     slug
    #     recordedVideoLink
    #     excerpt
    #     coverImage {
    #       width
    #       height
    #       url
    #     }
    #   }
    # }
  }
`;

export default ArticlePageTemplate;
