import { styled } from "@linaria/react";
import {
  DatoCmsPerson,
  DatoCmsProfessionalServicesPage,
} from "../../../graphql-types";
import PersonPhotoIcon, {
  PersonPhotoIconContainer,
} from "../general/PersonPhotoIcon";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import stackedStonesIcon from "./illustrations/StackedStonesIcon.svg";
import Spacing from "../layout/Spacing";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import { useRef } from "react";
import g2Badge1 from "./g2badges/G2Badge1.svg";
import g2Badge2 from "./g2badges/G2Badge2.svg";
import g2Badge3 from "./g2badges/G2Badge3.svg";
import g2Badge4 from "./g2badges/G2Badge4.svg";
import g2Badge5 from "./g2badges/G2Badge5.svg";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { css } from "linaria";

const Header = styled.header`
  text-align: center;
  max-width: 33em;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3em;
`;

const G2Badges = styled.div`
  justify-content: center;
  ${onlyPhones} {
    display: flex;
    flex-wrap: wrap;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    > * {
      margin: 0.5em;
    }
  }
  ${fromTablet} {
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${rSize("md")};
  }
  img {
    aspect-ratio: 159/176;
    max-width: 9rem;
    ${fromTablet} {
      max-width: 10rem;
    }
    ${fromTabletMd} {
      max-width: 12rem;
    }
  }
`;

export const ProServMeetTheTeamSection = (props: {
  page: DatoCmsProfessionalServicesPage;
}) => {
  const allTeamMembersEntries = props.page.teamMembers as DatoCmsPerson[];
  return (
    <PageSection>
      <Header>
        <img
          src={stackedStonesIcon}
          width={112}
          height={88}
          style={{ aspectRatio: 112 / 88 }}
        />
        <Spacing />
        <SectionHeading2 lighter>Meet the team</SectionHeading2>
        <Spacing />
        <p>
          We’re proud of and regularly recognized for our people. Meet your
          partners for your Tines journey.
        </p>
      </Header>
      <Spacing size="sectionPadding" />
      <Kaleidoscope persons={allTeamMembersEntries} />
      <Spacing size="sectionPadding" />
      <G2Badges>
        <img
          src={g2Badge1}
          alt="G2 award badge: Best Support, Mid-market, Spring 2023"
        />
        <img
          src={g2Badge2}
          alt="G2 award badge: Easiest to do business with, Mid-market, Spring 2023"
        />
        <img src={g2Badge3} alt="G2 award badge: Best Service, Fall 2023" />
        <img
          src={g2Badge4}
          alt="G2 award badge: Best Relationship, Spring 2024"
        />
        <img
          src={g2Badge5}
          alt="G2 award badge: Leader, Enterprise, Spring 2024"
        />
      </G2Badges>
    </PageSection>
  );
};

const Kaleidoscope = (props: { persons: DatoCmsPerson[] }) => {
  const topThreeRows = [[], [], []] as DatoCmsPerson[][];
  const bottomTwoRows = [[], []] as DatoCmsPerson[][];
  let tri = 0;
  props.persons.forEach(entry => {
    topThreeRows[tri].push(entry);
    tri++;
    if (tri >= topThreeRows.length) tri = 0;
  });
  let bri = 0;
  props.persons.forEach(entry => {
    if (bottomTwoRows[bri].length >= topThreeRows[0].length) return;
    bottomTwoRows[bri].push(entry);
    bri++;
    if (bri >= bottomTwoRows.length) bri = 0;
  });
  return (
    <div>
      {topThreeRows.map((row, i) => (
        <KaleidoscopeRow key={i} person={row} index={i} />
      ))}
      {bottomTwoRows.map((row, i) => (
        <KaleidoscopeRow key={i} person={row} index={i} />
      ))}
    </div>
  );
};

const KaleidoscopeRow = (props: { person: DatoCmsPerson[]; index: number }) => {
  const innerRef = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const tween = gsap.fromTo(
      innerRef.current,
      {
        x: 0,
      },
      {
        x: "-25%",
        duration: 45 - 5 * props.index,
        repeat: -1,
        ease: "linear",
      }
    );
    tween.seek(props.index * 0.33 * tween.duration());
    return () => {
      tween.kill();
    };
  });
  return (
    <KaleidoscopeRowWrap>
      <KaleidoscopeRowInner ref={innerRef}>
        {props.person.map(person => (
          <PersonEntry person={person} key={person.id} />
        ))}
        {props.person.map(person => (
          <PersonEntry person={person} key={person.id} />
        ))}
        {props.person.map(person => (
          <PersonEntry person={person} key={person.id} />
        ))}
        {props.person.map(person => (
          <PersonEntry person={person} key={person.id} />
        ))}
      </KaleidoscopeRowInner>
    </KaleidoscopeRowWrap>
  );
};

const KaleidoscopeRowWrap = styled.div`
  display: flex;
  justify-content: center;
  ${fromTablet} {
    &:nth-child(5) {
      display: none;
    }
  }
  ${fromTabletLg} {
    &:nth-child(4) {
      display: none;
    }
  }
  + * {
    margin-top: ${rSize("lg")};
  }
`;

const KaleidoscopeRowInner = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
`;

const PersonEntryWrap = css`
  display: grid;
  text-decoration: none;
  grid-template-columns: auto minmax(auto, 1fr);
  align-items: center;
  grid-gap: 0.5em;
  ${fromTablet} {
    grid-gap: 0.75em;
  }
  font-weight: 500;
  white-space: nowrap;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${fromTablet} {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
  ${onlyPhones} {
    ${PersonPhotoIconContainer} {
      width: 4.2rem;
    }
  }
`;

const PersonDetails = styled.div`
  > * {
    display: block;
  }
  strong {
    font-size: 1.4rem;
  }
  span {
    font-size: 1.2rem;
  }
  ${fromTabletLg} {
    strong {
      font-size: 1.6rem;
    }
    span {
      font-size: 1.4rem;
    }
  }
`;

const PersonEntry = (props: { person: DatoCmsPerson }) => {
  const inner = (
    <>
      <PersonPhotoIcon person={props.person} size={64} />
      <PersonDetails>
        <strong>{props.person.fullName} </strong>
        <span>{props.person.team}</span>
      </PersonDetails>
    </>
  );
  return props.person.linkedin ? (
    <a
      className={PersonEntryWrap}
      href={props.person.linkedin}
      {...externalLinkAttr}
    >
      {inner}
    </a>
  ) : (
    <div className={PersonEntryWrap}>{inner}</div>
  );
};
