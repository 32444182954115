export const FedContinuousMonitoringIcon = () => (
  <svg width="101" height="87" viewBox="0 0 101 87" fill="none">
    <path
      d="M33.6429 63.2669L35.1996 61.7665C32.4845 61.3587 29.4434 61.1604 26.2362 61.2249C14.8682 61.4538 5.71809 64.8961 5.79937 68.9137C5.88009 72.9316 15.1609 76.0032 26.5291 75.7743C30.242 75.6998 33.7177 75.2818 36.7129 74.6133L33.6429 63.2669Z"
      fill="#195642"
    />
    <path
      d="M59.9416 18.832L27.3105 39.4663L38.1874 80.255L99.7703 78.8152L59.9416 18.832Z"
      fill="#FFF7EA"
    />
    <path
      d="M14.9684 59.0555C14.5502 57.989 4.72113 32.9032 3.87132 29.7152L3.79686 29.4382C3.3527 27.7813 2.96862 26.3497 3.41352 24.8329C3.87537 23.2577 5.13562 21.9876 7.49871 20.7145C10.1836 19.2692 27.3926 10.2638 28.1237 9.88135L29.8609 13.201C29.6818 13.2948 11.9403 22.5785 9.275 24.0137C7.92242 24.7422 7.15978 25.3723 7.00884 25.8869C6.85974 26.3952 7.10486 27.3097 7.41578 28.4678L7.49135 28.75C8.15169 31.227 15.6306 50.4791 18.4565 57.688L14.9684 59.0555Z"
      fill="#B8E5D2"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M44.5059 62.8871C44.5059 59.4232 34.865 56.6152 22.9726 56.6152C11.0799 56.6152 1.43921 59.4232 1.43921 62.8871C1.43921 63.5836 1.43921 66.1751 1.43921 66.8715C1.43921 70.3355 11.0799 73.1431 22.9726 73.1431C34.865 73.1431 44.5059 70.3355 44.5059 66.8715C44.5059 66.1751 44.5059 63.5836 44.5059 62.8871Z"
      fill="#49B889"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M44.5059 62.887C44.5059 66.351 34.865 69.1589 22.9726 69.1589C11.0799 69.1589 1.43921 66.351 1.43921 62.887C1.43921 59.4231 11.0799 54.626 22.9726 54.626C34.865 54.626 44.5059 59.4231 44.5059 62.887Z"
      fill="#49B889"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M27.0132 20.278C27.6303 21.203 31.3918 19.7776 35.4147 17.0946C39.4376 14.4112 42.1986 11.4865 41.5816 10.5615L32.705 0.925938C32.0883 0.00093604 29.086 0.919859 25.9999 2.97831C22.9131 5.03694 20.9114 7.4555 21.5281 8.3805L27.0132 20.278Z"
      fill="#49B889"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M54.2639 12.6466C52.3096 11.0118 48.6824 9.19536 43.2959 9.9368C38.0253 10.6618 33.1804 13.745 33.1804 13.745C33.1804 13.745 28.4719 17.0331 25.7772 21.6205C23.0236 26.3085 23.3065 30.3554 24.0645 32.788C24.8226 35.2204 27.5941 40.3935 27.5941 40.3935L43.7616 29.6105L59.9293 18.8276C59.9293 18.8276 56.2184 14.2813 54.2639 12.6466Z"
      fill="#49B889"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M47.7215 35.5409C56.6502 29.5853 62.1139 22.0971 59.9251 18.8157C57.7363 15.5342 48.7238 17.7021 39.7952 23.6577C30.8665 29.6134 25.4028 37.1015 27.5916 40.383C29.7804 43.6644 38.7929 41.4966 47.7215 35.5409Z"
      fill="#FFF7EA"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M29.5603 33.0784C31.9721 33.2254 38.5809 33.2396 43.9667 29.6476C49.3528 26.0556 51.879 19.9488 52.67 17.6655C48.9997 18.5205 44.4512 20.5653 39.9237 23.5851C35.3963 26.6052 31.7599 30.0186 29.5603 33.0784Z"
      fill="#DAF2E8"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M37.1218 25.6686C37.0698 26.4036 37.2359 27.1261 37.6493 27.7455C38.7939 29.4626 41.3942 29.7394 43.4566 28.364C45.5191 26.9886 46.2625 24.4819 45.1169 22.7644C44.7164 22.1632 44.1363 21.7393 43.4637 21.5005C42.3157 22.086 41.1467 22.7692 39.9836 23.5451C38.9622 24.2265 38.0046 24.9395 37.1218 25.6686Z"
      fill="#195642"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M21.3772 13.244L22.5471 17.3395L24.8872 15.7794L23.4248 12.269L21.3772 13.244Z"
      fill="#195642"
    />
    <path
      d="M69.2122 86.507C86.4061 86.507 100.344 83.6635 100.344 80.1558C100.344 76.6482 86.4061 73.8047 69.2122 73.8047C52.0183 73.8047 38.0798 76.6482 38.0798 80.1558C38.0798 83.6635 52.0183 86.507 69.2122 86.507Z"
      fill="#FFF7EA"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M59.7073 18.4761L100.064 79.314"
      stroke="#195642"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.1433 38.9736L38.0338 79.9723"
      stroke="#195642"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.9529 71.8757C41.1483 70.7306 44.5057 68.9154 44.5057 66.8715C44.5057 66.1751 44.5057 63.5836 44.5057 62.8872C44.5057 60.3667 39.4008 58.194 32.0386 57.1973L35.9529 71.8757Z"
      fill="#B8E5D2"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M34.9461 68.1008C40.7105 66.9756 44.5056 65.0605 44.5056 62.8875C44.5056 60.3152 39.1874 57.0076 31.5793 55.4751L34.9461 68.1008Z"
      fill="#B8E5D2"
      stroke="#195642"
      strokeMiterlimit="10"
    />
  </svg>
);
