export const TrophyTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6H7V8.5C7 8.95963 7.09053 9.41475 7.26642 9.83939C7.44231 10.264 7.70012 10.6499 8.02513 10.9749C8.35013 11.2999 8.73597 11.5577 9.16061 11.7336C9.58525 11.9095 10.0404 12 10.5 12C10.9596 12 11.4148 11.9095 11.8394 11.7336C12.264 11.5577 12.6499 11.2999 12.9749 10.9749C13.2999 10.6499 13.5577 10.264 13.7336 9.83939C13.9095 9.41475 14 8.95963 14 8.5V6Z"
    />
    <path
      opacity="0.7"
      d="M7.32458 13H7.7918C8.18293 13 8.5 12.6829 8.5 12.2918C8.5 12.113 8.39896 11.9495 8.23901 11.8695L7.5 11.5H10.5L9.76099 11.8695C9.60104 11.9495 9.5 12.113 9.5 12.2918C9.5 12.6829 9.81707 13 10.2082 13H10.6754C10.8832 13 11.0826 13.0826 11.2295 13.2295L12 14H6L6.77049 13.2295C6.91744 13.0826 7.11676 13 7.32458 13Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4H6V8C6 9.65685 7.34315 11 9 11C10.6569 11 12 9.65685 12 8V4ZM12 3H13V4V5H15C15.5523 5 16 5.44772 16 6V7C16 8.65685 14.6569 10 13 10H12.4649C11.7733 11.1956 10.4806 12 9 12C7.51944 12 6.22675 11.1956 5.53513 10H5C3.34315 10 2 8.65685 2 7V6C2 5.44772 2.44772 5 3 5H5V4V3H6H12ZM12.874 9C12.9562 8.68038 13 8.3453 13 8V6H15V7C15 8.10457 14.1046 9 13 9H12.874ZM5 8C5 8.3453 5.04375 8.68038 5.12602 9H5C3.89543 9 3 8.10457 3 7V6H5V8ZM13 15V14H5V15H13Z"
    />
  </svg>
);
