import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import DemoForm from "../components/demo/DemoForm";
import ogImage from "../../static/images/og/tines-book-a-demo.png";
import { colors, withOpacity } from "../styles/colors.styles";
import { styled } from "@linaria/react";
import AllCaps from "../components/typography/AllCaps";
import { widthInGrid } from "../constants/globalGrid.constants";
import PageSection from "../components/reusableSections/PageSection";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { rSize } from "../styles/responsiveSizes.styles";
import { serif } from "../styles/fonts.styles";
import LogoCanva from "../components/thirdPartyLogos/LogoCanva";
import { LogoCode42 } from "../components/thirdPartyLogos/LogoCode42";
import { LogoElastic36h } from "../components/thirdPartyLogos/LogoElastic36h";
import LogoIntercom from "../components/thirdPartyLogos/LogoIntercom";
import LogoMars20h from "../components/thirdPartyLogos/LogoMars20h";
import LogoCoinbase from "../components/thirdPartyLogos/LogoCoinbase";
import { LogoRedditForLightBackgrounds } from "../components/thirdPartyLogos/LogoReddit";
import { LogoOakRidge } from "../components/thirdPartyLogos/LogoOakRidge";
import { DemoPageDecoLeft } from "../components/demo/DemoPageDecoLeft";
import { DemoPageDecoTopRight } from "../components/demo/DemoPageDecoTopRight";
import { DemoPageDecoBottomRight } from "../components/demo/DemoPageDecoBottomRight";
import { Link } from "gatsby";
import { Paths } from "../utils/pathBuilders.utils";
import QuoteCardNeue from "../components/general/QuoteCardNeue";
import { css } from "linaria";
import {
  DemoPageDecoTopLeaf,
  DemoPageDemoLeavesBase,
} from "../components/demo/DemoPageDecoLeaves";
import DemoPageBackdrop from "../components/demo/DemoPageBackdrop";
import { DemoPageTopSpacer } from "../components/demo/DemoPageTopSpacer";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromTabletLg,
  onlyPhones,
  uptoTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { FromTabletLg } from "../components/mediaQueries/UptoTabletLg";

const ContentWrap = styled.div`
  position: relative;
  ${fromTabletLg} {
    padding-right: ${widthInGrid(1, 1)};
  }
`;

const MainFrame = styled.div`
  position: relative;
  ${fromTabletLg} {
    padding: 7.2rem 33rem 0 6.4rem;
    background-color: ${colors.purple100};
    border: 1px solid ${colors.purple400};
    border-radius: ${rSize("radius")};
  }
  ${fromDesktop} {
    padding: 9.6rem 33rem 0 7.2rem;
  }
  ${fromDesktopMd} {
    padding: ${widthInGrid(1, 1)} ${widthInGrid(4)} 0 ${widthInGrid(1, 1)};
  }
  font-weight: 500;
  z-index: 2;
  h1 {
    font-size: 4.8rem;
    font-family: ${serif};
    letter-spacing: -0.015em;
    font-weight: 300;
    line-height: 0.9;
    ${uptoTabletLg} {
      text-align: center;
    }
    ${fromDesktop} {
      font-size: 5.4rem;
      margin-top: -0.15em;
    }
    ${fromDesktopMl} {
      font-size: 6.2rem;
    }
    span {
      display: block;
    }
  }
  ul {
    padding-left: 1em;
  }
  li {
    + li {
      margin-top: 0.5em;
    }
  }
  hr {
    display: block;
    border: 0;
    border-top: 1px dashed ${colors.purple400};
    background-color: transparent;
    height: 0;
    margin: ${rSize("lg")} 0;
    ${fromTabletLg} {
      margin: ${rSize("lg")} -33rem ${rSize("lg")} 0;
    }
    ${fromDesktop} {
      margin-right: -33rem;
    }
    ${fromDesktopMd} {
      margin: ${rSize("xl")} ${widthInGrid(3, 0, -1)} ${rSize("xl")} 0;
    }
  }
`;

const MainFrameFooter = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1.6rem;
  text-align: right;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${withOpacity(colors.purple800, 0.7)};
  a {
    font-weight: 500;
    &:hover {
      color: ${colors.purple800};
    }
  }
`;

const FormFrame = styled.div`
  scroll-margin-top: 78px;
  ${uptoTabletLg} {
    position: relative;
    margin-top: 4.8rem;
    margin-bottom: 2.4rem;
  }
  ${fromTabletLg} {
    position: absolute;
    top: 9rem;
    right: ${widthInGrid(1, 1, -1)};
    width: 33.5rem;
  }
  ${fromDesktopMd} {
    width: ${widthInGrid(4)};
  }
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple400};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  z-index: 3;
`;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 48rem;
  > * {
    + * {
      margin-left: 1em;
    }
  }
`;

const QuoteCardWrap = styled.div`
  position: relative;
  ${fromTabletLg} {
    max-width: 33rem;
  }
  ${fromDesktop} {
    max-width: ${widthInGrid(4)};
  }
`;

const LeavesBaseWrap = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  position: absolute;
  left: 100%;
  bottom: 9rem;
  transform: translate(-11px, 15px);
`;

const TopLeafWrap = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  position: absolute;
  left: 100%;
  bottom: 13rem;
  transform: translate(-11px, 15px);
  z-index: 2;
`;

const DecoLeftPositioner = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  position: absolute;
  left: -9.2rem;
  top: 20rem;
  z-index: 1;
`;
const DecoTopRightPositioner = styled.div`
  ${uptoTabletLg} {
    margin: 2em auto;
    > * {
      margin: auto;
    }
  }
  ${fromTabletLg} {
    position: absolute;
    right: -6.2rem;
    top: 6.2rem;
    z-index: 1;
  }
`;
const DecoBottomRightPositioner = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  position: absolute;
  right: -7.2rem;
  bottom: -6.8rem;
  z-index: 1;
`;

const PageBookADemo: PageComponent = props => {
  return (
    <Page
      {...props}
      backgroundColor={colors.purple50}
      textColor={colors.purple800}
      navThemeColor="purple"
    >
      <SEO
        title="Book a demo"
        image={ogImage}
        description="Want to see a custom demo or get help finding the right plan? We’d love to chat."
      />
      <SiteNavHeightPlaceholder />
      <DemoPageTopSpacer />

      <DemoPageBackdrop />

      <PageSection
        className={css`
          ${onlyPhones} {
            overflow: hidden;
          }
        `}
      >
        <ContentWrap>
          <DecoTopRightPositioner>
            <DemoPageDecoTopRight />
          </DecoTopRightPositioner>
          <MainFrame>
            <h1>
              Talk to a <span>product expert</span>
            </h1>

            <FormFrame id="form-frame">
              <DemoForm />
            </FormFrame>

            <Spacing size="lg" />
            <h4>What to expect:</h4>
            <ul>
              <li>Zero-pressure, technical demo of the Tines platform</li>
              <li>Walkthrough of a relevant use case</li>
              <li>Overview of products and plans</li>
              <li>Insight into API and integration capabilities</li>
            </ul>
            <hr />

            <AllCaps>Trusted by</AllCaps>
            <Spacing size="gap" />
            <LogoRow>
              <LogoCanva />
              <LogoCode42 />
              <LogoElastic36h />
              <LogoIntercom />
            </LogoRow>

            <Spacing />

            <LogoRow>
              <LogoMars20h />
              <LogoCoinbase />
              <LogoRedditForLightBackgrounds />
              <LogoOakRidge />
            </LogoRow>

            <Spacing size="xl" />

            <QuoteCardWrap>
              <LeavesBaseWrap>
                <DemoPageDemoLeavesBase />
              </LeavesBaseWrap>
              <QuoteCardNeue
                color="green"
                sourceName="Daniel Gallagher"
                sourceDetails="Automation Engineer"
                sourceLogo={<LogoElastic36h />}
                footerLayout="space-between"
                fontSize="small"
                className={css`
                  position: relative;
                  z-index: 1;
                  ${fromTabletLg} {
                    margin-bottom: -9rem;
                  }
                `}
              >
                Anything can be done in Tines. There has never been a time that
                I couldn't achieve something with the product. It’s so
                versatile. If you can think it, you can do it.
              </QuoteCardNeue>
              <TopLeafWrap>
                <DemoPageDecoTopLeaf />
              </TopLeafWrap>
            </QuoteCardWrap>

            <MainFrameFooter>
              <p>Need product or customer support?</p>
              <p>
                Visit our <Link to={Paths.contactSupport()}>support page</Link>.
              </p>
            </MainFrameFooter>
          </MainFrame>

          <DecoLeftPositioner>
            <DemoPageDecoLeft />
          </DecoLeftPositioner>
          <DecoBottomRightPositioner>
            <DemoPageDecoBottomRight />
          </DecoBottomRightPositioner>
        </ContentWrap>

        <FromTabletLg>
          <Spacing size="7rem" />
        </FromTabletLg>
      </PageSection>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageBookADemo;
