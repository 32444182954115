import { styled } from "@linaria/react";
import Spacing from "../components/layout/Spacing";
import { Serif } from "../components/typography/Serif";
import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import ogImage from "../../static/images/og/tines-mssps.png";
import tinesIntegrationsImgHorizontal from "../../static/images/mssp/tines-integrations-horizontal.png";
import { whenDesktopNav } from "../components/site/SiteNav";
import { css } from "linaria";
import PageSection from "../components/reusableSections/PageSection";
import alanJonesPhoto from "../../static/images/mssp/alan-jones.jpg";
import { SectionHeading2 } from "../components/typography/SectionHeading2";
import ChecklistBlock from "../components/blocks/Checklist.block";
import {
  ColoredCardLg,
  ColoredCardMd,
} from "../components/general/ColoredCard";
import QuotemarkIcon from "../components/icons/misc/QuotemarkIcon";
import { externalLinkAttr } from "../constants/externalLinks.constants";
import {
  AlignCenter,
  AlignCenterOnMobile,
} from "../components/typography/Align";
import SpaceChildren from "../components/layout/SpaceChildren";
import { Link } from "gatsby";
import {
  fromDesktop,
  fromTabletMd,
  onlyPhones,
  onlyTablets,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import BookADemoButton from "../components/utilities/BookADemoButton";
import G2BadgeCardList from "../components/general/G2BadgeCardList";
import { widthInGrid } from "../constants/globalGrid.constants";
import MSSPPageIconStreetBlock from "../components/illustrations/spots/MSSPPageIconStreetBlock";
import { Split } from "../components/layout/Split";
import Button, {
  ButtonGroupCenteredOnMobile,
} from "../components/forms/Button";
import UptoTabletMd from "../components/mediaQueries/OnlyPhones";
import FromTabletMd from "../components/mediaQueries/FromTabletMd";
import { Paths } from "../utils/pathBuilders.utils";
import HeroSection, {
  HeroSectionDescription,
  HeroSectionSplit2to1,
  HeroSectionSplitHeaderWrap,
} from "../components/sections/HeroSection";
import AllCaps from "../components/typography/AllCaps";
import MSSPUseCases from "../components/reusableSections/MSSPUseCases";
import { LogoBCMOne20h } from "../components/thirdPartyLogos/LogoBCMOne20h";
import LogoSophos35 from "../components/thirdPartyLogos/LogoSophos35";
import PersonPhotoIcon from "../components/general/PersonPhotoIcon";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";

const IllustrationWrap = styled.div`
  ${onlyPhones} {
    text-align: center;
    margin-top: 2em;
    svg {
      width: 18rem;
      height: 18rem;
    }
  }
  ${onlyTablets} {
    svg {
      width: 20rem;
      height: 20rem;
    }
  }
`;

const ChecklistSection = styled.div`
  p {
    max-width: 28em;
    ${fromDesktop} {
      font-size: 1.8rem;
    }
  }
`;
const TinesIntegrationsImgWrap = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${rSize("radius")};
  aspect-ratio: 12/9;
  height: 120%;
  margin: -10%;
  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CTASectionIllustrationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${onlyPhones} {
    margin-bottom: -1em;
  }
  svg {
    width: 150px;
    height: 150px;
    ${fromDesktop} {
      width: 175px;
      height: 175px;
    }
  }
`;

const webinarLinkStyle = css`
  text-decoration: none;
  color: ${colors.purple};
  font-weight: 600;
`;
const caseStudyLinkStyle = css`
  text-decoration: none;
  color: ${colors.purple};
  font-weight: 600;
`;

const QuoteSourceWorkTitle = styled.p`
  opacity: 0.8;
  font-size: 1.4rem;
`;

const PageMSSP: PageComponent = props => {
  const description =
    "Our flexible platform lets MSSPs – big and small – break free from their time-intensive workflows.";
  useOnMount(() => {
    reportIntent("Viewed MSSPs page");
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title="MSSPs transform operations with Tines"
        description={description}
        image={ogImage}
      />

      <HeroSection
        textColor={colors.purple800}
        backdrop="grid"
        backdropColor={colors.green100}
        backdropParallax
        contentRelative
      >
        <HeroSectionSplit2to1>
          <HeroSectionSplitHeaderWrap>
            <AllCaps>Tines for MSSPs</AllCaps>
            <h1>
              Say goodbye to tedious tasks: transform MSSP operations with Tines
            </h1>
            <HeroSectionDescription>
              Leading Managed Security Service Providers automate their most
              complex processes with Tines. No code or customer development
              needed. It is fast, flexible and secure by design.
            </HeroSectionDescription>
            <ButtonGroupCenteredOnMobile>
              <Button to={Paths.product()} darker>
                Tour the product
              </Button>
              <BookADemoButton appearance="outlined" darker />
            </ButtonGroupCenteredOnMobile>
          </HeroSectionSplitHeaderWrap>
          <IllustrationWrap>
            <MSSPPageIllustration />
          </IllustrationWrap>
        </HeroSectionSplit2to1>
      </HeroSection>

      <Spacing size="sectionPadding" />
      <UptoTabletMd>
        <Spacing size="1.5em" />
      </UptoTabletMd>

      <PageSection>
        <AlignCenter>
          <SectionHeading2 blockSpan>
            <span>Supercharge your </span>
            <span>operational efficiency</span>
          </SectionHeading2>
        </AlignCenter>
        <Spacing size="xl" />
        <Split>
          <div
            className={css`
              ${whenDesktopNav} {
                padding-right: ${widthInGrid(1)};
              }
            `}
          >
            <ChecklistSection>
              <p style={{ marginBottom: "1em" }}>
                Today, the security landscape is more crowded than ever. This
                results in more differences between client environments, and
                more manual work eating away at your bottom line.
              </p>
              <p>
                Tines eliminates the toil with fully flexible workflow
                automation, allowing you to:
              </p>
              <ChecklistBlock centerOnMobile>
                <ul>
                  <li>
                    <p>Increase your margin</p>
                  </li>
                  <li>
                    <p>Reduce analyst burnout</p>
                  </li>
                  <li>
                    <p>Streamline operations</p>
                  </li>
                </ul>
              </ChecklistBlock>
            </ChecklistSection>
          </div>
          <ColoredCardMd
            backgroundColor={colors.purple50}
            color={colors.purple700}
          >
            <AlignCenterOnMobile>
              <SpaceChildren>
                <QuotemarkIcon color="purple" size={32} />
                <p>
                  One of the first questions that gets asked when we onboard a
                  new client is, ‘How can we use Tines here?’
                </p>
                <div>
                  <PersonPhotoIcon src={alanJonesPhoto} size="3em" inline />
                  <Spacing size=".5em" />
                  <p>
                    <strong>Alan Jones</strong>
                  </p>
                  <QuoteSourceWorkTitle>
                    Director of Security Services, Layer 3 Communications
                  </QuoteSourceWorkTitle>
                  <Spacing size="1em" />
                  <p>
                    <Link
                      className={webinarLinkStyle}
                      to="https://www.tines.com/case-studies/layer-3-communications"
                    >
                      Read the case study
                    </Link>
                  </p>
                </div>
              </SpaceChildren>
            </AlignCenterOnMobile>
          </ColoredCardMd>
        </Split>
      </PageSection>

      <Spacing size="sectionMargin" />
      <FromTabletMd>
        <Spacing size="xl" multiplier={0.5} />
      </FromTabletMd>

      <PageSection>
        <Split>
          <div
            className={css`
              ${whenDesktopNav} {
                padding-right: ${widthInGrid(1)};
              }
            `}
          >
            <AlignCenterOnMobile>
              <SectionHeading2>
                Adapt to changing
                <br />
                <Serif>technology needs</Serif>
              </SectionHeading2>
              <Spacing size="lg" />
              <p>
                Our completely API-driven automation technology connects you to
                any tech stack without needing to engage additional teams or
                resources. This means you move faster and support a broader set
                of technology for your diverse client needs and deliver client
                outcomes—not just technology.
              </p>
            </AlignCenterOnMobile>
            <ChecklistBlock centerOnMobile>
              <ul>
                <li>
                  <p>Deliver bespoke services quickly</p>
                </li>
                <li>
                  <p>Improve retention</p>
                </li>
                <li>
                  <p>Increase the ROI of existing clients</p>
                </li>
              </ul>
            </ChecklistBlock>
          </div>
          <TinesIntegrationsImgWrap>
            <img src={tinesIntegrationsImgHorizontal} />
          </TinesIntegrationsImgWrap>
        </Split>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <AlignCenter>
          <SectionHeading2>Example use cases</SectionHeading2>
          <Spacing size="lg" />
          <p
            className={css`
              ${whenDesktopNav} {
                max-width: 38em;
                margin-left: auto;
                margin-right: auto;
              }
            `}
          >
            Automate everything with Tines. Below are a few example workflows
            leveraged by leading MSSPs.
          </p>
        </AlignCenter>
        <Spacing size="xl" />
        <MSSPUseCases />
        <Spacing size="gap" />
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <AlignCenter>
          <SectionHeading2>Don’t take our word for it</SectionHeading2>
        </AlignCenter>
        <Spacing size="xl" />
        <G2BadgeCardList />
        <Spacing size="gap" />
        <Split>
          <ColoredCardLg
            backgroundColor={colors.light100}
            color={colors.dark500}
          >
            <AlignCenter>
              <SpaceChildren>
                <QuotemarkIcon color="green" />
                <p
                  className={css`
                    ${whenDesktopNav} {
                      font-size: 105%;
                      max-width: 38em;
                      margin-left: auto;
                      margin-right: auto;
                    }
                  `}
                >
                  <Serif>"Tines has been transformative for us."</Serif>
                </p>
                <p
                  className={css`
                    font-size: 1.5rem;
                    font-style: italic;
                    max-width: 17em;
                    margin: auto;
                    height: 9em;
                    opacity: 0.8;
                    display: flex;
                    align-items: center;

                    ${fromTabletMd} {
                      height: 8em;
                    }
                  `}
                >
                  In the first few months of using Tines, BCM One has already
                  built 12 automations with 50k events daily, and decreased
                  vulnerabilities by 55%.
                </p>
                <div>
                  <p>
                    <strong>Dan Rubins</strong>
                  </p>
                  <QuoteSourceWorkTitle>
                    VP of IT and Information Security, BCM One
                  </QuoteSourceWorkTitle>
                  <Spacing size="1em" />
                  <a href="https://www.bcmone.com/" {...externalLinkAttr}>
                    <LogoBCMOne20h />
                  </a>
                  <Spacing size="1em" />
                  <p>
                    <Link
                      className={caseStudyLinkStyle}
                      to={Paths.caseStudy("bcm-one")}
                    >
                      Read the case study →
                    </Link>
                  </p>
                </div>
              </SpaceChildren>
            </AlignCenter>
          </ColoredCardLg>
          <ColoredCardLg
            backgroundColor={colors.light100}
            color={colors.dark500}
          >
            <AlignCenter>
              <SpaceChildren>
                <QuotemarkIcon color="green" />
                <p
                  className={css`
                    ${whenDesktopNav} {
                      font-size: 105%;
                      max-width: 38em;
                      margin-left: auto;
                      margin-right: auto;
                    }
                  `}
                >
                  <Serif>
                    "Thanks to Tines, the first time an analyst looks at the
                    case, they already have all the information they need to
                    decide what action to take. This workflow saves around 50%
                    of an analyst’s time working on each case. But it’s not just
                    about time – we also eliminate the human error that can
                    creep into manual processes."
                  </Serif>
                </p>
                <div>
                  <p>
                    <strong>Tom Sage</strong>
                  </p>
                  <QuoteSourceWorkTitle>
                    Senior Security Engineer, Sophos
                  </QuoteSourceWorkTitle>
                  <Spacing size="1em" />
                  <a href="https://www.sophos.com/" {...externalLinkAttr}>
                    <LogoSophos35 />
                  </a>
                  <Spacing size="1em" />
                  <p>
                    <Link
                      className={caseStudyLinkStyle}
                      to={Paths.caseStudy("sophos")}
                    >
                      Read the case study →
                    </Link>
                  </p>
                </div>
              </SpaceChildren>
            </AlignCenter>
          </ColoredCardLg>
        </Split>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <ColoredCardLg>
          <Split>
            <div>
              <SectionHeading2>
                Trusted by
                <br />
                <Serif>MSSPs big & small</Serif>
              </SectionHeading2>
              <Spacing size="1em" />
              <p>Are you ready to join them and start scaling your business?</p>
              <Spacing size="1em" />
              <BookADemoButton
                appearance="filled-white"
                color="purple"
                label="Get started with a demo"
              />
            </div>
            <CTASectionIllustrationWrap>
              <MSSPPageIconStreetBlock />
            </CTASectionIllustrationWrap>
          </Split>
        </ColoredCardLg>
      </PageSection>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

const MSSPPageIllustration = () => (
  <svg width="233" height="304" viewBox="0 0 233 304" fill="none">
    <path
      d="M81.9738 302.36C94.9801 302.36 105.524 298.392 105.524 293.497C105.524 288.601 94.9801 284.633 81.9738 284.633C68.9675 284.633 58.4238 288.601 58.4238 293.497C58.4238 298.392 68.9675 302.36 81.9738 302.36Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.4199 293.275C74.4127 293.275 75.6307 290.061 75.6307 288.442V253.889C75.6307 250.852 78.0931 248.391 81.1291 248.391H82.6562C85.6934 248.391 88.1546 250.853 88.1546 253.889V288.442C88.1546 290.061 89.1518 293.275 92.8094 293.275"
      fill="#FABE64"
    />
    <path
      d="M71.4199 293.275C74.4127 293.275 75.6307 290.061 75.6307 288.442V253.889C75.6307 250.852 78.0931 248.391 81.1291 248.391H82.6562C85.6934 248.391 88.1546 250.853 88.1546 253.889V288.442C88.1546 290.061 89.1518 293.275 92.8094 293.275"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M152.175 302.36C165.181 302.36 175.725 298.392 175.725 293.497C175.725 288.601 165.181 284.633 152.175 284.633C139.169 284.633 128.625 288.601 128.625 293.497C128.625 298.392 139.169 302.36 152.175 302.36Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M141.621 293.275C144.614 293.275 145.832 290.061 145.832 288.442V253.889C145.832 250.852 148.294 248.391 151.33 248.391H152.857C155.895 248.391 158.356 250.853 158.356 253.889V288.442C158.356 290.061 159.353 293.275 163.011 293.275"
      fill="#49B889"
    />
    <path
      d="M141.621 293.275C144.614 293.275 145.832 290.061 145.832 288.442V253.889C145.832 250.852 148.294 248.391 151.33 248.391H152.857C155.895 248.391 158.356 250.853 158.356 253.889V288.442C158.356 290.061 159.353 293.275 163.011 293.275"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.436 216.67C141.436 216.67 139.425 216.67 136.087 216.67C129.795 216.67 128.316 214.303 127.983 210.426V194.881C127.983 188.82 123.07 183.906 117.007 183.906C116.992 183.906 116.977 183.909 116.96 183.909C116.945 183.909 116.93 183.906 116.915 183.906C110.854 183.906 105.94 188.82 105.94 194.881V210.426C105.607 214.303 104.128 216.67 97.8366 216.67C94.497 216.67 92.4858 216.67 92.4858 216.67C80.5782 216.67 70.8906 226.359 70.8906 238.266V256.769C70.8906 262.831 75.804 264.201 81.8652 264.201C87.9264 264.201 92.8398 262.831 92.8398 256.769C92.8398 256.769 92.8398 252.22 92.8398 247.495C92.8398 242.77 93.9192 238.62 100.175 238.62C105.49 238.62 106.387 238.62 106.387 238.62C110.227 238.62 113.833 237.607 116.961 235.843C120.09 237.607 123.696 238.62 127.536 238.62C127.536 238.62 128.432 238.62 133.749 238.62C140.003 238.62 141.083 242.77 141.083 247.495C141.083 252.22 141.083 256.769 141.083 256.769C141.083 262.831 145.996 264.201 152.057 264.201C158.12 264.201 163.033 262.831 163.033 256.769V238.266C163.033 226.359 153.344 216.67 141.436 216.67Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M131.916 206.06C131.916 214.32 125.221 215.574 116.96 215.574C108.701 215.574 102.006 214.319 102.006 206.06V132.072C102.006 123.813 108.701 117.117 116.96 117.117C125.221 117.117 131.916 123.813 131.916 132.072V206.06Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M116.776 199.856C125.723 199.856 132.976 196.296 132.976 191.904C132.976 187.513 125.723 183.953 116.776 183.953C107.829 183.953 100.576 187.513 100.576 191.904C100.576 196.296 107.829 199.856 116.776 199.856Z"
      fill="#32313B"
    />
    <path
      d="M117.145 58.6484C59.3209 58.6484 12.4453 64.3814 12.4453 71.4536C12.4453 71.4536 12.3829 74.9792 17.5669 83.438C20.3797 88.0256 28.1947 95.2778 28.1947 95.2778C28.1947 95.2778 30.6451 103.738 34.2109 109.086C38.5765 115.634 44.0467 119.257 44.0467 119.257C44.0467 119.257 47.2117 127.795 50.5825 133.096C53.9533 138.398 60.4099 144.009 60.4099 144.009C60.4099 144.009 63.3091 151.445 65.5897 154.924C67.8703 158.403 73.3039 163.515 73.3039 163.515C73.3039 163.515 76.2205 170.305 78.6865 174.024C81.1525 177.742 90.3871 184.813 90.3871 184.813C96.9745 190.036 100.708 195.24 117.146 195.24C133.584 195.24 136.795 190.143 143.905 184.813C143.905 184.813 153.14 177.742 155.605 174.024C158.071 170.305 160.987 163.515 160.987 163.515C160.987 163.515 166.422 158.403 168.702 154.924C170.982 151.445 173.882 144.009 173.882 144.009C173.882 144.009 180.339 138.398 183.709 133.096C187.079 127.795 190.245 119.257 190.245 119.257C190.245 119.257 195.715 115.634 200.081 109.086C203.646 103.738 206.097 95.2778 206.097 95.2778C206.097 95.2778 213.912 88.0256 216.724 83.438C221.908 74.9792 221.847 71.4536 221.847 71.4536C221.845 64.3808 174.97 58.6484 117.145 58.6484Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M160.261 164.047C148.142 166.288 133.247 167.61 117.144 167.61C101.27 167.61 86.571 166.326 74.5488 164.143"
      stroke="#6359B3"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M147.811 180.989C139.109 182.957 128.54 184.111 117.144 184.111C105.65 184.111 94.9976 182.937 86.252 180.938"
      stroke="#6359B3"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M174.652 143.375C160.303 146.469 139.848 148.404 117.145 148.404C94.5128 148.404 74.1146 146.482 59.7734 143.404"
      stroke="#6359B3"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M231.807 54.5292C231.807 44.9166 180.183 37.125 116.502 37.125C52.822 37.125 1.19922 44.9166 1.19922 54.5292C1.19922 56.334 1.19922 66.5052 1.19922 68.3106C1.19922 77.9226 52.822 85.7154 116.502 85.7154C180.183 85.7154 231.807 77.9226 231.807 68.3106C231.807 66.5052 231.807 56.334 231.807 54.5292Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M116.503 71.9322C180.184 71.9322 231.807 64.1404 231.807 54.5286C231.807 44.9169 180.184 37.125 116.503 37.125C52.8225 37.125 1.19922 44.9169 1.19922 54.5286C1.19922 64.1404 52.8225 71.9322 116.503 71.9322Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M116.503 71.9322C180.184 71.9322 231.807 64.1404 231.807 54.5286C231.807 44.9169 180.184 37.125 116.503 37.125C52.8225 37.125 1.19922 44.9169 1.19922 54.5286C1.19922 64.1404 52.8225 71.9322 116.503 71.9322Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M116.504 61.5443C168.587 61.5443 210.809 58.0838 210.809 53.8151C210.809 49.5464 168.587 46.0859 116.504 46.0859C64.421 46.0859 22.1992 49.5464 22.1992 53.8151C22.1992 58.0838 64.421 61.5443 116.504 61.5443Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M53.5305 59.3435C53.5305 59.3435 80.9535 48.0503 99.2367 46.4375C99.2367 46.4375 27.1827 47.5145 22.3438 53.4281C22.3438 53.4281 27.1821 58.2677 53.5305 59.3435Z"
      fill="#32313B"
    />
    <path
      d="M69.8457 43.4263C70.7799 45.9079 69.5247 48.6757 67.0437 49.6099L54.6897 54.2581C52.2087 55.1917 49.4403 53.9371 48.5067 51.4561L43.8579 39.1015C42.9237 36.6205 44.1789 33.8527 46.6599 32.9185L59.0145 28.2697C61.4949 27.3361 64.2639 28.5907 65.1975 31.0717L69.8457 43.4263Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.455 86.365C118.455 86.365 203.328 83.3008 220.774 75.9922C220.774 75.9922 218.18 82.1218 216.294 84.2434C216.294 84.2434 170.557 88.7224 118.455 86.365Z"
      fill="#32313B"
    />
    <path
      d="M58.4961 39.4411L60.2145 21.7117L76.4289 14.3359L90.9231 24.6889L89.2041 42.4183L72.9903 49.7941L58.4961 39.4411Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M142.799 29.014L131.078 21.6995L131.552 7.89224L143.746 1.39844L155.467 8.71244L154.992 22.5202L142.799 29.014Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M171.706 47.6622C170.392 49.2594 168.03 49.4868 166.433 48.1716L158.482 41.6214C156.884 40.3068 156.657 37.9446 157.972 36.348L164.522 28.3962C165.838 26.7984 168.199 26.571 169.796 27.8856L177.748 34.4364C179.344 35.7522 179.573 38.1138 178.258 39.7104L171.706 47.6622Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M137.837 45.946C136.649 48.3148 133.764 49.273 131.394 48.0832L116.84 40.7794C114.47 39.5902 113.513 36.7054 114.702 34.3366L122.007 19.7806C123.195 17.4112 126.08 16.4548 128.45 17.644L143.004 24.9478C145.374 26.1364 146.331 29.0218 145.142 31.3906L137.837 45.946Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M189.047 58.8161L189.551 56.3087L173.206 41.9141L152.568 48.8705L150.117 61.0613C165.351 60.5945 178.716 59.8145 189.047 58.8161Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M117.192 61.5426C118.149 61.5426 119.101 61.5408 120.049 61.5384C120.806 57.0456 118.123 52.5858 113.646 51.2448L83.8388 42.3108C78.9842 40.8558 73.8734 43.6098 72.4184 48.4614L68.8262 60.4494C82.9682 61.1436 99.5102 61.5426 117.192 61.5426Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.7325 59.5411L57.8895 55.0549L45.4161 50.6719L37.0977 57.8119C43.1289 58.4791 50.4399 59.0635 58.7325 59.5411Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M129.526 55.2653L125.678 48.6011L129.526 41.9375H137.22L141.067 48.6011L137.22 55.2653H129.526Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M96.2852 13.5791L98.4962 6.2087L105.984 4.4375L111.262 10.0367L109.052 17.4065L101.563 19.1783L96.2852 13.5791Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M205.905 94.8281C186.681 98.5559 154.101 101.008 117.146 101.008C80.2113 101.008 47.6487 98.5595 28.4199 94.8353"
      stroke="#6359B3"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M190.603 119.141C173.95 122.334 147.247 124.404 117.146 124.404C87.3527 124.404 60.8885 122.376 44.2031 119.238"
      stroke="#6359B3"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13.9746 76.666C14.733 78.4276 15.8682 80.668 17.565 83.437C20.3778 88.0246 28.1928 95.2768 28.1928 95.2768C28.1928 95.2768 30.6432 103.737 34.209 109.085C38.5746 115.633 44.0448 119.256 44.0448 119.256C44.0448 119.256 47.2098 127.794 50.5806 133.095C53.9514 138.397 60.408 144.008 60.408 144.008C60.408 144.008 63.3072 151.444 65.5878 154.923C67.8684 158.402 73.302 163.514 73.302 163.514C73.302 163.514 76.2186 170.304 78.6846 174.023C81.1506 177.741 90.3852 184.812 90.3852 184.812C96.9726 190.035 100.706 195.239 117.144 195.239C133.582 195.239 136.793 190.142 143.903 184.812C143.903 184.812 153.138 177.741 155.603 174.023C158.069 170.304 160.985 163.514 160.985 163.514C160.985 163.514 166.42 158.402 168.7 154.923C170.98 151.444 173.88 144.008 173.88 144.008C173.88 144.008 180.337 138.397 183.707 133.095C187.078 127.794 190.243 119.256 190.243 119.256C190.243 119.256 195.713 115.633 200.079 109.085C203.644 103.737 206.095 95.2768 206.095 95.2768C206.095 95.2768 213.91 88.0246 216.722 83.437C218.517 80.5084 219.683 78.1714 220.44 76.3672"
      stroke="#32313B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M75.875 267.472C77.5178 267.869 79.5356 268.107 81.725 268.107C84.0488 268.107 86.1812 267.841 87.875 267.397V263.406H75.875V267.472Z"
      fill="#32313B"
    />
    <path
      d="M146.076 267.472C147.719 267.869 149.737 268.107 151.926 268.107C154.25 268.107 156.382 267.841 158.076 267.397V263.406H146.076V267.472Z"
      fill="#32313B"
    />
  </svg>
);

export default PageMSSP;
