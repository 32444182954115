import { styled } from "@linaria/react";
import { useRef } from "react";
import {
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { useViewportSize } from "../../utils/window.utils";
import gsap from "gsap";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { responsiveSizeMaps } from "../../styles/responsiveSizes.styles";

const useCaseLines = [
  [
    ["Assess risks", colors.purple],
    ["Monitor systems for compliance and policy enforcement", colors.purple800],
    ["Chatbots to manage common user questions", colors.pink],
    ["Triage incidents", colors.green],
  ],
  [
    ["Enrich threats", colors.purple800],
    ["Analyze email content", colors.green],
    ["Isolate affected systems", colors.pink],
    ["Identify a root cause and find actionable insights", colors.purple],
  ],
  [
    ["Detect Anomalies", colors.pink],
    ["Predict future threats", colors.purple],
    ["Contain a threat", colors.green],
    ["Block malicious IP", colors.purple800],
  ],
  [
    ["Triage incidents", colors.pink], // duplicate
    ["Enrich threats", colors.green], // duplicate
    ["Provide context on threats", colors.purple800],
    ["Aggregate and analyze data from multiple sources", colors.purple],
  ],
] as unknown as [string, string][][];

const UseCaseKaleidoscopeWrap = styled.div`
  position: relative;
  overflow: hidden;
  color: ${colors.purple};
`;

const maskerBreakpoint = maxPageContentWidth;

const Masker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: -1em;
  @media (min-width: ${maskerBreakpoint}px) {
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: ${responsiveSizeMaps.pageMargin.desktopMd * 10}px;
      display: block;
      z-index: 1;
    }
    &:before {
      left: 0;
      background-image: linear-gradient(
        to right,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)}
      );
    }
    &:after {
      right: 0;
      background-image: linear-gradient(
        to left,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)}
      );
    }
  }
`;

const LinesWrap = styled.div`
  > * {
    + * {
      margin-top: 0.66em;
    }
  }
`;

export const WorkbenchUseCaseKaleidoscope = () => {
  return (
    <UseCaseKaleidoscopeWrap>
      <Masker>
        <LinesWrap>
          {useCaseLines.map((line, i) => (
            <UseCaseLine key={i} line={line} index={i} />
          ))}
        </LinesWrap>
      </Masker>
    </UseCaseKaleidoscopeWrap>
  );
};

const UseCaseLineWrap = styled.div`
  font-size: 2.4rem;
  font-family: ${serif};
  font-weight: 400;
  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;
  ${fromTablet} {
    font-size: 2.8rem;
  }
  ${fromTabletLg} {
    font-weight: 300;
    font-size: 3.2rem;
  }
`;

const UseCaseLineInner = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
`;

const UseCaseEntryWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  white-space: nowrap;
  align-items: center;
`;

const UseCaseEntryIcon = styled.div`
  width: 0.25em;
  height: 0.25em;
  background-color: ${colors.purple100};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 0.75em;
`;

const UseCaseLine = (props: { line: [string, string][]; index: number }) => {
  const ref = useRef<HTMLDivElement>(null);
  const direction = props.index % 2 === 1 ? 1 : -1;
  const { vw } = useViewportSize();
  useOnMount(() => {
    const tween = gsap.fromTo(
      ref.current,
      {
        x: `${direction * 15 + props.index * 5}vw`,
      },
      {
        x: `${props.index * 5}vw`,
        scrollTrigger: {
          trigger: ref.current,
          scrub: 1,
          start: "top bottom",
          end: "bottom top",
          invalidateOnRefresh: true,
        },
      }
    );
    return () => {
      tween.kill();
    };
  });
  const renderWords = () =>
    props.line.map(([word, color], i) => (
      <UseCaseEntryWrap
        key={i}
        style={{
          color,
        }}
      >
        <UseCaseEntryIcon />
        <div>{word}</div>
      </UseCaseEntryWrap>
    ));
  return (
    <UseCaseLineWrap>
      <UseCaseLineInner ref={ref}>
        {renderWords()}
        {renderWords()}
        {vw >= 1920 && (
          <>
            {renderWords()}
            {renderWords()}
          </>
        )}
      </UseCaseLineInner>
    </UseCaseLineWrap>
  );
};
