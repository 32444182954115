import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import { useRef } from "react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromPhoneLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";

import { fluidFontSize, fluidLineHeight } from "../../styles/helpers.styles";
import Button from "../forms/Button";
import ComboFontHeading from "../typography/ComboFontHeading";
import FlexibleDotGrid, {
  DEFAULT_CELL_SIZE,
} from "../decorations/FlexibleDotGrid";
import GartnerBadge from "../utilities/GartnerBadge";
import LinkToAnchor from "../basic/LinkToAnchor";
import Spacing from "../layout/Spacing";
import PageSection from "./PageSection";
import G2BadgeCardList from "../general/G2BadgeCardList";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import { colors } from "../../styles/colors.styles";

const RecognitionSectionInner = styled.div`
  position: relative;
  ${onlyPhones} {
    // cheeky hack to make the dot grid aligns to edges on iPhones
    margin-left: 0.1em;
    margin-right: 0.1em;
  }
  p {
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ContentGrid = styled.div<{ gridCellSize: number }>`
  position: relative;
  display: block;
  padding-top: ${p => `${p.gridCellSize * 2}px`};
  padding-bottom: ${p => `${p.gridCellSize * 2}px`};
  text-align: center;
  > section {
    margin-left: auto;
    margin-right: auto;
  }
`;

const G2Section = styled.section`
  background-color: ${colors.lightest};
  max-width: ${widthInGrid(10, 2)};

  h2 {
    max-width: 12em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const GartnerSection = styled.section`
  background-color: ${colors.lightest};
  max-width: ${widthInGrid(10, 2)};
  h2 {
    max-width: 16em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromPhoneLg} {
    padding-left: ${widthInGrid(1)};
    padding-right: ${widthInGrid(1)};
  }
  ${fromDesktop} {
    max-width: ${widthInGrid(8)};
  }
  img {
    ${onlyPhones} {
      max-width: 200px;
    }
  }
`;

const RecognitionAndBadgesSection = () => {
  const [gridCellSizeRef, setGridCellSize] = useStateWithRef(DEFAULT_CELL_SIZE);
  const contentGridRef = useRef<HTMLDivElement>(null);

  return (
    <PageSection>
      <RecognitionSectionInner>
        <FlexibleDotGrid
          cover
          onFittedCellSizeRecalc={setGridCellSize}
          heightCalculationRef={contentGridRef}
          snapColumnNumberTo={5}
        />

        <ContentGrid
          ref={contentGridRef}
          gridCellSize={gridCellSizeRef.current}
        >
          <G2Section>
            <Spacing size="lg" />
            <ComboFontHeading
              snippets={[
                ["Recognized as a", "serif"],
                ["leader in security & SOAR", "sans"],
              ]}
              className={cx(
                css`
                  ${fluidFontSize(28, 42)}
                  ${fluidLineHeight(32, 48)}
                  max-width: ${widthInGrid(6)};
                  margin-left: auto;
                  margin-right: auto;
                `
              )}
            />
            <Spacing size="md" />
            <p>
              Out of over 500 products across G2’s system security categories,
              Tines is the #1 highest rated by customer reviews.
            </p>
            <Spacing size="lg" />
            <G2BadgeCardList className="padded" />
            <Spacing size="lg" />
            <LinkToAnchor
              to="#customer-reviews"
              className={css`
                text-decoration: none;
              `}
            >
              <Button
                appearance="outlined"
                children="Read what customers say"
              />
            </LinkToAnchor>

            <Spacing size="xl" />
          </G2Section>

          <Spacing size={gridCellSizeRef.current * 2} />
          <GartnerSection>
            <Spacing size="xl" />

            <GartnerBadge style={{ marginRight: -10 }} />
            <Spacing size="md" />
            <ComboFontHeading
              snippets={[
                ["Highlighted as a Gartner Cool Vendor", "serif"],
                ["in Security Operations", "sans"],
              ]}
              className={cx(
                css`
                  ${fluidFontSize(28, 42)}
                  ${fluidLineHeight(32, 48)}
                `
              )}
            />
            <Spacing size="md" />
            <p>
              We were proud to be recognized in this recent report for our work
              in democratizing security automation.
            </p>
            <Spacing size="md" />
            <Button
              appearance="outlined"
              to="/gartner-cool-vendor"
              title="Learn more"
              children="Learn more"
            />
            <Spacing size="xl" />
          </GartnerSection>
        </ContentGrid>
      </RecognitionSectionInner>
    </PageSection>
  );
};

export default RecognitionAndBadgesSection;
