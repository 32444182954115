import { styled } from "@linaria/react";
import { DatoCmsCaseStudy } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import CaseStudyBookCardLink from "../general/CaseStudyBookCardLink";
import PageSection from "../reusableSections/PageSection";
import CustomerG2ReviewCard, {
  CustomerG2ReviewContainer,
} from "../general/CustomerLoveReviewCard";
import { g2Reviews } from "../../data/g2Reviews";
import g2Badge1 from "../../../static/images/g2-badges/G2-badge-leader-spring-2024.svg";
import g2Badge2 from "../../../static/images/g2-badges/G2-badge-best-relationship-spring-2024.svg";
import g2Badge3 from "../../../static/images/g2-badges/G2-badge-leader-enterprise-spring-2024.svg";
import g2Badge4 from "../../../static/images/g2-badges/G2-badge-best-support-enterprise-spring-2024.svg";
import g2Badge5 from "../../../static/images/g2-badges/G2-badge-best-support-mid-market-spring-2024.svg";
import g2Badge6 from "../../../static/images/g2-badges/G2-badge-easiest-to-do-business-with-mid-market-spring-2024.svg";
import GartnerBadge from "../utilities/GartnerBadge";
import Button from "../forms/Button";
import { colors, withOpacity } from "../../styles/colors.styles";
import { useRef } from "react";
import { gsap } from "gsap";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { onFontReady } from "../../utils/fonts.utils";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { StarTreeIcon } from "./StarTreeIcon";
import {
  breakpoints,
  fromDesktopMd,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";

type Props = {
  caseStudies: DatoCmsCaseStudy[];
};

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  align-items: start;
  position: relative;
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: "A B C";
  }
  ${CustomerG2ReviewContainer} {
    ${fromTabletMd} {
      min-height: 37.5rem;
    }
    ${fromDesktopMd} {
      min-height: 50rem;
    }
  }
`;

type BadgeBlockProps = {
  color: string;
};

const BadgeBlock = styled.div<BadgeBlockProps>`
  border-radius: ${rSize("radius")};
  background-color: ${p => p.color};
  aspect-ratio: 1;
  ${uptoTabletMd} {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  img {
    max-width: 50%;
    + * {
      margin-top: 1.5em;
    }
  }
`;

const CTABlock = styled.div`
  border-radius: ${rSize("radius")};
  background-color: ${colors.green};
  color: ${colors.white};
  ${fromTabletMd} {
    aspect-ratio: 1;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5em 1.5em;
  text-align: center;
  ${fromTabletMd} {
    padding: 1.5em;
  }
  h3 {
    font-size: 2.8rem;
    font-family: ${serif};
    font-weight: 400;
    ${fromTabletMd} {
      font-size: 2.4rem;
    }
    ${fromDesktopMd} {
      font-size: 3.2rem;
    }
  }
  img {
    max-width: 50%;
  }
  > * + * {
    margin-top: 1.8rem;
  }
`;

const BlankCard = styled.div`
  border-radius: ${rSize("radius")};
  aspect-ratio: 1;
  &.tall {
    aspect-ratio: 4 / 3;
  }
`;

const ParallaxColumn = styled.div<{ gridArea?: string }>`
  ${uptoTabletMd} {
    .CaseStudyBookCardLink {
      display: none;
    }
  }
  ${fromTabletMd} {
    grid-area: ${p => p.gridArea ?? "auto"};
  }
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  &.decorative {
    ${uptoTabletMd} {
      display: none;
    }
    position: absolute;
    top: 0;
    width: ${widthInGrid(4)};
    &.left {
      right: calc(100% + ${rSize("gap")});
      ${BlankCard} {
        background-image: linear-gradient(
          to left,
          ${colors.light100} 25%,
          ${withOpacity(colors.light100, 0)}
        );
      }
    }
    &.right {
      left: calc(100% + ${rSize("gap")});
      ${BlankCard} {
        background-image: linear-gradient(
          to right,
          ${colors.light100} 25%,
          ${withOpacity(colors.light100, 0)}
        );
      }
    }
  }
`;

const columnOffsets = {
  A: 72 * -0.5,
  B: 72 * 1,
  C: 72 * -1,
  D: 72 * -2,
  E: 72 * -3,
};

const AccreditationParallaxGrid = ({ caseStudies }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const timeline = gsap.timeline();

    const heightMap = {
      total: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
    };

    const columns = Array.from(
      ref.current?.childNodes ?? []
    ) as HTMLDivElement[];

    const reschedule = () => {
      timeline.clear();

      const [A, C, B, D, E] = columns;

      heightMap.A = A.clientHeight;
      heightMap.B = B.clientHeight;
      heightMap.C = C.clientHeight;
      heightMap.D = D.clientHeight;
      heightMap.E = E.clientHeight;

      heightMap.total = ref.current?.clientHeight ?? 0;

      columns.forEach(col => {
        const name = col.getAttribute("data-name") ?? "";

        timeline.fromTo(
          col,
          {
            y: () => {
              if (window.innerWidth >= breakpoints.tabletMd) {
                return columnOffsets[name as keyof typeof columnOffsets];
              }
              return 0;
            },
          },
          {
            y: () => {
              if (window.innerWidth >= breakpoints.tabletMd) {
                return (
                  heightMap.total - heightMap[name as keyof typeof heightMap]
                );
              }
              return 0;
            },
            scrollTrigger: {
              trigger: ref.current,
              scrub: true,
              start: "top bottom",
              end: "bottom 250px",
              invalidateOnRefresh: true,
            },
          },
          0
        );
      });
    };

    window.addEventListener("resize", reschedule);
    onFontReady(reschedule);
    reschedule();

    return () => {
      window.removeEventListener("resize", reschedule);
      timeline.kill();
    };
  });
  return (
    <PageSection id="accreditation">
      <Wrapper ref={ref}>
        <ParallaxColumn data-name="A" gridArea="A">
          <BadgeBlock color={colors.red50}>
            <img src={g2Badge1} />
          </BadgeBlock>
          <CaseStudyBookCardLink caseStudy={caseStudies[0]} />
          <CustomerG2ReviewCard review={g2Reviews[0]} />
          <BadgeBlock color={colors.green50}>
            <img src={g2Badge2} />
          </BadgeBlock>
          <CustomerG2ReviewCard review={g2Reviews[1]} />
          <CaseStudyBookCardLink caseStudy={caseStudies[1]} />
          <CustomerG2ReviewCard review={g2Reviews[2]} />
          <BadgeBlock color={colors.pink50}>
            <img src={g2Badge3} />
          </BadgeBlock>
        </ParallaxColumn>

        <ParallaxColumn data-name="C" gridArea="C">
          <CustomerG2ReviewCard review={g2Reviews[3]} />
          <BadgeBlock color={colors.lime50}>
            <img src={g2Badge4} />
          </BadgeBlock>
          <CustomerG2ReviewCard review={g2Reviews[4]} />
          <CaseStudyBookCardLink caseStudy={caseStudies[5]} />
          <BadgeBlock color={colors.orange50}>
            <img src={g2Badge5} />
          </BadgeBlock>
          <CustomerG2ReviewCard review={g2Reviews[5]} />
          <CaseStudyBookCardLink caseStudy={caseStudies[6]} />
          <CustomerG2ReviewCard review={g2Reviews[6]} />
        </ParallaxColumn>

        <ParallaxColumn data-name="B" gridArea="B">
          <CustomerG2ReviewCard review={g2Reviews[7]} />
          <BadgeBlock color="#E3E6ED">
            <GartnerBadge />
            <Button to="/gartner-cool-vendor" appearance="outlined">
              Learn more
            </Button>
          </BadgeBlock>
          <CaseStudyBookCardLink caseStudy={caseStudies[2]} />
          <CustomerG2ReviewCard review={g2Reviews[8]} />
          <CaseStudyBookCardLink caseStudy={caseStudies[3]} />
          <CustomerG2ReviewCard review={g2Reviews[9]} />
          <BadgeBlock color={colors.green50}>
            <img src={g2Badge6} />
          </BadgeBlock>
          <CaseStudyBookCardLink caseStudy={caseStudies[4]} />
          <CTABlock>
            <StarTreeIcon />
            <h3>Read more raving reviews on G2</h3>
            <Button
              href="https://www.g2.com/products/tines/reviews"
              appearance="filled-white"
              color="green"
              target="_blank"
            >
              Go to G2.com <sup>↗</sup>
            </Button>
          </CTABlock>
        </ParallaxColumn>

        <ParallaxColumn data-name="D" className="decorative left">
          <BlankCard />
          <BlankCard className="tall" />
          <BlankCard />
          <BlankCard className="tall" />
          <BlankCard />
          <BlankCard className="tall" />
          <BlankCard />
        </ParallaxColumn>

        <ParallaxColumn data-name="E" className="decorative right">
          <BlankCard className="tall" />
          <BlankCard />
          <BlankCard className="tall" />
          <BlankCard />
          <BlankCard className="tall" />
          <BlankCard />
        </ParallaxColumn>
      </Wrapper>
    </PageSection>
  );
};

export default AccreditationParallaxGrid;
