import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { DeepPartial } from "utility-types";
import {
  fromDesktop,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { DatoCmsArticle, DatoCmsCaseStudy } from "../../../graphql-types";
import ImageFrame from "../basic/ImageFrame";
import PlaceholderImageFrame from "../general/PlaceholderImageFrame";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import ReactMarkdown from "react-markdown";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import AllCaps from "../typography/AllCaps";
import Spacing from "../layout/Spacing";

type Props = {
  record: DeepPartial<DatoCmsArticle | DatoCmsCaseStudy>;
  featured?: boolean;
  showFeaturedTag?: boolean;
  openInNewTab?: boolean;
  noDescription?: boolean;
};

const linkStyle = css`
  display: block;
  text-decoration: none;
  width: 100%;
  > * {
    + * {
      margin-top: 2rem;
    }
  }
`;

const Inner = styled.div<Props>`
  position: relative;
  > * + * {
    ${uptoTabletLg} {
      margin-top: 2rem;
    }
    ${fromTabletLg} {
      margin-top: ${p => (p.featured ? 0 : "2rem")};
    }
  }
`;

const featuredArticleEntryLink = css`
  ${fromTabletLg} {
    display: grid;
    grid-gap: ${rSize("gap")};
    align-items: start;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "header image image";
  }
`;

const CoverImage = styled.div<Props>`
  grid-area: image;
  overflow: hidden;
  border-radius: 1.2rem;
  background-color: ${colors.light200};
  transform: translateZ(0);
  ${darkModeLinariaCSS(`background-color: ${colors.dark500};`)}
`;

const Header = styled.header`
  grid-area: header;
  max-width: 90%;
  .featured & {
    ${fromTablet} {
      padding-top: 0.25em;
    }
  }
  > * + * {
    margin-top: 1rem;
  }
`;

const Heading = styled.h3<{ featured?: boolean }>`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
  max-width: 18em;
  ${fromDesktop} {
    font-size: ${p => (p.featured ? "2.6rem" : "2rem")};
  }
  ${fromDesktopMl} {
    font-size: ${p => (p.featured ? "3.2rem" : "2.2rem")};
    letter-spacing: -0.01em;
    line-height: 1.15;
  }
  > span > * {
    font-family: inherit !important;
    font-weight: inherit !important;
  }
`;

const Excerpt = styled.div`
  line-height: 1.4;
  font-size: 1.6rem;
  opacity: 0.8;
  max-width: 33em;
  .featured & {
    ${fromTabletLg} {
      max-width: 90%;
    }
  }
  code {
    font-family: ${font("monospace")} !important;
    color: ${colors.orange700};
    font-size: 95%;
    background-color: ${withOpacity(colors.orange, 0.1)};
    padding: 0.1em 0.4em;
    border-radius: 0.35em;
  }
`;

const AuthorLine = styled.p`
  strong {
    font-weight: 600;
  }
`;

const ArticleEntryLink = (props: Props) => {
  const url = `/blog/${props.record.slug ?? ""}`;
  const commonAttr = {
    title: props.record.title ?? "Untitled Article",
    className: linkStyle,
    // add a key so that when switching between categories this component will be completely discarded, avoiding flashes of stale image from the previous article
    key: props.featured ? props.record.slug : undefined,
    target: props.openInNewTab ? "_blank" : undefined,
    children: (
      <Inner
        {...props}
        className={cx(
          props.featured && featuredArticleEntryLink,
          props.featured && "featured"
        )}
      >
        <CoverImage {...props}>
          {props.record.coverImage ? (
            <ImageFrame
              image={props.record.coverImage}
              lazy={!props.featured}
              key={props.featured ? props.record.slug : undefined}
            />
          ) : (
            <PlaceholderImageFrame />
          )}
        </CoverImage>
        <Header>
          {props.featured && props.showFeaturedTag && (
            <div>
              <AllCaps>Featured</AllCaps>
              <Spacing size=".5em" />
            </div>
          )}
          <Heading featured={props.featured}>
            <WithCustomInlineFormatting as="span">
              {props.record.title || "Untitled Article"}
            </WithCustomInlineFormatting>
          </Heading>
          {!!(props.record as DatoCmsArticle).authors?.length && (
            <AuthorLine>
              Written by{" "}
              <strong>
                {(props.record as DatoCmsArticle)
                  .authors!.map(a => `${a?.name} ${a?.surname}`)
                  .join(", ")}
              </strong>
            </AuthorLine>
          )}
          {!props.noDescription && props.record.excerpt && (
            <Excerpt>
              <ReactMarkdown>{props.record.excerpt}</ReactMarkdown>
            </Excerpt>
          )}
        </Header>
      </Inner>
    ),
  };
  return props.openInNewTab ? (
    <a href={url} {...commonAttr} />
  ) : (
    <Link to={url} {...commonAttr} />
  );
};

export default ArticleEntryLink;
