import { styled } from "@linaria/react";
import { graphql, useStaticQuery } from "gatsby";
import { AwsPageQuotesQuery } from "../../../../graphql-types";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import DatoCMSQuoteCardFullColor from "../../general/DatoCMSQuoteCardFullColor";
import PageSection from "../../reusableSections/PageSection";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: 1fr 1fr;
  }
`;

const AWSPageQuotesSection = () => {
  const data = useStaticQuery<AwsPageQuotesQuery>(query);
  return (
    <PageSection>
      <Grid>
        {data.quotes.nodes.map((q, i) => {
          return (
            <DatoCMSQuoteCardFullColor
              key={q.id}
              quote={q}
              color={i === 0 ? colors.orange : colors.purple}
            />
          );
        })}
      </Grid>
    </PageSection>
  );
};

export const query = graphql`
  query AWSPageQuotes {
    quotes: allDatoCmsQuote(
      filter: { originalId: { in: ["121499191", "121499237"] } }
    ) {
      nodes {
        id: originalId
        body
        sourceName
        sourceSubtitle
        sourcePhotoIcon {
          fluid(sizes: "64px") {
            ...GatsbyDatoCmsFluid
            width
            height
          }
        }
        sourceLogoWhite {
          url
        }
        sourcePerson {
          name
          middleName
          surname
          photoIcon {
            fluid(sizes: "64px") {
              ...GatsbyDatoCmsFluid
              width
              height
            }
          }
          organization {
            name
            logoWhite {
              url
            }
          }
        }
        sourceOrganization {
          name
          logoWhite {
            url
          }
        }
      }
    }
  }
`;

export default AWSPageQuotesSection;
