import { styled } from "@linaria/react";
import { graphql, useStaticQuery } from "gatsby";
import {
  fromTabletLg,
  fromTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import PageSection from "../../reusableSections/PageSection";
import { AwsPageUsefulResourcesQuery } from "../../../../graphql-types";
import ArticleEntryLink from "../../articles/ArticleEntryLink";
import Spacing from "../../layout/Spacing";

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.8rem;
  ${fromTabletLg} {
    font-size: 4.8rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const AWSPageUsefulResourcesSection = () => {
  const { articles } = useStaticQuery<AwsPageUsefulResourcesQuery>(query);
  return (
    <PageSection>
      <SectionTitle>Useful Resources</SectionTitle>
      <Spacing size="xl" />
      <Grid>
        {articles.nodes.map(article => (
          <ArticleEntryLink key={article.slug} record={article} openInNewTab />
        ))}
      </Grid>
    </PageSection>
  );
};

export const query = graphql`
  query AWSPageUsefulResources {
    articles: allDatoCmsArticle(
      filter: {
        slug: {
          in: [
            "aws-security-response-with-tines"
            "leveraging-aws-lambda"
            "how-to-create-a-tines-tunnel-with-an-aws-ec2-instance"
          ]
        }
      }
    ) {
      nodes {
        title
        slug
        excerpt
        coverImage {
          url
          width
          height
        }
      }
    }
  }
`;

export default AWSPageUsefulResourcesSection;
