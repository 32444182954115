import { styled } from "@linaria/react";
import { CSSProperties, cx } from "linaria";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import g2Badge1 from "../../../static/images/g2-badges/G2-badge-leader-spring-2024.svg";
import g2Badge2 from "../../../static/images/g2-badges/G2-badge-best-relationship-spring-2024.svg";
import g2Badge3 from "../../../static/images/g2-badges/G2-badge-leader-enterprise-spring-2024.svg";
import g2Badge4 from "../../../static/images/g2-badges/G2-badge-best-support-enterprise-spring-2024.svg";
import g2Badge5 from "../../../static/images/g2-badges/G2-badge-best-support-mid-market-spring-2024.svg";
import g2Badge6 from "../../../static/images/g2-badges/G2-badge-easiest-to-do-business-with-mid-market-spring-2024.svg";
import { colors } from "../../styles/colors.styles";

const G2BadgeCardListUl = styled.ul`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, 1fr);
  padding: 0;
  max-width: 100%;
  &.padded {
    ${fromPhoneLg} {
      padding: 0 ${rSize("gap")};
    }
  }
  ${fromTabletMd} {
    grid-template-columns: repeat(3, 1fr);
  }
  &.sixColumnsOnDesktop {
    ${fromDesktopMd} {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  list-style: none;
  margin: 0;
`;

const G2BadgeCard = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--G2BadgeBackgroundColor, ${colors.light200});
  border-radius: ${rSize("radius")};
  padding: 36px 24px 24px 24px;
  h3 {
    line-height: 1.25;
    margin-bottom: 1em;
  }
  img {
    width: 120px;
    height: auto;
    max-width: 100%;
    .sixColumnsOnDesktop & {
      ${fromDesktopMd} {
        width: 96px;
      }
    }
  }
`;

const G2BadgeCardList = (props: {
  className?: string;
  backgroundColor?: string;
  sixColumnsOnDesktop?: boolean;
}) => {
  return (
    <G2BadgeCardListUl
      className={cx(
        props.className,
        props.sixColumnsOnDesktop && "sixColumnsOnDesktop"
      )}
      style={
        {
          "--G2BadgeBackgroundColor":
            props.backgroundColor ?? colorsV4.canvas550,
        } as CSSProperties
      }
    >
      <G2BadgeCard>
        <img src={g2Badge6} />
      </G2BadgeCard>
      <G2BadgeCard>
        <img src={g2Badge5} />
      </G2BadgeCard>
      <G2BadgeCard>
        <img src={g2Badge4} />
      </G2BadgeCard>
      <G2BadgeCard>
        <img src={g2Badge2} />
      </G2BadgeCard>
      <G2BadgeCard>
        <img src={g2Badge1} />
      </G2BadgeCard>
      <G2BadgeCard>
        <img src={g2Badge3} />
      </G2BadgeCard>
    </G2BadgeCardListUl>
  );
};

export default G2BadgeCardList;
