import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { LogoAWSMarketplace } from "../thirdPartyLogos/LogoAWSMarketplace";
import { FedPartnerIcon } from "./iconsAndIllustrations/FedPartnerIcon";
import { LogoCrowdstrike } from "../thirdPartyLogos/LogoCrowdstrike";
import { LogoRecordedFuture } from "../thirdPartyLogos/LogoRecordedFuture";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromPhoneLg,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../layout/Spacing";
import { LogoHashiCorp } from "../thirdPartyLogos/LogoHashiCorp";
import { LogoAxonius } from "../thirdPartyLogos/LogoAxonius";
import { LogoNoName } from "../thirdPartyLogos/LogoNoName";
import { LogoTenable } from "../thirdPartyLogos/LogoTenable";
import { LogoElastic45h } from "../thirdPartyLogos/LogoElastic45h";
import UptoPhoneLg from "../mediaQueries/UptoPhoneLg";
import FromPhoneLg from "../mediaQueries/FromPhoneLg";

const Inner = styled.div`
  text-align: center;
  h2 {
    ${fromDesktop} {
      max-width: ${widthInGrid(7)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const PartnerLogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${fromTabletLg} {
    flex-wrap: nowrap;
  }
  max-width: ${widthInGrid(10)};
  margin-left: auto;
  margin-right: auto;
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    flex: 0 0 50%;
    height: 9rem;
    ${fromPhoneLg} {
      flex: 0 0 33.33%;
    }
    ${fromTabletLg} {
      flex: 0 0 auto;
      padding: 1.5em;
    }
    ${fromDesktopMd} {
      padding: 1.75em;
    }
  }
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

const FedPartnersSection = () => {
  return (
    <PageSection>
      <Inner>
        <FedPartnerIcon />
        <Spacing size="xl" />
        <SectionHeading2 lighter>
          Partnered with the companies you trust today
        </SectionHeading2>
        <Spacing size="lg" />
        <PartnerLogoRow>
          <div>
            <LogoAWSMarketplace />
          </div>
          <div>
            <LogoCrowdstrike />
          </div>
          <div>
            <LogoRecordedFuture />
          </div>
          <UptoPhoneLg>
            <LogoElastic45h />
          </UptoPhoneLg>
        </PartnerLogoRow>
        <PartnerLogoRow>
          <FromPhoneLg>
            <LogoElastic45h />
          </FromPhoneLg>
          <div>
            <LogoHashiCorp />
          </div>
          <div>
            <LogoAxonius />
          </div>
          <div>
            <LogoNoName />
          </div>
          <div>
            <LogoTenable />
          </div>
        </PartnerLogoRow>
      </Inner>
    </PageSection>
  );
};

export default FedPartnersSection;
