import { styled } from "@linaria/react";
import HeroSection, {
  HeroSectionBackdropLayer,
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import { SolutionPageHeadlineWrap } from "../components/solutions/SolutionPageHero";
import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import Spacing from "../components/layout/Spacing";
import { FedDecoTopLeft } from "../components/federal/iconsAndIllustrations/FedDecoTopLeft";
import BookADemoButton from "../components/utilities/BookADemoButton";
import { NoLetterSpacing } from "../components/typography/NoLetterSpacing";
import { FedLayeredProductVisual } from "../components/federal/iconsAndIllustrations/FedLayeredProductVisual";
import { rSize } from "../styles/responsiveSizes.styles";
import CenteredQuoteSection from "../components/general/CenteredQuoteSection";
import { LogoOakRidge } from "../components/thirdPartyLogos/LogoOakRidge";
import FedModernizeSecurityApproachSection from "../components/federal/FedModernizeSecurityApproachSection";
import FedFiveColSection from "../components/federal/FedFiveColSection";
import FedLibrarySuggestionsSection from "../components/federal/FedLibrarySuggestionsSection";
import FedPartnersSection from "../components/federal/FedPartnersSection";
import FedCompactRelatedContentSection from "../components/federal/FedCompactRelatedContentSection";
import { FedPageEndCTASection } from "../components/federal/FedPageEndCTASection";
import { LogoElastic45h } from "../components/thirdPartyLogos/LogoElastic45h";
import {
  fromDesktopMd,
  fromDesktopSm,
  fromPhoneLg,
  fromTabletLg,
  mediaFromTo,
} from "../styles/breakpointsAndMediaQueries.styles";
import ogImage from "../../static/images/og/tines-federal.png";
import { css } from "linaria";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import AllCaps from "../components/typography/AllCaps";
import { graphql } from "gatsby";
import { FederalPageQueryQuery } from "../../graphql-types";

const HeaderWrap = styled.div`
  position: relative;
  h1 {
    max-width: 12em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const DecoTopLeftWrap = styled.div`
  position: absolute;
  bottom: 0;
  display: none;
  ${fromTabletLg} {
    display: block;
    left: 0;
  }
  ${fromDesktopSm} {
    left: ${rSize("lg")};
  }
  ${mediaFromTo("tabletLg", "desktopMd")} {
    svg {
      width: 150px;
      height: auto;
    }
  }
`;

const HeroSmallPrint = styled.p`
  opacity: 0.7;
  font-size: 1.3rem;
`;

const MainVisualWrap = styled.div``;

const PageFederal: PageComponent<{ data: FederalPageQueryQuery }> = props => {
  useOnMount(() => {
    reportIntent("Viewed Federal page");
  });

  const description =
    "Federal agencies rely on our workflow and automation platform to reach their zero trust goals and reinforce their security posture.";

  return (
    <Page {...props} textColor={colors.purple800} navThemeColor="purple">
      <SEO
        title="Federal"
        seoTitle={props.data.federal?.seoTitle}
        description={props.data.federal?.seoDescription || description}
        image={ogImage}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="federalPage"
        recordId="Qw_TdqLQQsiFPFsN1wB-qA"
        label="Edit in CMS"
      />

      <HeroSection
        wider
        backdrop="grid"
        backdropColor={colors.light300}
        backdropParallax
        contentRelative
        className={css`
          ${HeroSectionBackdropLayer} {
            bottom: 12rem;
            ${fromPhoneLg} {
              bottom: 20rem;
            }
            ${fromTabletLg} {
              bottom: 25rem;
            }
            ${fromDesktopMd} {
              bottom: 32rem;
            }
          }
        `}
      >
        <HeaderWrap>
          <AllCaps as="p">Federal</AllCaps>
          <Spacing size="1.5em" />
          <SolutionPageHeadlineWrap>
            <h1>
              Smart, secure work<NoLetterSpacing>fl</NoLetterSpacing>ows for
              SOAR and beyond
            </h1>
            <HeroSectionDescription centered wider>
              {description}
            </HeroSectionDescription>
            <Spacing size="md" />
            <BookADemoButton darker />
            <Spacing size="md" />
            <HeroSmallPrint
              style={{
                maxWidth: "29em",
              }}
            >
              Tines is listed on the Department of Homeland Security’s (DHS) CDM
              Approved Products List (APL).
            </HeroSmallPrint>
          </SolutionPageHeadlineWrap>

          <DecoTopLeftWrap>
            <FedDecoTopLeft />
          </DecoTopLeftWrap>
        </HeaderWrap>

        <MainVisualWrap>
          <FedLayeredProductVisual />
        </MainVisualWrap>
      </HeroSection>

      <CenteredQuoteSection
        sourceName="Pete Wood"
        sourceWorkTitle="Lead Engineer"
        sourceCompanyLogo={<LogoOakRidge />}
        caseStudySlug="oak-ridge-national-laboratory"
        color="green"
      >
        <p>
          “We can automate anything, the only thing we’re limited by is our own
          mindset and time to do it.”
        </p>
      </CenteredQuoteSection>

      <Spacing size="sectionPadding" />

      <FedModernizeSecurityApproachSection />

      <Spacing size="sectionPadding" />

      <CenteredQuoteSection
        sourceName="Daniel Gallagher"
        sourceWorkTitle="Security Automation Engineer"
        sourceCompanyLogo={<LogoElastic45h />}
        caseStudySlug="elastic"
        color="purple"
      >
        <p>
          “Tines is doing the work of at least 3 FTEs and, most importantly, is
          allowing us to be more thorough in the work that we do.”
        </p>
      </CenteredQuoteSection>

      <Spacing size="sectionPadding" />

      <FedFiveColSection />
      <Spacing size="xl" />
      <FedLibrarySuggestionsSection />

      <Spacing size="sectionMargin" />

      <FedPartnersSection />

      <Spacing size="sectionMargin" />

      <FedCompactRelatedContentSection />

      <Spacing size="sectionMargin" />

      <FedPageEndCTASection />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const federalPageQuery = graphql`
  query FederalPageQuery {
    federal: datoCmsFederalPage {
      seoTitle
      seoDescription
    }
  }
`;

export default PageFederal;
