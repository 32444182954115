import ContentCardBase from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  PrimaryColorName,
  brandColorThemeVar,
} from "../../styles/colors.styles";

const contentCardUniSimpleStyle = css`
  [data-path="/"] & {
    border-color: ${brandColorThemeVar(700)};
    &:hover {
      border-color: ${brandColorThemeVar(800)};
    }
  }
`;

const ContentCardUniversitySimple = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={contentCardUniSimpleStyle}
      link="/university"
      color={(overrides?.color ?? "purple") as PrimaryColorName}
      variant="50"
      large={large}
      size="1/2"
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Learn"}</strong>
          </p>
          <h4>{overrides?.heading || "Tines University"}</h4>
          <ReactMarkdown>
            {overrides?.description ||
              "Build your knowledge with dedicated learning paths at all levels."}
          </ReactMarkdown>
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Start learning"}</strong>
        </p>
      </header>
      <figure>
        <UniversityIcon />
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardUniversitySimple;

const UniversityIcon = () => (
  <svg
    width="95"
    height="81"
    viewBox="0 0 95 81"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    stroke="#4D3E78"
  >
    <path
      d="M94.2408 73.9326H15.3838V79.8794H87.8068L94.2408 73.9326Z"
      fill="#4D3E78"
    />
    <path
      d="M25.8076 69.4054C25.8076 67.3593 24.1483 65.7004 22.1024 65.7004H10.7799C9.69002 65.7004 8.70991 66.1713 8.03195 66.9204C7.43721 67.5778 2.72121 72.2247 2.04782 72.9172C1.39913 73.5846 1.00049 74.4954 1.00049 75.4991V79.8792H19.7324L25.8078 73.7853L25.8076 69.4054Z"
      fill="#8D75E6"
    />
    <path
      d="M19.7319 79.8792V75.4992C19.7319 73.4531 18.0738 71.7942 16.0277 71.7942H4.70501C2.65848 71.7942 1 73.4531 1 75.4992V79.8792H19.7319Z"
      fill="#C2AAFA"
    />
    <path d="M18.7822 73.0219L24.7886 66.9929" />
    <path
      d="M54.4468 69.4062C54.4468 67.3601 52.7879 65.7012 50.7416 65.7012H39.4193C38.3292 65.7012 37.3489 66.172 36.6713 66.9212C36.0766 67.5786 31.3606 72.2255 30.687 72.918C30.0385 73.5853 29.6396 74.4961 29.6396 75.4999V79.8799H48.3717L54.4468 73.786V69.4062Z"
      fill="#8D75E6"
    />
    <path
      d="M48.3717 79.88V75.4999C48.3717 73.4538 46.7137 71.7949 44.6676 71.7949H33.3446C31.2983 71.7949 29.6396 73.4538 29.6396 75.4999V79.88H48.3717Z"
      fill="#C2AAFA"
    />
    <path d="M47.4219 73.0224L53.4286 66.9934" />
    <path
      d="M83.0872 69.4059C83.0872 67.3598 81.4283 65.7009 79.382 65.7009H68.0595C66.9698 65.7009 65.9895 66.1718 65.3115 66.9209C64.717 67.5783 60.0012 72.2252 59.3276 72.9177C58.6791 73.5851 58.2803 74.4959 58.2803 75.4996V79.8797H77.0119L83.0874 73.7858L83.0872 69.4059Z"
      fill="#8D75E6"
    />
    <path
      d="M77.0119 79.8797V75.4997C77.0119 73.4536 75.3543 71.7947 73.3082 71.7947H61.9853C59.939 71.7947 58.2803 73.4536 58.2803 75.4997V79.8797H77.0119Z"
      fill="#C2AAFA"
    />
    <path d="M76.063 73.0219L82.0695 66.9929" />
    <path
      d="M22.4448 68.6707C22.4401 68.6871 22.4069 68.7804 22.1781 68.9304C21.9218 69.0986 21.5179 69.2692 20.9741 69.4202C19.8934 69.7201 18.3719 69.912 16.6712 69.912C14.9705 69.912 13.4491 69.7201 12.3684 69.4202C11.8245 69.2692 11.4206 69.0986 11.1643 68.9304C10.9356 68.7804 10.9023 68.6871 10.8977 68.6707C10.9023 68.6543 10.9356 68.5611 11.1643 68.411C11.4206 68.2428 11.8245 68.0722 12.3684 67.9212C13.4491 67.6213 14.9705 67.4294 16.6712 67.4294C18.3719 67.4294 19.8934 67.6213 20.9741 67.9212C21.5179 68.0722 21.9218 68.2428 22.1781 68.411C22.4069 68.5611 22.4401 68.6543 22.4448 68.6707Z"
      fill="#4D3E78"
    />
    <path
      d="M50.9711 68.6707C50.9664 68.6871 50.9331 68.7804 50.7044 68.9304C50.4481 69.0986 50.0442 69.2692 49.5003 69.4202C48.4196 69.7201 46.8981 69.912 45.1973 69.912C43.4966 69.912 41.975 69.7201 40.8943 69.4202C40.3504 69.2692 39.9465 69.0986 39.6902 68.9304C39.4615 68.7804 39.4282 68.6871 39.4235 68.6707C39.4282 68.6543 39.4615 68.5611 39.6902 68.411C39.9465 68.2428 40.3504 68.0722 40.8943 67.9212C41.975 67.6213 43.4966 67.4294 45.1973 67.4294C46.8981 67.4294 48.4196 67.6213 49.5003 67.9212C50.0442 68.0722 50.4481 68.2428 50.7044 68.411C50.9331 68.5611 50.9664 68.6543 50.9711 68.6707Z"
      fill="#4D3E78"
    />
    <path
      d="M79.4984 68.6707C79.4937 68.6871 79.4605 68.7804 79.2317 68.9304C78.9754 69.0986 78.5715 69.2692 78.0277 69.4202C76.9469 69.7201 75.4254 69.912 73.7246 69.912C72.0239 69.912 70.5024 69.7201 69.4216 69.4202C68.8778 69.2692 68.4739 69.0986 68.2176 68.9304C67.9888 68.7804 67.9556 68.6871 67.9509 68.6707C67.9556 68.6543 67.9888 68.5611 68.2176 68.411C68.4739 68.2428 68.8778 68.0722 69.4216 67.9212C70.5024 67.6213 72.0239 67.4294 73.7246 67.4294C75.4254 67.4294 76.9469 67.6213 78.0277 67.9212C78.5715 68.0722 78.9754 68.2428 79.2317 68.411C79.4605 68.5611 79.4937 68.6543 79.4984 68.6707Z"
      fill="#4D3E78"
    />
    <path
      d="M7.34912 68.2544C7.34912 69.0608 10.2753 69.7143 13.8844 69.7143C17.4933 69.7143 20.4191 69.0608 20.4191 68.2544V30.9913C20.4191 30.1851 17.4931 29.5312 13.8844 29.5312C10.2751 29.5312 7.34912 30.1849 7.34912 30.9913V68.2544Z"
      fill="#C2AAFA"
    />
    <path
      d="M35.9893 68.2546C35.9893 69.0611 38.9152 69.7145 42.5239 69.7145C46.1333 69.7145 49.059 69.0611 49.059 68.2546V30.9916C49.059 30.1854 46.1331 29.5315 42.5239 29.5315C38.915 29.5315 35.9893 30.1852 35.9893 30.9916V68.2546Z"
      fill="#C2AAFA"
    />
    <path
      d="M64.6304 68.2544C64.6304 69.0608 67.5559 69.7143 71.1651 69.7143C74.7742 69.7143 77.7001 69.0608 77.7001 68.2544V30.9913C77.7001 30.1851 74.7742 29.5312 71.1651 29.5312C67.5559 29.5312 64.6304 30.1849 64.6304 30.9913V68.2544Z"
      fill="#C2AAFA"
    />
    <path
      d="M13.8841 37.4695C11.5426 37.4695 9.42006 37.2984 7.82764 37.0221V34.2405H19.9701V37.0169C18.3743 37.2962 16.24 37.4695 13.8841 37.4695Z"
      fill="#4D3E78"
    />
    <path
      d="M42.6414 37.4695C40.2999 37.4695 38.1774 37.2984 36.585 37.0221V34.2405H48.7274V37.0169C47.1318 37.2962 44.9973 37.4695 42.6414 37.4695Z"
      fill="#4D3E78"
    />
    <path
      d="M71.4009 37.4695C69.0594 37.4695 66.9367 37.2984 65.3442 37.0221V34.2405H77.4867V37.0169C75.8911 37.2962 73.7567 37.4695 71.4009 37.4695Z"
      fill="#4D3E78"
    />
    <path
      d="M23.0834 30.3737C23.0834 29.3917 18.9648 28.5957 13.884 28.5957C8.80334 28.5957 4.68457 29.3917 4.68457 30.3737C4.68457 30.9601 4.68457 32.6477 4.68457 33.2343C4.68457 34.2163 8.80334 35.0125 13.884 35.0125C18.9648 35.0125 23.0834 34.2165 23.0834 33.2343C23.0834 32.6479 23.0834 30.9601 23.0834 30.3737Z"
      fill="#5ABE89"
    />
    <path
      d="M23.0834 27.1732C23.0834 26.1913 18.9648 25.395 13.884 25.395C8.80334 25.395 4.68457 26.1911 4.68457 27.1732C4.68457 27.7597 4.68457 29.4472 4.68457 30.0338C4.68457 31.0158 8.80334 31.8118 13.884 31.8118C18.9648 31.8118 23.0834 31.0158 23.0834 30.0338C23.0834 29.4472 23.0834 27.7595 23.0834 27.1732Z"
      fill="#C2AAFA"
    />
    <path
      d="M13.8988 28.951C18.9796 28.951 23.0985 28.155 23.0985 27.173C23.0985 26.1911 18.9796 25.395 13.8988 25.395C8.81803 25.395 4.69922 26.1911 4.69922 27.173C4.69922 28.155 8.81803 28.951 13.8988 28.951Z"
      fill="#C2AAFA"
    />
    <path
      d="M51.7237 30.3737C51.7237 29.3917 47.6049 28.5957 42.5241 28.5957C37.4433 28.5957 33.3247 29.3917 33.3247 30.3737C33.3247 30.9601 33.3247 32.6477 33.3247 33.2343C33.3247 34.2163 37.4433 35.0125 42.5241 35.0125C47.6049 35.0125 51.7237 34.2165 51.7237 33.2343C51.7237 32.6479 51.7237 30.9601 51.7237 30.3737Z"
      fill="#FD975D"
    />
    <path
      d="M51.7237 27.1732C51.7237 26.1913 47.6049 25.395 42.5241 25.395C37.4433 25.395 33.3247 26.1911 33.3247 27.1732C33.3247 27.7597 33.3247 29.4472 33.3247 30.0338C33.3247 31.0158 37.4433 31.8118 42.5241 31.8118C47.6049 31.8118 51.7237 31.0158 51.7237 30.0338C51.7237 29.4472 51.7237 27.7595 51.7237 27.1732Z"
      fill="#C2AAFA"
    />
    <path
      d="M42.5243 28.951C47.6051 28.951 51.7239 28.155 51.7239 27.173C51.7239 26.1911 47.6051 25.395 42.5243 25.395C37.4435 25.395 33.3247 26.1911 33.3247 27.173C33.3247 28.155 37.4435 28.951 42.5243 28.951Z"
      fill="#C2AAFA"
    />
    <path
      d="M80.3641 30.374C80.3641 29.392 76.2453 28.5959 71.1645 28.5959C66.0836 28.5959 61.9648 29.392 61.9648 30.374C61.9648 30.9604 61.9648 32.6479 61.9648 33.2346C61.9648 34.2165 66.0836 35.0128 71.1645 35.0128C76.2453 35.0128 80.3641 34.2167 80.3641 33.2346C80.3641 32.6481 80.3641 30.9604 80.3641 30.374Z"
      fill="#F486B8"
    />
    <path
      d="M80.3641 27.1735C80.3641 26.1915 76.2453 25.3953 71.1645 25.3953C66.0836 25.3953 61.9648 26.1913 61.9648 27.1735C61.9648 27.7599 61.9648 29.4474 61.9648 30.0341C61.9648 31.016 66.0836 31.8121 71.1645 31.8121C76.2453 31.8121 80.3641 31.016 80.3641 30.0341C80.3641 29.4474 80.3641 27.7597 80.3641 27.1735Z"
      fill="#C2AAFA"
    />
    <path
      d="M71.1645 28.9513C76.2453 28.9513 80.3641 28.1552 80.3641 27.1733C80.3641 26.1913 76.2453 25.3953 71.1645 25.3953C66.0837 25.3953 61.9648 26.1913 61.9648 27.1733C61.9648 28.1552 66.0837 28.9513 71.1645 28.9513Z"
      fill="#C2AAFA"
    />
    <path
      d="M76.6425 27.7403L78.5468 26.5132L79.57 27.2278C79.4648 27.2753 79.3374 27.3221 79.188 27.3669C78.6982 27.5136 78.0601 27.613 77.4089 27.6782C77.1486 27.7043 76.8899 27.7246 76.6425 27.7403Z"
      fill="#4D3E78"
    />
    <path
      d="M5.78947 20.7378L2.69727 22.5158V24.5756C2.69727 26.6217 4.35554 28.2806 6.40165 28.2806H75.0053C75.7202 28.2806 76.3869 28.0772 76.952 27.7259C77.4469 27.4182 80.0531 26.0741 80.7076 25.4252C81.3837 24.7547 81.8023 23.8254 81.8023 22.7974V20.7378H5.78947Z"
      fill="#4D3E78"
    />
    <path
      d="M2.69727 22.5159V24.5757C2.69727 26.6218 4.35554 28.2807 6.40165 28.2807H75.0053C77.0516 28.2807 78.7103 26.6216 78.7103 24.5757V22.5159H2.69727Z"
      fill="#8D75E6"
    />
    <path
      d="M81.7236 20.7383L43.5865 1L5.56954 20.7383L2.79297 22.5163H78.7049L81.7236 20.7383Z"
      fill="#8D75E6"
    />
    <path
      d="M78.7049 22.5157H2.79297L40.5678 2.77759L78.7049 22.5157Z"
      fill="#C2AAFA"
    />
  </svg>
);
