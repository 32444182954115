export const FedPartnerIcon = () => (
  <svg width="118" height="74" viewBox="0 0 118 74" fill="none">
    <path
      d="M109.914 68.1918C109.914 68.3399 109.845 68.4967 109.689 68.6633C109.532 68.8304 109.293 69.0002 108.971 69.1707C108.327 69.5114 107.37 69.8433 106.128 70.161C103.646 70.7961 100.05 71.3691 95.6001 71.8507C86.7024 72.8136 74.4076 73.4095 60.8254 73.4095C47.2431 73.4095 34.9484 72.8136 26.0507 71.8507C21.6012 71.3691 18.0048 70.7961 15.5228 70.161C14.2809 69.8433 13.3239 69.5114 12.6796 69.1707C12.3574 69.0002 12.1188 68.8304 11.962 68.6633C11.8057 68.4967 11.7369 68.3399 11.7369 68.1918C11.7369 68.0437 11.8057 67.8869 11.962 67.7203C12.1188 67.5532 12.3574 67.3834 12.6796 67.2129C13.3239 66.8722 14.2809 66.5403 15.5228 66.2226C18.0048 65.5875 21.6012 65.0145 26.0507 64.5329C34.9484 63.57 47.2431 62.9742 60.8254 62.9742C74.4076 62.9742 86.7024 63.57 95.6001 64.5329C100.05 65.0145 103.646 65.5875 106.128 66.2226C107.37 66.5403 108.327 66.8722 108.971 67.2129C109.293 67.3834 109.532 67.5532 109.689 67.7203C109.845 67.8869 109.914 68.0437 109.914 68.1918Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.212965"
    />
    <path
      d="M83.0881 68.1621C101.656 68.1621 116.709 53.1095 116.709 34.5413C116.709 15.973 101.656 0.92041 83.0881 0.92041C64.5199 0.92041 49.4673 15.973 49.4673 34.5413C49.4673 53.1097 64.5199 68.1621 83.0881 68.1621ZM83.0881 9.68032C96.8187 9.68032 107.949 20.811 107.949 34.5415C107.949 48.2722 96.8184 59.4028 83.0881 59.4028C69.3578 59.4028 58.227 48.272 58.227 34.5415C58.227 20.811 69.3578 9.68032 83.0881 9.68032Z"
      fill="#8D75E6"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M76.2732 68.1621C94.8414 68.1621 109.894 53.1095 109.894 34.5413C109.894 15.973 94.8414 0.92041 76.2732 0.92041C57.705 0.92041 42.6523 15.973 42.6523 34.5413C42.6523 53.1097 57.705 68.1621 76.2732 68.1621ZM76.2732 9.68032C90.0037 9.68032 101.134 20.811 101.134 34.5415C101.134 48.2722 90.0035 59.4028 76.2732 59.4028C62.5429 59.4028 51.412 48.272 51.412 34.5415C51.412 20.811 62.5429 9.68032 76.2732 9.68032Z"
      fill="#8D75E6"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M49.3174 54.6326C45.2099 57.6312 40.1492 59.4026 34.6741 59.4026C20.9436 59.4026 9.81292 48.2718 9.81292 34.5413C9.81292 20.8107 20.9438 9.6801 34.6741 9.6801C48.4044 9.6801 59.535 20.8107 59.535 34.5413C59.535 36.4969 59.3086 38.3991 58.8816 40.2247C59.7993 44.1475 61.6466 47.7113 64.177 50.6745C66.8016 45.8853 68.2952 40.3878 68.2952 34.5413C68.2952 15.973 53.2423 0.92041 34.6741 0.92041C16.1058 0.92041 1.05322 15.973 1.05322 34.5413C1.05322 53.1095 16.1058 68.1621 34.6741 68.1621C42.5289 68.1621 49.7542 65.4681 55.4774 60.9541C53.1582 59.126 51.0852 57.0002 49.3174 54.6326Z"
      fill="#F486B8"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M51.5239 57.2919C48.4547 58.6478 45.06 59.4024 41.489 59.4024C27.7585 59.4024 16.6279 48.2715 16.6279 34.541C16.6279 20.8105 27.7587 9.67983 41.489 9.67983C55.2193 9.67983 66.35 20.8105 66.35 34.541C66.35 39.5691 64.8558 44.2473 62.2898 48.1593C63.9373 50.6704 66.0263 52.8658 68.4481 54.633C72.6324 49.0278 75.1101 42.0736 75.1101 34.5408C75.1101 15.9725 60.0573 0.919922 41.489 0.919922C22.9208 0.919922 7.86816 15.9725 7.86816 34.5408C7.86816 53.109 22.9208 68.1616 41.489 68.1616C47.8554 68.1616 53.8082 66.3915 58.883 63.3175C56.1511 61.6636 53.6747 59.63 51.5239 57.2919Z"
      fill="#F486B8"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M58.8082 38.6649C58.8074 38.6623 58.8066 38.6597 58.8058 38.6571C58.7384 38.4354 58.6484 38.1183 58.5584 37.7378C58.3781 36.9758 58.1988 35.9632 58.1988 34.9533C58.1988 33.943 58.3946 32.749 58.5919 31.8036C58.6904 31.3318 58.7888 30.9235 58.8627 30.6332C58.8687 30.6097 58.8745 30.5869 58.8802 30.5649C58.8806 30.5665 58.881 30.5682 58.8814 30.5699C58.9422 30.8227 59.0233 31.181 59.1044 31.6023C59.2669 32.4461 59.4284 33.5382 59.4284 34.5416C59.4284 35.5441 59.2507 36.6603 59.0718 37.5288C58.9825 37.9624 58.8931 38.333 58.8262 38.5951C58.82 38.6193 58.814 38.6426 58.8082 38.6649Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.212965"
    />
    <path
      d="M54.9634 65.7319C54.9612 65.7298 54.9589 65.7277 54.9566 65.7254C54.9246 65.6952 54.8772 65.6496 54.8164 65.5888C54.6946 65.4673 54.5189 65.2852 54.3039 65.0439C53.874 64.5612 53.2877 63.8419 52.6638 62.8971C51.4534 61.064 50.1025 58.3834 49.4782 54.9364L49.582 55.0369C49.7997 55.2475 50.114 55.5509 50.5004 55.9226C51.2733 56.666 52.3349 57.6823 53.4897 58.7741C55.7155 60.8785 58.2914 63.2668 59.8127 64.5196L54.9634 65.7319Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.212965"
    />
    <path
      d="M48.4789 13.273C48.3277 13.325 48.1939 13.3884 48.0997 13.4695L48.0902 13.4642C48.0377 13.4351 47.9605 13.3924 47.8618 13.3383C47.6643 13.2299 47.3806 13.0756 47.0352 12.8912C46.3445 12.5226 45.4071 12.0339 44.4206 11.5539C43.4344 11.0742 42.3972 10.6022 41.5075 10.2677C41.1256 10.1241 40.7693 10.0053 40.4548 9.922C40.5447 9.91624 40.6402 9.91077 40.741 9.90579C41.5445 9.86607 42.684 9.85722 44.027 9.98092C46.6259 10.2203 49.9817 10.9557 53.1368 12.92C53.0271 12.9184 52.8971 12.9171 52.7511 12.9164C52.2887 12.9141 51.6658 12.9186 51.0248 12.9453C50.3843 12.972 49.7228 13.0211 49.1842 13.1086C48.9151 13.1523 48.6733 13.2062 48.4789 13.273Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.212965"
    />
    <path
      d="M76.5765 10.1251C76.8641 10.1475 77.1151 10.1708 77.3213 10.1916C75.4667 10.6496 73.3306 11.6008 71.5987 12.4727C70.6318 12.9595 69.7883 13.4229 69.1865 13.7647C68.8856 13.9355 68.6451 14.076 68.4797 14.1739C68.4045 14.2183 68.3448 14.254 68.302 14.2797L66.1949 11.7713C67.021 11.1319 68.1391 10.7109 69.3667 10.4424C70.6354 10.1648 72.0123 10.0521 73.286 10.0255C74.5594 9.999 75.727 10.0587 76.5765 10.1251Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.212965"
    />
  </svg>
);
