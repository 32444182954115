import { styled } from "@linaria/react";
import { fluidFontSize, fluidLineHeight } from "../../styles/helpers.styles";
import { serif } from "../../styles/fonts.styles";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";

export const ArticleLikePageTitle = styled.h1`
  ${fluidFontSize(32, 46)};
  ${fluidLineHeight(38, 52)};
  font-weight: 400;
  font-family: ${serif};
  ${fromTabletMd} {
    letter-spacing: -0.01em;
  }
  &.long {
    ${fluidFontSize(28, 36)};
    ${fluidLineHeight(36, 44)};
  }
`;

export default ArticleLikePageTitle;
