import { graphql, useStaticQuery } from "gatsby";
import { findNextUpcomingBootcamp } from "../../utils/bootcamps.utils";
import {
  DatoCmsWebinar,
  DynamicNextBootcampCtaSectionQuery,
} from "../../../graphql-types";
import { Link } from "@reach/router";
import { color, colors } from "../../styles/colors.styles";
import { styled } from "@linaria/react";
import { useSiteContext } from "../../context/site.context";
import DateTimeFormatter from "../basic/DateTimeFormatter";
import dayjs from "dayjs";
import { getReadableTimezoneName } from "../../utils/time.utils";
import { css, cx } from "linaria";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import Spacing from "../layout/Spacing";
import {
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import workbenchEntryBackdrop from "./workbenchEntryBackdrop.svg";

const linkStyle = css`
  display: block;
  background-color: ${colors.green100};
  padding: ${rSize("md")};
  border: 1px solid ${colors.green200};
  text-decoration: none;
  border-radius: ${rSize("radius")};
  margin-bottom: ${rSize("lg")};
  &:hover {
    background-color: ${color("green", 150)};
    border: 1px solid ${colors.green300};
  }
  &.isWorkbench {
    background-image: url(${workbenchEntryBackdrop});
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }
`;

const LinkTitle = styled.p`
  font-size: 2.4rem;
  font-weight: 400;
  font-family: ${serif};
  line-height: 1.15;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 0 !important;
  max-width: 10em;
  ${fromTabletMd} {
    font-size: 3.2rem;
    letter-spacing: -0.02em;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-areas: "heading" "time";
  grid-gap: 0.25em;
  ${fromPhoneLg} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "time heading";
  }
`;

const PillGroup = styled.div`
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 1.15rem;
  margin-top: -0.25em;
  margin-left: -0.25em;
  &:first-child {
    grid-area: heading;
  }
  &:last-child {
    grid-area: time;
  }
  > * {
    margin: 0.25em;
  }
`;

const Pill = styled.span`
  display: inline-block;
  background-color: ${colors.green200};
  color: ${colors.green700};
  &.dark {
    background-color: ${colors.green700};
    color: ${colors.green50};
  }
  padding: 0.4em 1em;
  border-radius: 2em;
  font-weight: 600;
  letter-spacing: 0.075em;
`;

const Footer = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
`;

const replaceLastSpaceWithNbsp = (str?: string | null) => {
  if (!str) return str;
  const lastSpaceIndex = str.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    str =
      str.substring(0, lastSpaceIndex) +
      "\u00A0" +
      str.substring(lastSpaceIndex + 1);
  }
  return str;
};

const DynamicNextBootcampCTASection = () => {
  const siteContext = useSiteContext();
  const data = useStaticQuery<DynamicNextBootcampCtaSectionQuery>(
    dynamicNextBootcampCtaSectionQuery
  );

  let redirectTo = "";
  const bootcamps = data.webinars.nodes as DatoCmsWebinar[];
  const nextUpcomingBootcamp = findNextUpcomingBootcamp(bootcamps);
  if (nextUpcomingBootcamp) {
    redirectTo = `/bootcamp/${nextUpcomingBootcamp.slug}`;
  } else {
    redirectTo = `/bootcamp-user-guide`;
  }

  const startsAtZeroMinute = nextUpcomingBootcamp?.timeStart
    ? dayjs(`${nextUpcomingBootcamp.timeStart}`).minute() === 0
    : false;
  const isNotCurrentYear = nextUpcomingBootcamp?.timeStart
    ? dayjs(`${nextUpcomingBootcamp.timeStart}`).year() !== dayjs().year()
    : false;

  const isWorkbench = nextUpcomingBootcamp?.title
    ?.toLowerCase()
    .includes("workbench");

  const timezoneName = getReadableTimezoneName(siteContext.timezone);

  return nextUpcomingBootcamp ? (
    <Link
      to={redirectTo}
      className={cx(linkStyle, isWorkbench && "isWorkbench")}
    >
      <Header>
        <PillGroup>
          <Pill>Next Bootcamp</Pill>
        </PillGroup>
        <PillGroup>
          <Pill className="dark">
            <DateTimeFormatter
              value={nextUpcomingBootcamp.timeStart as string | null}
              format={isNotCurrentYear ? "MMM D, YYYY" : "MMM D"}
            />
          </Pill>
          <Pill>
            <DateTimeFormatter
              value={nextUpcomingBootcamp.timeStart as string | null}
              format={startsAtZeroMinute ? "hA" : "h:mm A"}
            />{" "}
            {timezoneName}
            {timezoneName.includes("US") ? "" : " Time"}
          </Pill>
        </PillGroup>
      </Header>
      <Spacing size="1em" />
      <LinkTitle>
        {replaceLastSpaceWithNbsp(nextUpcomingBootcamp?.title)}
      </LinkTitle>
      <Spacing size="1em" />
      <Footer>Learn more and register →</Footer>
    </Link>
  ) : null;
};

export default DynamicNextBootcampCTASection;

export const dynamicNextBootcampCtaSectionQuery = graphql`
  query DynamicNextBootcampCtaSection {
    webinars: allDatoCmsWebinar(
      filter: { category: { slug: { eq: "bootcamp" } } }
      sort: { timeStart: DESC }
    ) {
      nodes {
        id: originalId
        title
        slug
        timeStart
      }
    }
  }
`;
