import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../styles/fonts.styles";
import { WorkbenchDemoTemplateOrStoryEntry } from "./WorkbenchDemoTemplateOrStoryEntry";
import { WorkbenchChatMessage, WorkbenchChatStack } from "./WorkbenchChatStack";
import {
  AWSIcon,
  BambooHRIcon,
  CiscoIcon,
  CrowdStrikeIcon,
  DarktraceIcon,
  GoogleCalendarIcon,
  MagnifierWithEyesIcon,
  RedLockIcon,
  SlackIcon,
  TinesIcon,
} from "./illustrations/WorkbenchDemoTemplateOrStoryIcons";
import PseudoLink from "../basic/PseudoLink";
import WorkbenchDemoTitleBarEndContentButtons from "./illustrations/WorkbenchDemoTitleBarEndContentButtons.svg";
import WorkbenchDemoTitleBarEndContentUserIcon from "./illustrations/WorkbenchDemoTitleBarEndContentUserIcon.svg";

const Frame = styled.div`
  font-size: 1.2rem;
  font-family: ${font("system")};
  font-weight: 500;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: "top" "chat";
  ${fromTablet} {
    grid-template-columns: 200px minmax(0, 1fr);
    grid-template-areas: "top top" "left chat";
  }
  ${fromDesktop} {
    grid-template-columns: 252px minmax(0, 1fr);
  }
  overflow: hidden;
  position: relative;
  pointer-events: all;
  .matter-dragging & {
    pointer-events: none;
  }
`;

const TitleBar = styled.div`
  grid-area: top;
  border-bottom: 1px solid ${colors.light300};
  display: grid;
  align-items: center;
  grid-template-columns: 4rem minmax(auto, 1fr) 4rem;
  padding: 0.8rem;
  user-select: none;
  strong {
    font-weight: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 50vw;
  }
  ${fromTablet} {
    grid-template-columns: 8em minmax(auto, 2fr) 8em;
    padding: 1rem;
  }
  ${fromTabletLg} {
    grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr) minmax(auto, 1fr);
    padding: 1rem;
  }
`;

const TitleBarStart = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.4rem;
  ${fromTablet} {
    padding-left: 1rem;
  }
`;
const TitleBarCenter = styled.div``;
const TitleBarEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  img {
    display: block;
  }
  > * {
    + * {
      margin-left: 10px;
    }
    &:first-child {
      ${onlyPhones} {
        display: none;
      }
    }
  }
`;

const ChatTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  span {
    opacity: 0.6;
  }
  svg {
    margin-left: 0.5em;
  }
`;

export const WorkbenchOpeningDemoAppContents = () => (
  <Frame>
    <TitleBar>
      <TitleBarStart>
        <TitleBarTinesLogo />
      </TitleBarStart>
      <TitleBarCenter>
        <ChatTitle>
          <strong id="WorkbenchOpeningDemoChat__titleContainer">
            New chat
          </strong>{" "}
          <ChevronDown />
        </ChatTitle>
      </TitleBarCenter>
      <TitleBarEnd>
        <img
          width="116"
          height="32"
          src={WorkbenchDemoTitleBarEndContentButtons}
        />
        <img
          width="32"
          height="32"
          src={WorkbenchDemoTitleBarEndContentUserIcon}
        />
      </TitleBarEnd>
    </TitleBar>
    <LeftSidebar />
    <ChatUI />
  </Frame>
);

const TitleBarTinesLogo = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64711 0.893555C4.04166 0.893555 2.62214 1.90826 2.14168 3.39933L0.820312 7.50001L2.14168 11.6007C2.62215 13.0918 4.04166 14.1065 5.64711 14.1065H9.20642C10.8119 14.1065 12.2314 13.0918 12.7119 11.6007L14.0332 7.50001L12.7119 3.39933C12.2314 1.90826 10.8119 0.893555 9.20642 0.893555H5.64711ZM9.20647 1.9243C10.3492 1.9243 11.3595 2.64653 11.7015 3.70781L12.0255 4.71317C11.3737 4.18916 10.5485 3.89422 9.68591 3.89415L5.15799 3.89404C4.30123 3.8941 3.48127 4.18509 2.83162 4.70251L3.15215 3.70781C3.49412 2.64653 4.50447 1.9243 5.64717 1.9243H9.20647ZM3.07605 5.9304L2.25329 6.98466L12.5905 6.98466L11.7678 5.93052C11.2734 5.29701 10.5032 4.92496 9.68583 4.9249H5.13334C4.32522 4.93238 3.56546 5.30329 3.07605 5.9304ZM12.5888 8.0154L11.7678 9.06858C11.2733 9.7028 10.5025 10.0753 9.68456 10.0752L5.15921 10.0751C4.34128 10.0752 3.57044 9.70268 3.07599 9.06847L2.25502 8.0154L12.5888 8.0154ZM3.15208 11.2922C3.49406 12.3535 4.50441 13.0757 5.6471 13.0757H9.20641C10.3491 13.0757 11.3595 12.3535 11.7014 11.2922L12.0256 10.2861C11.3736 10.8108 10.5477 11.106 9.68448 11.106L5.15929 11.1059C4.30182 11.1059 3.48118 10.8146 2.83123 10.2965L3.15208 11.2922Z"
      fill="#333333"
    />
  </svg>
);

const ChevronDown = () => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
    <path
      d="M7.11542 1.26123L4.01865 4.358L0.921875 1.26123"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LeftSidebar = () => (
  <LeftSidebarWrap>
    <LeftSidebarTop>
      <SearchBar>
        <SearchIcon />
        <span>Search templates and stories</span>
      </SearchBar>
      <TemplateAndStoriesToggle>
        <strong>Templates</strong>
        <span>Stories</span>
      </TemplateAndStoriesToggle>
      <SortButton>
        <SortIcon />
        <span>Sort by: Selected</span>
      </SortButton>
    </LeftSidebarTop>
    <LeftSidebarContents>
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<AWSIcon />}
        name="AWS CLI"
        subheading="1 template"
        connected
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<BambooHRIcon />}
        name="BambooHR"
        subheading="6 out of 36 selected"
        connected
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<SlackIcon />}
        name="Slack"
        subheading="5 out of 39 selected"
        connected
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<TinesIcon />}
        name="Tines"
        subheading="24 out of 171 selected"
        connected
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<CiscoIcon />}
        name="Cisco Umbrella"
        subheading="Not connected"
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<CrowdStrikeIcon />}
        name="CrowdStrike"
        subheading="Not connected"
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<DarktraceIcon />}
        name="Darktrace"
        subheading="Not connected"
      />
      <WorkbenchDemoTemplateOrStoryEntry
        icon={<GoogleCalendarIcon />}
        name="Google Calendar"
        subheading="Not connected"
      />
    </LeftSidebarContents>
  </LeftSidebarWrap>
);

const LeftSidebarWrap = styled.div`
  display: none;
  ${fromTablet} {
    grid-area: left;
    display: grid;
    border-right: 1px solid ${colors.light300};
    overflow: hidden;
    grid-template-rows: auto minmax(0, 1fr);
    user-select: none;
  }
`;

const LeftSidebarTop = styled.div`
  padding: 1em;
  border-bottom: 1px solid ${colors.light300};
  > * + * {
    margin-top: 0.5em;
  }
`;

const SearchBar = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto minmax(0, 1fr);
  padding: 0.75em;
  border: 1px solid ${colors.light300};
  border-radius: 8px;
  > * {
    opacity: 0.5;
  }
  svg {
    display: block;
  }
`;

const SearchIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M10.7654 10.2347L8.41839 7.88811C9.09865 7.07141 9.43787 6.02389 9.36547 4.96345C9.29307 3.90302 8.81463 2.91132 8.02967 2.19466C7.24472 1.47799 6.21369 1.09154 5.15106 1.11569C4.08844 1.13984 3.07603 1.57274 2.32444 2.32432C1.57286 3.0759 1.13996 4.08831 1.11581 5.15094C1.09166 6.21357 1.47812 7.2446 2.19478 8.02955C2.91145 8.81451 3.90314 9.29295 4.96358 9.36535C6.02401 9.43775 7.07153 9.09853 7.88823 8.41827L10.2348 10.7653C10.2696 10.8001 10.311 10.8278 10.3565 10.8466C10.402 10.8655 10.4508 10.8752 10.5001 10.8752C10.5494 10.8752 10.5982 10.8655 10.6437 10.8466C10.6892 10.8278 10.7306 10.8001 10.7654 10.7653C10.8003 10.7305 10.8279 10.6891 10.8468 10.6436C10.8656 10.5981 10.8753 10.5493 10.8753 10.5C10.8753 10.4507 10.8656 10.4019 10.8468 10.3564C10.8279 10.3109 10.8003 10.2695 10.7654 10.2347ZM1.87511 5.24999C1.87511 4.58247 2.07305 3.92995 2.4439 3.37494C2.81475 2.81992 3.34185 2.38734 3.95855 2.13189C4.57525 1.87645 5.25385 1.80961 5.90854 1.93983C6.56322 2.07006 7.16459 2.3915 7.63659 2.8635C8.1086 3.3355 8.43003 3.93687 8.56026 4.59156C8.69048 5.24624 8.62365 5.92484 8.3682 6.54154C8.11276 7.15824 7.68017 7.68535 7.12516 8.0562C6.57014 8.42705 5.91762 8.62499 5.25011 8.62499C4.35531 8.62399 3.49744 8.2681 2.86472 7.63538C2.232 7.00265 1.8761 6.14479 1.87511 5.24999Z"
      fill="currentColor"
    />
  </svg>
);

const TemplateAndStoriesToggle = styled.div`
  display: flex;
  background-color: ${colors.light100};
  border: 1px solid ${colors.light300};
  border-radius: 8px;
  padding: 1px;
  text-align: center;
  ${uptoTabletLg} {
    display: none;
  }
  span,
  strong {
    flex: 1 1 auto;
    padding: 0.4em;
    border-radius: 6px;
    width: 4.5em;
    border: 1px solid transparent;
    color: ${withOpacity(colors.purple800, 0.5)};
  }
  strong {
    color: ${colors.purple800};
    background-color: ${colors.lightest};
    border-color: ${colors.light300};
    font-weight: inherit;
  }
`;

const SortButton = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto minmax(0, 1fr);
  padding: 0.75em;
  border: 1px solid ${colors.light300};
  border-radius: 8px;
  svg {
    display: block;
  }
`;

const SortIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M9.375 6.375C9.375 6.47446 9.33549 6.56984 9.26517 6.64017C9.19484 6.71049 9.09946 6.75 9 6.75H3C2.90054 6.75 2.80516 6.71049 2.73484 6.64017C2.66451 6.56984 2.625 6.47446 2.625 6.375C2.625 6.27554 2.66451 6.18016 2.73484 6.10983C2.80516 6.03951 2.90054 6 3 6H9C9.09946 6 9.19484 6.03951 9.26517 6.10983C9.33549 6.18016 9.375 6.27554 9.375 6.375ZM10.875 3.75H1.125C1.02554 3.75 0.930161 3.78951 0.859835 3.85984C0.789509 3.93016 0.75 4.02554 0.75 4.125C0.75 4.22446 0.789509 4.31984 0.859835 4.39016C0.930161 4.46049 1.02554 4.5 1.125 4.5H10.875C10.9745 4.5 11.0698 4.46049 11.1402 4.39016C11.2105 4.31984 11.25 4.22446 11.25 4.125C11.25 4.02554 11.2105 3.93016 11.1402 3.85984C11.0698 3.78951 10.9745 3.75 10.875 3.75ZM7.125 8.25H4.875C4.77554 8.25 4.68016 8.28951 4.60984 8.35983C4.53951 8.43016 4.5 8.52554 4.5 8.625C4.5 8.72446 4.53951 8.81984 4.60984 8.89017C4.68016 8.96049 4.77554 9 4.875 9H7.125C7.22446 9 7.31984 8.96049 7.39017 8.89017C7.46049 8.81984 7.5 8.72446 7.5 8.625C7.5 8.52554 7.46049 8.43016 7.39017 8.35983C7.31984 8.28951 7.22446 8.25 7.125 8.25Z"
      fill="#4D3E78"
    />
  </svg>
);

const LeftSidebarContents = styled.div`
  padding: 0.5em;
`;

const ChatUIWrap = styled.div`
  grid-area: chat;
  background-color: ${colors.light100};
  overflow: hidden;
  text-align: center;
  border-bottom-right-radius: 1.2rem;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  align-content: end;
  justify-content: end;
  position: relative;
`;

const messages: WorkbenchChatMessage[] = [
  {
    sender: "user",
    type: "text",
    contents: "Hey, can you reset Jennifer Browne’s Okta password?",
  },
  {
    sender: "ai",
    type: "text",
    contents: (
      <p>
        To proceed, we need to confirm Jennifer Browne’s email address. Let me
        search for that information in our systems.
      </p>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <MagnifierWithEyesIcon />,
    name: "Lookup user on multiple platforms",
    subheading: "Security",
    requiresConfirmation: false,
  },
  {
    sender: "ai",
    type: "text",
    contents: (
      <>
        <p>
          <span>I’ve found that information, her email address is </span>
          <PseudoLink defaultCursor>jbrowne@example.com</PseudoLink>
          <span>.</span>
        </p>
        <p>
          I’ll use the “Reset password in Okta” function to reset her password
          to a random value and clear her active sessions.
        </p>
      </>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <RedLockIcon />,
    name: "Reset password in Okta",
    subheading: "Security",
    requiresConfirmation: true,
  },
  {
    sender: "ai",
    type: "text",
    contents: (
      <>
        <p>
          I’ve successfully reset Jennifer Browne’s password in Okta. A password
          reset link has been generated:
        </p>
        <pre children="https://company.okta.com/reset_password/qVMCdmdS54u9" />
        <p>
          Please ensure that you communicate this link securely, as it will
          allow direct access to reset the password.
        </p>
      </>
    ),
  },
  {
    sender: "ai",
    type: "text",
    contents: <p>Can I help with any related tasks?</p>,
  },
  {
    sender: "user",
    type: "text",
    contents: "Thank you that’s all for now",
  },
  {
    sender: "ai",
    type: "text",
    contents: <p>Always happy to help. Have a great day.</p>,
  },
];

const ChatUI = () => (
  <ChatUIWrap id="WorkbenchOpeningDemoChatUI">
    <WorkbenchChatStack
      id="WorkbenchOpeningDemoChat"
      messages={messages}
      chatTitle="Reset Jennifer’s Okta password"
      withEmptyStateScreen
      withMessageComposer
      animated
      padding="1.5em"
    />
  </ChatUIWrap>
);
