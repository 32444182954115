import { LibraryCollectionType } from "./libraryCollectionType.enum";

export const Paths = {
  home: () => `/`,
  login: () => "https://login.tines.com",
  signup_webhook: () =>
    `https://hq.tines.io/webhook/b5bee396bc6288ff41f5a178ba9380b7/15e40f8226982fdde178c0c248f2aef6`,
  signup_loginTinesCom: (paramsString?: string) =>
    `https://login.tines.com/signup${paramsString ?? ""}`,
  signup_workbench: () =>
    `https://login.tines.com/saml_idp/choose_tenant?intended_path=/workbench`,
  whatsNew: () => `/whats-new`,
  product: () => `/product`,
  buildApps: () => `/product/build-apps`,
  workbench: () => `/product/workbench`,
  cases: () => "/product/cases",
  ai: () => "/product/ai",
  pricing: () => `/pricing`,
  partners: () => `/partners`,
  federal: () => `/federal`,
  professionalServices: () => `/professional-services`,
  formulas: () => `/product/formulas`,
  mssps: () => `/mssps`,
  ydwwt: () => `/you-did-what-with-tines`,
  ydwwtTerms: () => `/ydwwt-terms`,
  blog: () => `/blog`,
  podcast: () => `/podcast`,
  events: () => `/events`,
  webinars: () => `/webinars`,
  caseStudies: () => `/case-studies`,
  caseStudiesAll: () => `/case-studies/all`,
  caseStudy: (slug?: null | string) => `/case-studies/${slug ?? ""}`,
  solutionPage: (slug?: null | string) => `/solutions/${slug ?? ""}`,
  solutionsByProduct: () => `/solutions/products`,
  reportSoc2023: () => `/reports/voice-of-the-soc-2023`,
  reportSoc2023Access: () => `/access/report-voice-of-the-soc-2023`,
  reportSoc2023Pdf: () =>
    `/reports/${encodeURIComponent(
      "Tines Report - Voice of the SOC 2023.pdf"
    )}`,
  guideSecurity: {
    page: () => `/guides/essential-guide-to-workflow-automation/security`,
    accessPage: () =>
      `/access/guide/the-essential-guide-to-workflow-automation-for-security-teams`,
    pdf: () => `/downloads/Tines-EssentialGuide-Security.pdf`,
  },
  cisoPerspectivesAI: {
    page: () => `/reports/ciso-perspectives-ai/`,
    accessPage: () => `/access/report/ciso-perspectives-ai/`,
    pdf: () => `/downloads/Tines Report - CISO perspectives - AI.pdf`,
  },
  library: {
    home: () => "/library",
    stories: (o?: { query?: string }) =>
      [`/library/stories`, o?.query ? `s=${encodeURIComponent(o.query)}` : ""]
        .filter(i => i)
        .join("?"),
    story: (id: string, slug?: string | null) =>
      `/library/stories/${id}?name=${slug}`,
    tools: () => `/library/tools`,
    tool: (toolSlug: string) => `/library/tools/${toolSlug}`,
    collection: (type?: string | null, slug?: string | null) =>
      [
        "/library",
        type === LibraryCollectionType.team
          ? "teams"
          : type === LibraryCollectionType.useCase
          ? "use-cases"
          : type === LibraryCollectionType.productFeature
          ? "product-features"
          : "",
        slug,
      ]
        .filter(i => i)
        .join("/"),
  },
  university: () => "/university",
  bookADemo: (paramsString?: string) => `/book-a-demo${paramsString ?? ""}`,
  docs: () => "/docs/quickstart",
  apiDocs: () => "/api/welcome",
  getCertified: () => "/get-certified",
  about: () => "/about",
  newsroom: () => "/newsroom",
  legal: () => "/legal",
  careers: () => "/careers",
  contact: () => "/contact",
  contactSupport: () => "/contact#support",
  tinesExplained: () => "https://explained.tines.com/en/",
  trustCenter: () => "https://trust.tines.com/",
  bootcamps: () => "/bootcamps",
  termsCE: () => "/terms",
  termsPublicSector: () => "/general-terms-public-sector-customers-aug-2024",
  termsGeneral: () => "/general-terms-aug-2024",
  privacy: () => "/privacy",
  cookiesPolicy: () => "/cookies-policy",
  security: () => "/security",
  gdprRequest: () => "/gdpr-request",
  supportReferenceGuide: () => `/support-reference-guide`,
  subProcessors: () => "/sub-processors",
  dataProcessingAddendum: () => "/data-processing-addendum-feb-2024",
  dataProtectionNotice: () =>
    "/data-protection-notice-for-recruitment-and-job-applications",
  cloudServiceAddendum: () => "/cloud-service-addendum-aug-2024",
  informationSecurityAddendum: () => "/information-security-addendum-feb-2024",
  selfHostedAddendum: () => "/self-hosted-software-addendum-feb-2024",
  professionalServicesAddendum: () =>
    "/professional-services-addendum-aug-2024",
  fairUsePolicy: () => "/fair-use-policy-aug-2024",
  supportServicesPolicy: () => "/support-services-policy-feb-2024",
  serviceLevelAgreement: () => "/service-level-agreement-aug-2024",
  marketingReferenceAgreement: () => "/marketing-reference-agreement-may-2024",
  mspAgreement: () => "/msp-agreement-jul-2024",
  oneTimeResellerAgreement: () => "/one-time-reseller-agreement-jul-2024",
  linkedIn: () => "https://www.linkedin.com/company/tines-io/",
  twitter: () => "https://twitter.com/tines_hq",
  youTube: () => "https://www.youtube.com/@TinesHQ",
  slackCommunity: () =>
    "https://hq.tines.io/forms/6f8b122ccba3cb7e8e0d3531d1b70eb2",
};
