import Spacing from "../../components/layout/Spacing";
import { ExplosionCTASection } from "../../components/reusableSections/ExplosionCTASection";
import SEO from "../../components/utilities/SEO";
import { WorkbenchPageOpeningDemoSection } from "../../components/workbench/WorkbenchPageOpeningDemoSection";
import { WorkbenchPageHero } from "../../components/workbench/WorkbenchPageHero";
import WorkbenchPageIntroSection from "../../components/workbench/WorkbenchPageIntroSection";
import { colors } from "../../styles/colors.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { WorkbenchExplainedSection } from "../../components/workbench/WorkbenchExplainedSection";
import WorkbenchPageSeeItInActionSection from "../../components/workbench/WorkbenchPageSeeItInActionSection";
import { graphql } from "gatsby";
import { reportIntent } from "../../utils/intentReporter.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { WorkbenchPageQueryQuery } from "../../../graphql-types";
import ogImage from "../../../static/images/og/tines-workbench.png";
import WorkbenchPageRelatedContentSection from "../../components/workbench/WorkbenchPageRelatedContentSection";
import { styled } from "@linaria/react";
import { ConnectorPathsPainter } from "../../components/decorations/ConnectorPathsPainter";
import { WorkbenchRiverOfShapesResizeManager } from "../../components/workbench/WorkbenchRiverOfShapesResizeManager";
import { WorkbenchCustomerQuotesSection } from "../../components/workbench/WorkbenchCustomerQuotesSection";
import { AdminToolBarWithEditInDatoCmsButton } from "../../components/site/AdminToolbar";

const MainBody = styled.div`
  position: relative;
`;

const defaultDescription =
  "A Tines-powered AI chat interface where you can take action and access proprietary data in real-time, privately and securely.";

const PageWorkbench: PageComponent<{
  data: WorkbenchPageQueryQuery;
}> = props => {
  useOnMount(() => {
    reportIntent("Viewed Workbench page");
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title="Workbench"
        seoTitle={props.data.workbench?.seoTitle}
        description={props.data.workbench?.seoDescription ?? defaultDescription}
        image={ogImage}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="workbenchPage"
        /* cspell:disable */
        recordId="bRnXsEdxSDeNZUR7-gDT7w"
        /* cspell:enable */
        label="Edit in CMS"
      />

      <WorkbenchRiverOfShapesResizeManager />

      <ConnectorPathsPainter />

      <WorkbenchPageHero />
      <Spacing size="subsectionMargin" />
      <WorkbenchPageOpeningDemoSection />
      <Spacing size="subsectionMargin" />

      <MainBody>
        <WorkbenchPageIntroSection />
        <Spacing size="subsectionMargin" />
        <WorkbenchExplainedSection />
        <Spacing size="subsectionMargin" />
        <WorkbenchPageSeeItInActionSection />
        <Spacing size="subsectionMargin" />
        <WorkbenchCustomerQuotesSection />
        <Spacing size="subsectionMargin" />
        <WorkbenchPageRelatedContentSection />

        <Spacing size="sectionMargin" />
        <ExplosionCTASection />
      </MainBody>
    </Page>
  );
};

export const workbenchPageQuery = graphql`
  query WorkbenchPageQuery {
    workbench: datoCmsWorkbenchPage {
      seoTitle
      seoDescription
    }
  }
`;

export default PageWorkbench;
