import { graphql } from "gatsby";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { PageComponentProps } from "../types/gatsby.types";
import { CaseStudyBySlugQuery, DatoCmsCaseStudy } from "../../graphql-types";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import Page from "./Page.template";
import StoryEmbedListSection from "../components/reusableSections/StoryEmbedListSection";
import { ValidStoryEmbed } from "../utils/datocms.utils";
import CaseStudyHeroSection from "../components/customers/CaseStudyHeroSection";
import CaseStudyPageContent from "../components/customers/CaseStudyPageContent";
import CaseStudyReadMoreSection from "../components/customers/CaseStudyReadMoreSection";
import StandardCTASection from "../components/sections/StandardCTASection";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { getBrandColorTheme } from "../styles/colors.styles";

const CaseStudyPageTemplate = (
  props: PageComponentProps & {
    data: CaseStudyBySlugQuery;
  }
) => {
  const caseStudy = props.data.caseStudy as DatoCmsCaseStudy | undefined;

  useOnMount(() => {
    reportIntent(
      `Viewed case study of ${caseStudy?.customer?.name ?? "Unknown customer"}`
    );
  });

  if (!caseStudy) return null;

  const theme = getBrandColorTheme(caseStudy.color);

  return (
    <Page {...props} theme={theme.name}>
      <SEO
        title={caseStudy.title ? caseStudy.title : "Customer Case Study"}
        description={caseStudy.excerpt}
        image={caseStudy.coverImage?.url}
        ogType="article"
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="caseStudy"
        recordId={caseStudy.id}
      />

      <CaseStudyHeroSection caseStudy={caseStudy} />

      <Spacing size="sectionPadding" />

      <CaseStudyPageContent caseStudy={caseStudy} />

      {caseStudy.featuredStories && caseStudy.featuredStories.length > 0 && (
        <>
          <Spacing size="sectionPadding" />
          <StoryEmbedListSection
            records={
              caseStudy.featuredStories.filter(i => i) as ValidStoryEmbed[]
            }
            linkToTag={caseStudy.storyLibraryTag}
          />
        </>
      )}

      <Spacing size="sectionMargin" />

      <CaseStudyReadMoreSection
        availableRecords={props.data.relatedRecords.nodes as DatoCmsCaseStudy[]}
      />

      <Spacing size="sectionMargin" />

      <StandardCTASection />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const caseStudyBySlugQuery = graphql`
  query CaseStudyBySlug($slug: String) {
    caseStudy: datoCmsCaseStudy(slug: { eq: $slug }) {
      id: originalId
      title
      slug
      datePublished
      excerpt
      color
      highlights
      featuredQuote
      featuredQuoteCover
      coverImage {
        url
        width
        height
      }
      storyLibraryTag
      featuredStories {
        id
        __typename
        pillText
        storyUrl
        overrideColor
      }
      customer {
        name
        logoDarkGray {
          url
        }
      }
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              width
              height
              url
            }
            caption
            link
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
            color
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
            aspectRatio
            aspectRatioMobile
            height
            heightMobile
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            storyUrl
            pillText
            fullWidth
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsInArticleStoryGrid {
            id: originalId
            preheading
            heading
            description
            linkTextLabel
            linkUrl
            image {
              url
              width
              height
            }
            maxNumberOfStories
            stories {
              id: originalId
              name
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            collection {
              id: originalId
              name
              slug
            }
            tool {
              id: originalId
              name
              slug
            }
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
          ... on DatoCmsDataCardGrid {
            id: originalId
            cards {
              data
              label
              color
            }
          }
          ... on DatoCmsStructuredTextBlock {
            id: originalId
            structuredText {
              value
              blocks
            }
            backdropColor
            appearance
          }
          ... on DatoCmsProductFeatureCardGrid {
            id: originalId
            features {
              featureName
              color
            }
          }
        }
      }
    }
    relatedRecords: allDatoCmsCaseStudy(
      sort: { position: ASC }
      filter: { hiddenInIndex: { ne: true }, slug: { ne: $slug } }
    ) {
      nodes {
        id: originalId
        title
        slug
        datePublished
        position
        excerpt
        color
        featuredQuote
        featuredData
        featuredDataLabel
        featuredTags
        coverImage {
          url
          width
          height
        }
        customer {
          name
          logoDarkGray {
            url
          }
        }
      }
    }
  }
`;

export default CaseStudyPageTemplate;
