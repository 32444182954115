/* eslint-disable no-console */
import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { when } from "../../utils/promises.utils";
import { loadScript } from "../../utils/script.utils";
import { locateUserIP } from "../../utils/country.utils";
import { useSiteContext } from "../../context/site.context";
import { isKnownCountry } from "../../constants/countryList.constant";
import LoadingIndicator from "../utilities/LoadingIndicator";
import { ChiliPiper } from "../../types/chilipiper.types";
import { renderInPortal } from "../../utils/portals.utils";
import DemoCheckingAvailabilityView from "./DemoCheckingAvailabilityView";
import { DemoInfoFormObject, makeDemoInfoForm } from "./makeDemoInfoForm";
import DemoInfoForm from "./DemoInfoForm";
import axios from "axios";
import { getCookie } from "../../utils/cookies.utils";
import { DemoCalendarOverlay } from "./DemoCalendarOverlay";
import { navigate } from "gatsby";
import DemoNoAvailabilityView from "./DemoNoAvailabilityView";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import { makeFormData } from "../../utils/formData.utils";
import { getUrlQueryParams } from "../../utils/urlQueryParams.utils";
import { USStateList } from "../../constants/USStateList.constant";
import { CanadianStateList } from "../../constants/CanadianStateList.constant";
import {
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { getFormMetaInfo } from "../../utils/form.utils";

let chiliPiperLoaded = false;

export const testDemoNoAvailability = false;

declare global {
  interface Window {
    /**
     * Refer to https://help.chilipiper.com/hc/en-us/articles/360053800373 for details
     */
    ChiliPiper: ChiliPiper;
  }
}

const Container = styled.div`
  ${fromTabletLg} {
    height: 55rem;
  }
`;

const LoadingState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
  ${uptoTabletLg} {
    background-color: ${colors.purple300};
    color: ${colors.white};
  }
  ${fromTabletLg} {
    padding: 6em 3em;
  }
`;

export type DemoFormProps = {
  leadSource?: string;
  campaignId?: string;
  onShouldClose?: () => void;
};

const DemoForm = (props: DemoFormProps) => {
  const { location } = useSiteContext();
  const [ready, setReady] = useState(false);
  const [chiliPiperUnavailable, setChiliPiperUnavailable] = useState(true);
  const [submittedToHQ, setSubmittedToHQ] = useState(true);
  const [chiliPiperFrameLoaded, setChiliPiperFrameLoaded] = useState(false);
  const [chiliPiperHasAvailabilityRef, setChiliPiperHasAvailability] =
    useStateWithRef<boolean | null>(null);
  const [requestSentToChiliPiperRef, setRequestSentToChiliPiper] =
    useStateWithRef(false);
  const [timeslotSelectedRef, setTimeslotSelected] = useStateWithRef(false);

  const { marketingAttribution: _ma, ...rest } = getFormMetaInfo();

  const formState = useState({
    ...makeDemoInfoForm({
      leadSource: props.leadSource,
      campaignId: props.campaignId,
      url: location.href,
    }),
    ...rest,
  });

  const [form] = formState;
  const formRef = useRef(form);
  formRef.current = form;

  const setForm = (newForm: Partial<DemoInfoFormObject>) => {
    if (newForm.state) {
      if (
        newForm.country === "United States" &&
        !USStateList.includes(newForm.state)
      ) {
        newForm.state = "";
      }
      if (
        newForm.country === "Canada" &&
        !CanadianStateList.includes(newForm.state)
      ) {
        newForm.state = "";
      }
    }
    formState[1]({ ...formRef.current, ...newForm });
  };

  const watchForChiliPiperFrame = () => {
    when(
      () => !!document.querySelector("iframe#chilipiper-frame"),
      () => {
        setChiliPiperFrameLoaded(true);
      }
    );
  };

  const handleWhenRequestToChiliPiperIsSent = () => {
    setRequestSentToChiliPiper(true);
    if (testDemoNoAvailability) {
      setTimeout(() => {
        setChiliPiperHasAvailability(false);
      }, 1000);
    }
    watchForChiliPiperFrame();
  };
  const handleWhenSubmittedToHQ = () => {
    setSubmittedToHQ(true);
  };

  const reportRequestCancel = () => {
    try {
      navigator.sendBeacon(
        "https://hq.tines.io/webhook/bbe32fd362ccf12f262dbb5cdb1aa2f4/06c20b354ebde0c6bec3afb1ecf147ef",
        makeFormData(form)
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleRequestCancel = () => {
    navigate("/demo-cancelled");
    reportRequestCancel();
  };

  useOnMount(() => {
    (async () => {
      await (async function prefillForm() {
        const urlParams = getUrlQueryParams<Partial<DemoInfoFormObject>>();
        const prefill = makeDemoInfoForm();

        const email = getCookie("email_address");
        if (email) prefill.email = email;
        const firstName = getCookie("first_name");
        if (firstName) prefill.firstName = firstName;
        const lastName = getCookie("last_name");
        if (lastName) prefill.lastName = lastName;
        const company = getCookie("company");
        if (company) prefill.company = company;

        if (urlParams.firstName) prefill.firstName = urlParams.firstName;
        if (urlParams.lastName) prefill.lastName = urlParams.lastName;
        if (urlParams.email) prefill.email = urlParams.email;
        if (urlParams.company) prefill.company = urlParams.company;
        if (urlParams.phone) prefill.phone = urlParams.phone;
        if (urlParams.country) prefill.country = urlParams.country;
        if (urlParams.state) prefill.state = urlParams.state;
        if (urlParams.postcode) prefill.postcode = urlParams.postcode;
        if (urlParams.message) prefill.message = urlParams.message;

        setForm(prefill);

        if (!formRef.current.country && !prefill.country) {
          const ipReport = await locateUserIP();
          if (isKnownCountry(ipReport.country)) {
            prefill.country = ipReport.country;
            if (!prefill.state) prefill.state = ipReport.regionName;
            setForm(prefill);
          }
        }
      })();

      if (chiliPiperLoaded) {
        setReady(true);
      } else {
        try {
          await loadScript({
            src: "https://js.chilipiper.com/marketing.js",
            onLoad: () => {
              chiliPiperLoaded = true;
              setReady(true);
            },
            onError: () => {
              axios.post(
                "https://hq.tines.io/webhook/74a0915175a12c1e4ee145124d3bbf20/e4c4563aa6dc96f9f3dcc4a5c952fb91",
                {
                  email: formRef.current.email,
                  unsubmittedForm: formRef.current,
                }
              );
              setReady(true);
              setChiliPiperUnavailable(true);
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Failed to load ChiliPiper", e);
          setChiliPiperUnavailable(true);
        }
      }
    })();

    const handleWindowMessage = (event: MessageEvent<{ action: string }>) => {
      const { action } = event.data;
      // console.log(action);
      switch (action) {
        case "no-free-slots": {
          setChiliPiperHasAvailability(false);
          break;
        }
        case "availability-loaded": {
          setChiliPiperHasAvailability(true);
          break;
        }
        case "timeslot-selected": {
          setTimeslotSelected(true);
          break;
        }
      }
    };

    const handleBeforeUnload = () => {
      const cancellingRequest =
        requestSentToChiliPiperRef.current && !timeslotSelectedRef.current;
      if (cancellingRequest) {
        reportRequestCancel();
      }
    };

    window.addEventListener("message", handleWindowMessage);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("message", handleWindowMessage);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  const checkingAvailability =
    requestSentToChiliPiperRef.current &&
    chiliPiperHasAvailabilityRef.current === null;

  return (
    <Container>
      {ready ? (
        <>
          {!requestSentToChiliPiperRef.current ? (
            <DemoInfoForm
              formState={formState}
              whenRequestToChiliPiperIsSent={
                handleWhenRequestToChiliPiperIsSent
              }
              onSubmittedToHQ={handleWhenSubmittedToHQ}
            />
          ) : (
            /* info form has been submitted */
            <>
              {checkingAvailability && <DemoCheckingAvailabilityView />}
              {chiliPiperHasAvailabilityRef.current === false && (
                <DemoNoAvailabilityView />
              )}
              {renderInPortal(
                <DemoCalendarOverlay
                  shouldRender={
                    !!(
                      chiliPiperFrameLoaded &&
                      chiliPiperHasAvailabilityRef.current
                    )
                  }
                  canCancel={!timeslotSelectedRef.current}
                  onCancel={handleRequestCancel}
                />
              )}
            </>
          )}
        </>
      ) : (
        <LoadingState>
          <LoadingIndicator size={24} />
        </LoadingState>
      )}
    </Container>
  );
};

export default DemoForm;
