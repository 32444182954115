export const datoCmsItemTypes = {
  article: { id: "1283713", name: "article", plural: "articles" },
  articleCategory: {
    id: "1283711",
    name: "blog category",
    plural: "blog categories",
  },
  productExplorer: {
    // cspell:disable
    id: "TMeX0_mPSnKMnTUHNZW0OA",
    // cspell:enable
    name: "product explorer",
    plural: "product explorer",
  },
  product: {
    // cspell:disable
    id: "dJ71rXIkRFOIZBmqQ6oRsw",
    // cspell:enable
    name: "product",
    plural: "products",
  },
  productFamily: {
    // cspell:disable
    id: "XLdjFyfnTZGuP7jlWwAXKQ",
    // cspell:enable
    name: "product family",
    plural: "product families",
  },
  productCategory: {
    // cspell:disable
    id: "VH2JZDmwQgqF7HzvTT0i3Q",
    // cspell:enable
    name: "product category",
    plural: "product categories",
  },
  webinar: { id: "1283710", name: "webinar", plural: "webinars" },
  event: { id: "1771007", name: "event", plural: "events" },
  caseStudy: { id: "1318024", name: "case study", plural: "case studies" },
  solutionPage: {
    id: "2132689",
    name: "solution page",
    plural: "solution pages",
  },
  partnerPage: { id: "2011928", name: "partner page", plural: "partner pages" },
  libraryCollection: {
    id: "2089965",
    name: "collection",
    plural: "collections",
  },
  libraryTool: { id: "2041217", name: "tool", plural: "tools" },
  story: { id: "2104163", name: "story", plural: "stories" },
  docsPage: { id: "1995708", name: "docs page", plural: "docs pages" },
  apiPage: { id: "1995708", name: "API page", plural: "API pages" },
  universityLesson: { id: "2040160", name: "lesson", plural: "lessons" },
  productUpdate: {
    id: "1995943",
    name: "product update",
    plural: "product updates",
  },
  genericPage: { id: "1991663", name: "page", plural: "pages" },
  videoLesson: { id: "1995899", name: "video lesson", plural: "video lessons" },
  home: {
    id: "1283705",
    name: "Homepage",
    plural: "Homepage",
  },
  federalPage: {
    id: "K1SkTA2rQT23vq-aVF_0vg",
    name: "Federal page",
    plural: "Federal page",
  },
  aiPage: {
    /* cspell:disable */
    id: "ReOF8Co9RAiaD0Kpddy1wQ",
    /* cspell:enable */
    name: "AI page",
    plural: "AI page",
  },
  workbenchPage: {
    /* cspell:disable */
    id: "YzrtXTbCQsK18XIinbYNvA",
    /* cspell:enable */
    name: "Workbench page",
    plural: "Workbench page",
  },
  casesPage: {
    /* cspell:disable */
    id: "KyyqVuTHSm6MLohVyJKUfw",
    /* cspell:enable */
    name: "Cases page",
    plural: "Cases page",
  },
  mediaMention: {
    id: "1739858",
    name: "media mention",
    plural: "media mentions",
  },
  document: {
    // cspell:disable
    id: "MAxUzocjSUeD8-erGcUGFQ",
    // cspell:enable
    name: "document",
    plural: "documents",
  },
  ydwwt: {
    // cspell:disable
    id: "VWgrHAPxS3G9fxATQiUivA",
    // cspell:enable
    name: "YDWWT page",
    plural: "YDWWT pages",
  },
};

export type DatoCMSItemTypeName = keyof typeof datoCmsItemTypes;
