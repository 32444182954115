export const LogoHashiCorp = () => (
  <svg width="134" height="38" viewBox="0 0 134 38" fill="currentColor">
    <title>HashiCorp</title>
    <path d="M45.5227 27.1058V19.944H39.1943V27.1058H35.9722V10.1235H39.1943V17.1089H45.5227V10.1235H48.7447V27.1058H45.5227Z" />
    <path d="M60.5574 27.105H57.9987L57.7671 26.2647C56.6612 26.9802 55.3707 27.3611 54.0518 27.3616C51.7705 27.3616 50.7974 25.8061 50.7974 23.6637C50.7974 21.1396 51.8994 20.171 54.4352 20.171H57.4318V18.8689C57.4318 17.493 57.0484 17.0087 55.0507 17.0087C53.8887 17.0167 52.7304 17.1402 51.5933 17.3775L51.2099 15.0074C52.6036 14.6233 54.0425 14.426 55.4886 14.4204C59.4068 14.4204 60.5603 15.7963 60.5603 18.9105L60.5574 27.105ZM57.4318 22.4128H55.1281C54.1034 22.4128 53.8231 22.6919 53.8231 23.638C53.8231 24.5039 54.1034 24.8888 55.0794 24.8888C55.9063 24.8755 56.7166 24.6549 57.4348 24.2474L57.4318 22.4128Z" />
    <path d="M66.6053 27.3585C65.1427 27.3346 63.6919 27.0936 62.3008 26.6433L62.7358 24.2732C63.9443 24.6173 65.1938 24.7975 66.4506 24.8088C67.8332 24.8088 68.0391 24.488 68.0391 23.5579C68.0391 22.7946 67.8844 22.4098 65.8613 21.9255C62.8131 21.1846 62.4551 20.4213 62.4551 18.0255C62.4551 15.527 63.5571 14.4302 67.1176 14.4302C68.3773 14.4313 69.6323 14.5766 70.8586 14.8631L70.5365 17.3359C69.4036 17.1279 68.2564 17.0088 67.1049 16.9799C65.7484 16.9799 65.5164 17.3006 65.5164 18.0511C65.5164 19.0454 65.5937 19.1223 67.2854 19.5553C70.7783 20.4758 71.1131 20.9344 71.1131 23.4713C71.1131 25.88 70.3687 27.3585 66.6053 27.3585Z" />
    <path d="M80.4121 27.1054V18.4458C80.4121 17.7819 80.1318 17.4516 79.4132 17.4516C78.6464 17.4516 77.2865 17.9102 76.1587 18.4972V27.1054H73.0332V9.61313L76.1587 9.18018V15.6299C77.5736 14.907 79.1292 14.4977 80.7181 14.4304C82.7932 14.4304 83.5342 15.8832 83.5342 18.1027V27.1054H80.4121Z" />
    <path d="M85.8418 12.9293V9.33398H88.9673V12.9293H85.8418ZM85.8418 27.1052V14.6869H88.9673V27.1052H85.8418Z" />
    <path d="M91.1455 14.7895C91.1455 11.6785 92.9628 9.86963 97.2161 9.86963C98.7789 9.86929 100.336 10.0577 101.853 10.4309L101.498 13.2084C100.117 12.9478 98.7155 12.803 97.3095 12.7754C95.0797 12.7754 94.3645 13.5419 94.3645 15.3412V21.8679C94.3645 23.6767 95.0831 24.4337 97.3095 24.4337C98.7155 24.4061 100.117 24.2613 101.498 24.0007L101.856 26.7814C100.339 27.1545 98.7818 27.343 97.2195 27.3426C92.9661 27.3426 91.1489 25.5337 91.1489 22.4227L91.1455 14.7895Z" />
    <path d="M108.265 27.3592C103.986 27.3592 102.835 25.0147 102.835 22.465V19.3283C102.835 16.7786 103.989 14.4341 108.265 14.4341C112.541 14.4341 113.694 16.7786 113.694 19.3283V22.465C113.684 25.0147 112.534 27.3592 108.265 27.3592ZM108.265 17.0961C106.599 17.0961 105.961 17.837 105.961 19.2385V22.5676C105.961 23.9692 106.605 24.71 108.265 24.71C109.924 24.71 110.569 23.9692 110.569 22.5676V19.2257C110.562 17.8241 109.921 17.0833 108.265 17.0833V17.0961Z" />
    <path d="M121.985 17.2878C120.838 17.8052 119.732 18.4099 118.679 19.0967V27.1148H115.553V14.6868H118.192L118.399 16.0627C119.415 15.3775 120.518 14.8286 121.679 14.4302L121.985 17.2878Z" />
    <path d="M133.836 22.974C133.836 25.729 132.605 27.3583 129.712 27.3583C128.592 27.3466 127.476 27.2263 126.38 26.9991V32.1306L123.254 32.5636V14.6865H125.739L126.061 15.7321C127.2 14.8857 128.584 14.4299 130.005 14.4331C132.515 14.4331 133.845 15.9117 133.845 18.7437L133.836 22.974ZM126.383 24.3755C127.293 24.5748 128.22 24.6822 129.151 24.6963C130.279 24.6963 130.714 24.1607 130.714 23.0381V18.6603C130.714 17.6404 130.33 17.0791 129.177 17.0791C128.142 17.1162 127.153 17.5134 126.383 18.2016V24.3755Z" />
    <path d="M12.3151 2.94531L0 10.0173V10.0205V26.9995L4.62702 29.6583V12.676L12.3151 8.25648V2.94531Z" />
    <path d="M17.2288 2.94531V16.4702H12.315V11.422L7.68799 14.0808V31.4095L12.315 34.0746V20.5883H17.2288V25.6012L21.8558 22.9456V5.6041L17.2288 2.94531Z" />
    <path d="M17.229 34.0812L29.5441 27.0092V27.0028V10.0269L24.9171 7.36816V24.3505L17.229 28.77V34.0812Z" />
  </svg>
);
