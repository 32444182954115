export const FedAccessControlsIcon = () => (
  <svg width="75" height="79" viewBox="0 0 75 79" fill="none">
    <path
      d="M38.3702 31.3154C38.3702 28.8744 36.3912 26.8955 33.95 26.8955H9.08705C7.88161 26.8955 6.78954 27.3786 5.99238 28.1609L5.9919 28.1602L5.98366 28.1691C5.89371 28.2578 1.80324 32.6727 1.80324 32.6727L1.80404 32.6733C0.999935 33.4737 0.501221 34.5808 0.501221 35.805V49.22C0.501221 51.6611 2.48008 53.6401 4.92115 53.6401H29.7841C31.0646 53.6401 32.2172 53.095 33.0244 52.225L33.0247 52.2256L37.1693 47.7589L37.169 47.7586C37.9137 46.9679 38.3702 45.9026 38.3702 44.7304V31.3154V31.3154Z"
      fill="#A990F5"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.2039 49.2202C34.2039 51.6613 32.2249 53.6403 29.7838 53.6403H4.92091C2.47983 53.6403 0.500977 51.6613 0.500977 49.2202V35.8052C0.500977 33.3641 2.47983 31.3853 4.92091 31.3853H29.7838C32.2249 31.3853 34.2039 33.364 34.2039 35.8052V49.2202Z"
      fill="#A990F5"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M30.2715 31.4124L34.2038 26.9033"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M34.197 35.5598L38.3548 30.9595"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M50.0962 78.6021H23.6533V66.3281H64.0252L50.0962 78.6021Z"
      fill="#4D3E78"
    />
    <path
      d="M48.783 0.787598H39.1367V66.5254H48.783V0.787598Z"
      fill="#2A7857"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.2518 62.5508L46.1304 62.5763L32.2403 71.6541H22.8342L39.2518 62.5508Z"
      fill="#2A7857"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.4049 0.698242L22.7024 12.5775V78.3151H32.3486L49.051 66.4357V0.698242H39.4049ZM45.9542 62.5059L35.7401 69.3362V15.5248L45.9542 7.90276V62.5059Z"
      fill="#49B889"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M32.3486 12.5776H22.7024V78.3152H32.3486V12.5776Z"
      fill="#49B889"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M32.3486 12.5775L49.051 0.698242"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M39.2112 16.2091L46.0749 11.741L45.9541 7.90283L39.2112 12.9791V16.2091Z"
      fill="#4D3E78"
    />
    <path
      d="M73.6395 31.3154C73.6395 28.8744 71.6605 26.8955 69.2194 26.8955H44.3563C43.1511 26.8955 42.059 27.3786 41.2617 28.1609L41.2614 28.1602L41.2531 28.1691C41.1632 28.2578 37.0727 32.6727 37.0727 32.6727L37.0733 32.6733C36.2692 33.4737 35.7705 34.5808 35.7705 35.805V49.22C35.7705 51.6611 37.7492 53.6401 40.1904 53.6401H65.0535C66.3339 53.6401 67.4867 53.095 68.2939 52.225L68.2942 52.2256L72.4386 47.7589L72.4383 47.7586C73.183 46.9679 73.6395 45.9026 73.6395 44.7304V31.3154V31.3154Z"
      fill="#EB81AF"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M69.4735 49.2202C69.4735 51.6613 67.4945 53.6403 65.0535 53.6403H40.1902C37.7491 53.6403 35.7703 51.6613 35.7703 49.2202V35.8052C35.7703 33.3641 37.749 31.3853 40.1902 31.3853H65.0533C67.4943 31.3853 69.4734 33.364 69.4734 35.8052V49.2202H69.4735Z"
      fill="#EB81AF"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M37.0703 63.6533H43.6868L35.9678 68.9665V64.6561L37.0703 63.6533Z"
      fill="#4D3E78"
    />
    <path
      d="M34.0835 48.7414C34.6856 48.7414 35.1737 47.8557 35.1737 46.763C35.1737 45.6704 34.6856 44.7847 34.0835 44.7847C33.4815 44.7847 32.9934 45.6704 32.9934 46.763C32.9934 47.8557 33.4815 48.7414 34.0835 48.7414Z"
      fill="#49B889"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0835 42.8469C34.6856 42.8469 35.1737 41.9611 35.1737 40.8685C35.1737 39.7759 34.6856 38.8901 34.0835 38.8901C33.4815 38.8901 32.9934 39.7759 32.9934 40.8685C32.9934 41.9611 33.4815 42.8469 34.0835 42.8469Z"
      fill="#EB81AF"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M39.1306 53.435C39.3861 53.435 39.1306 31.5518 39.1306 31.5518C39.1306 31.5518 37.2367 32.0464 36.5466 33.3283C35.9813 34.378 35.8198 36.1545 35.8198 36.1545C35.8198 36.1545 35.8198 48.8323 35.8198 49.882C35.8198 50.9318 37.1118 53.435 39.1306 53.435Z"
      fill="#4D3E78"
    />
  </svg>
);
