/* eslint-disable no-console */
import { css, cx } from "@linaria/core";
import { createElement } from "react";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";

const withCustomInlineFormattingCss = css`
  em {
    font-family: ${font("serif")};
    font-style: normal;
    font-weight: 400;
  }
  code {
    font-size: 95%;
    background-color: ${withOpacity(colorsV4.green, 0.1)};
    color: ${colorsV4.green700};
    padding: 0.1em 0.4em;
    border-radius: 0.35em;
    ${darkModeLinariaCSS(`color: ${colorsV4.green};`)}
  }
  pre[data-language] {
    font-size: 1.2rem;
  }
`;
export const stripCustomInlineFormattingSyntax = (input?: string | null) => {
  try {
    return input === null || input === undefined
      ? ""
      : `${input}`
          .replace(/(.|^)\*([^*]+?)\*/g, (match, $1, $2) =>
            $1 === "\\" ? match : `${$1}${$2}`
          )
          .replace(/(.|^)_([^_]+?)_/g, (match, $1, $2) =>
            $1 === "\\" ? match : `${$1}${$2}`
          )
          .replace(/```(?:\w+\n)?([^`]*)```/g, "$1")
          .replace(/(.|^)`([^`]*)`/g, (match, $1, $2) =>
            $1 === "\\" ? match : `${$1}${$2}`
          )
          .replace(/\n/g, " ")
          .replace(/\\\\/g, " ")
          .replace(/\/\//g, " ")
          .replace(/\\([_*`])/g, "$1")
          .replace(/\s\s+/g, " ");
  } catch (e) {
    console.warn(e);
    return input ?? "";
  }
};

export const getCustomInlineFormattedHtml = (input?: string | null) => {
  try {
    return input === null || input === undefined
      ? ""
      : `${input}`
          .replace(/\\\\/g, "<br />")
          .replace(/\/\//g, "<br />")
          .replace(/(.|^)\*([^*]+)\*/g, (match, $1, $2) =>
            $1 === "\\" ? match : `${$1}<em>${$2}</em>`
          )
          .replace(/(.|^)_([^_]+)_/g, (match, $1, $2) =>
            $1 === "\\" ? match : `${$1}<i>${$2}</i>`
          )
          .replace(
            /```(.*)\n([^`]*)```/g,
            (match, lang, content) =>
              `<pre data-language="${lang}">${content}</pre>`
          )
          .replace(
            /```([^`]*)```/g,
            (match, content) => `<pre>${content}</pre>`
          )
          .replace(/(.|^)`([^`]*)`/g, (match, $1, $2) =>
            $1 === "\\" ? match : `${$1}<code>${$2}</code>`
          )
          .replace(/\\([_*`])/g, "$1")
          .replace(/\n/g, "<br />");
  } catch (e) {
    console.warn(e);
    return input ?? "";
  }
};

/**
 *
 * Supported custom formatting:
 *
 * - Wrapping in <em>: Example *text snippet*
 * - Wrapping in <i>: Example _text snippet_
 * - Add a <br />: Example // text (or Example \\ text)
 * - Wrapping in <code>: Example `text snippet`
 * - Wrapping in <pre>: Example ```text snippet```
 * - Wrapping in <pre data-language="javascript">:
 *      Example
 *      ```javascript
 *        text snippet
 *      ```
 * - Escaping: \*, \_ and \` will be ignored.
 *   e.g. with "`FORMULA\_FUNCTION\_NAME`", the "FUNCTION" part will not be wrapped in <i>
 *
 */
const WithCustomInlineFormatting = (props: {
  id?: string;
  className?: string;
  as: keyof HTMLElementTagNameMap;
  children?: string | null | undefined;
}) => {
  return createElement(props.as ?? "p", {
    id: props.id,
    className: cx(withCustomInlineFormattingCss, props.className),
    dangerouslySetInnerHTML: {
      __html: getCustomInlineFormattedHtml(props.children),
    },
  });
};

export default WithCustomInlineFormatting;
