import { styled } from "@linaria/react";
import Spacing from "../layout/Spacing";
import {
  fromDesktop,
  fromDesktopHd,
  fromDesktopLg,
  fromDesktopMd,
  fromDesktopXl,
  fromTablet,
  onlyPhones,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";
import { ButtonGroupCenteredUptoDesktop } from "../forms/Button";
import BookADemoButton from "../utilities/BookADemoButton";
import SignUpButton from "../utilities/SignUpButton";
import {
  AnnouncementBar,
  AnnouncementBarOuter,
} from "../general/AnnouncementBar";
import {
  HomeHeroIllustration,
  homeHeroIllustrationMobileRatio,
} from "./heroIllustration/_HomeHeroIllustration";
import { FeaturedClientLogoMarquee } from "../general/FeaturedClientLogoMarquee";
import { graphql, useStaticQuery } from "gatsby";
import { HomeHeroQuery } from "../../../graphql-types";

const HomeHeroWrap = styled.section`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(auto, 1fr) auto;
  user-select: none;
  min-height: 580px;
  ${uptoDesktop} {
    padding-top: 2em;
  }
  ${fromDesktop} {
    height: calc(var(--vh, 100vh) - var(--pageTopFixedElementsHeight));
    max-height: 760px;
  }
  ${fromDesktopXl} {
    margin-top: 2em;
  }
`;

const Inner = styled.div`
  align-self: center;
  position: relative;
  width: 100%;
  ${widerPageSectionMaxWidthStyle()};
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  ${fromTablet} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${uptoDesktop} {
    grid-template-columns: minmax(0, 1fr);
  }
  ${fromDesktop} {
    grid-template-columns: repeat(2, 1fr);
    align-items: end;
  }
`;

const TextWrap = styled.div`
  ${uptoDesktop} {
    text-align: center;
  }
  ${fromDesktop} {
    padding-right: 7rem;
  }
  ${fromDesktopLg} {
    padding-left: ${rSize("widerPageMargin")};
  }
  ${AnnouncementBarOuter} {
    ${uptoDesktop} {
      justify-content: center;
    }
  }
  h1 {
    font-size: 4.2rem;
    font-weight: 300;
    font-family: ${serif};
    line-height: 1;
    letter-spacing: -0.01em;
    ${fromTablet} {
      font-size: 5.2rem;
      letter-spacing: -0.02em;
    }
    ${fromDesktop} {
      font-size: 4.8rem;
      letter-spacing: -0.01em;
    }
    ${fromDesktopMd} {
      font-size: 6.4rem;
    }
    ${fromDesktopLg} {
      font-size: 7.2rem;
      letter-spacing: -0.025em;
    }
    span {
      letter-spacing: 0;
    }
  }
  p {
    max-width: 22em;
    font-size: 1.4rem;
    ${fromTablet} {
      max-width: 50rem;
      font-size: 1.6rem;
    }
    ${uptoDesktop} {
      margin-left: auto;
      margin-right: auto;
    }
    ${fromDesktop} {
      font-size: 1.4rem;
      max-width: 26em;
    }
    ${fromDesktopMd} {
      font-size: 1.6rem;
    }
  }
`;

const IllustrationWrap = styled.div`
  ${onlyPhones} {
    margin: 0 -1em;
    aspect-ratio: ${homeHeroIllustrationMobileRatio};
  }
`;

const MarqueeWrap = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  ${fromTablet} {
    padding-top: 3em;
  }
  ${fromDesktop} {
    padding-bottom: 3em;
  }
  ${fromDesktopLg} {
    padding-top: 4em;
    padding-bottom: 4em;
  }
  ${fromDesktopXl} {
    padding-top: 4.5em;
  }
  ${fromDesktopHd} {
    padding-bottom: 5em;
  }
`;

const HomeHero = () => {
  const data = useStaticQuery<HomeHeroQuery>(homeHeroQuery);
  const announcement = {
    tag: data.home?.announcementTag,
    body: data.home?.announcementBody,
    link: data.home?.announcementLink,
  };
  return (
    <HomeHeroWrap>
      <Inner>
        <Grid>
          <TextWrap>
            {announcement.body && announcement.link && (
              <AnnouncementBar tag={announcement.tag} link={announcement.link}>
                {announcement.body}
              </AnnouncementBar>
            )}
            <Spacing size="1.75em" />
            <h1>
              The smart, secure
              <br /> work<span>fl</span>ow builder
            </h1>
            <Spacing size="1em" />
            <p>
              Build, run, and monitor workflows with the most intuitive platform
              for security-minded teams.
            </p>
            <Spacing size="2em" />
            <ButtonGroupCenteredUptoDesktop>
              <BookADemoButton appearance="filled-white" width="8em" />
              <SignUpButton appearance="outlined" color="white" width="8em" />
            </ButtonGroupCenteredUptoDesktop>
          </TextWrap>
          <IllustrationWrap>
            <HomeHeroIllustration />
          </IllustrationWrap>
        </Grid>
      </Inner>
      <MarqueeWrap>
        <FeaturedClientLogoMarquee />
      </MarqueeWrap>
    </HomeHeroWrap>
  );
};

export const homeHeroQuery = graphql`
  query HomeHero {
    home: datoCmsHomepage {
      announcementTag
      announcementBody
      announcementLink
    }
  }
`;

export default HomeHero;
