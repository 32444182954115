import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import PageSection from "../reusableSections/PageSection";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  uptoDesktop,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { WorkbenchOpeningDemoAppContents } from "./WorkbenchOpeningDemoAppContents";
import { css } from "linaria";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPathsPainter";

const AppFramePositioner = styled.div`
  position: relative;
  max-width: ${widthInGrid(10)};
  margin-left: auto;
  margin-right: auto;
`;

const AppFrame = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  background-color: ${colors.lightest};
  border-radius: 1.4rem;
  height: 420px;
  ${uptoDesktop} {
    overflow: hidden;
  }
  ${fromTabletLg} {
    height: 480px;
  }
  ${fromDesktop} {
    height: 560px;
  }
  border: 2px solid ${colors.purple200};
  position: relative;
`;

const Bullets = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;
  ${fromDesktop} {
    display: block;
  }
`;

const BulletContent = styled.div``;

const Bullet = styled.li`
  position: absolute;
  font-size: 1.4rem;
  ${fromDesktop} {
    padding: ${rSize("gap")};
    width: 20rem;
  }
  ${fromDesktopMd} {
    width: 25rem;
  }
  h4 {
    font-size: 1.4rem;
    ${fromDesktop} {
      font-size: 1.6rem;
    }
    + * {
      margin-top: 0.6rem;
    }
  }
  p {
    line-height: 1.3;
    font-size: 1.3rem;
    ${fromDesktopMd} {
      font-size: 1.4rem;
    }
  }
  ${uptoDesktop} {
    text-align: left;
    &:nth-child(1) {
      top: 8.4rem;
      left: 4.8rem;
      ${fromTabletMd} {
        top: 8.8rem;
        left: 6.4rem;
      }
      i {
        animation-delay: 0.3s;
      }
      ${BulletContent} {
        left: 100%;
        transform-origin: top left;
      }
    }
    &:nth-child(2) {
      top: calc(8.4rem + 59%);
      left: calc(4.8rem + 7%);
      ${fromTabletMd} {
        top: calc(8.8rem + 59%);
      }
      i {
        animation-delay: 0.5s;
      }
      ${BulletContent} {
        left: 100%;
        transform-origin: top left;
      }
    }
    &:nth-child(3) {
      top: calc(8.4rem + 33%);
      left: calc(4.8rem + 70%);
      ${fromTabletMd} {
        left: calc(4.8rem + 45%);
      }
      i {
        animation-delay: 0.7s;
      }
      ${BulletContent} {
        ${uptoTabletMd} {
          right: 100%;
          transform-origin: top right;
          border-radius: 0.75em 0.25em 0.75em 0.75em;
        }
        ${fromTabletMd} {
          left: 100%;
          transform-origin: top left;
        }
      }
    }
  }
  ${fromDesktop} {
    &:nth-child(1) {
      top: 16rem;
      right: 100%;
      text-align: right;
    }
    &:nth-child(2) {
      top: 10rem;
      left: 100%;
      text-align: left;
    }
    &:nth-child(3) {
      top: 35rem;
      left: 100%;
      text-align: left;
    }
  }
`;

const Bump1Positioner = styled.div`
  position: absolute;
  top: 13.5rem;
  right: 100%;
  ${uptoDesktop} {
    display: none;
  }
`;
const Bump2Positioner = styled.div`
  position: absolute;
  top: 7.5rem;
  left: 100%;
  ${uptoDesktop} {
    display: none;
  }
`;
const Bump3Positioner = styled.div`
  position: absolute;
  top: 32.5rem;
  left: 100%;
  ${uptoDesktop} {
    display: none;
  }
`;

export const WorkbenchPageOpeningDemoSection = () => (
  <PageSection
    relative
    className={css`
      ${fromTablet} {
        pointer-events: none;
      }
    `}
  >
    <AppFramePositioner>
      <AppFrame>
        <Bump1Positioner>
          <BumpLeft />
        </Bump1Positioner>
        <Bump2Positioner>
          <BumpRight />
        </Bump2Positioner>
        <Bump3Positioner>
          <BumpRight />
        </Bump3Positioner>
        <WorkbenchOpeningDemoAppContents />
      </AppFrame>
      <Bullets>
        <Bullet>
          <BulletContent>
            <h4>Connect anything</h4>
            <p>
              Workbench, like all of Tines, integrates with anything. You define
              the available tools for LLM interactions.
            </p>
          </BulletContent>
        </Bullet>
        <Bullet>
          <BulletContent>
            <h4>Control data interactions</h4>
            <p>
              Determine what stories or systems are available for Workbench
              interactions based on user roles.
            </p>
          </BulletContent>
        </Bullet>
        <Bullet>
          <BulletContent>
            <h4>Use natural language</h4>
            <p>
              Describe in a message what you are aiming to achieve. This can be
              formed as a question or a statement.
            </p>
          </BulletContent>
        </Bullet>
      </Bullets>
      <ConnectorPathWaypoint
        id="workbench-page-quote-section"
        index={0}
        color={colors.purple100}
        bottom="0"
        left="50%"
      />
    </AppFramePositioner>
  </PageSection>
);

const BumpLeft = () => (
  <svg width="12" height="111" viewBox="0 0 12 111" fill="none">
    <path
      d="M10 25.7058V1C10 0.447715 10.4477 0 11 0C11.5523 0 12 0.437459 12 0.989744V109.51C12 110.063 11.5523 110.5 11 110.5C10.4477 110.5 10 110.052 10 109.5V86.2942C10 80.8904 8.37175 75.6119 5.32757 71.1471C-0.902339 62.0099 -0.902339 49.9901 5.32757 40.8529C8.37175 36.3881 10 31.1096 10 25.7058Z"
      fill="#D7C4FA"
    />
  </svg>
);

const BumpRight = () => (
  <svg width="12" height="111" viewBox="0 0 12 111" fill="none">
    <path
      d="M2 25.7058V1C2 0.447715 1.55229 0 1 0C0.447716 0 0 0.437459 0 0.989744V109.51C0 110.063 0.447716 110.5 1 110.5C1.55229 110.5 2 110.052 2 109.5V86.2942C2 80.8904 3.62825 75.6119 6.67243 71.1471C12.9023 62.0099 12.9023 49.9901 6.67243 40.8529C3.62825 36.3881 2 31.1096 2 25.7058Z"
      fill="#D7C4FA"
    />
  </svg>
);
