import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  onlyPhones,
  uptoDesktopMd,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "../reusableSections/PageSection";
import { colors } from "../../styles/colors.styles";
import { EscherFrameIcon } from "./spotIcons/EscherFrameIcon";
import { SectionHeading2 } from "../typography/SectionHeading2";
import {
  getWidthPxInMaxGrid,
  gridGapPx,
} from "../../constants/globalGrid.constants";
import { serif } from "../../styles/fonts.styles";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import LogoIntercom from "../thirdPartyLogos/LogoIntercom";
import LogoMars20h from "../thirdPartyLogos/LogoMars20h";
import { w } from "../../utils/string.utils";
import { LogoKnowBe4 } from "../thirdPartyLogos/LogoKnowBe4";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import { Link } from "gatsby";
import { Paths } from "../../utils/pathBuilders.utils";
import { css } from "linaria";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { ExternalLinkIcon } from "../basic/ExternalLinkIcon";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";

const Positioner = styled.div`
  ${uptoDesktopMd} {
    margin-bottom: 1.5em;
  }
  ${fromDesktopMd} {
    margin-left: ${gridGapPx(-2)};
    margin-right: ${gridGapPx(-2)};
  }
`;

const Wrap = styled.div`
  position: relative;
  ${fromDesktopMd} {
    display: grid;
    grid-template-columns: minmax(auto, 5fr) minmax(
        auto,
        calc(${getWidthPxInMaxGrid(7)} + ${gridGapPx(3)})
      );
  }
`;

const Header = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  ${uptoDesktopMd} {
    text-align: center;
  }
  ${fromDesktopMd} {
    color: ${colors.purple800};
    background-color: ${colors.lightest};
    border: 1px solid ${colors.purple200};
    margin-right: -1px;
    margin-bottom: 47px;
    border-radius: 8px 0 0 8px;
    padding: ${gridGapPx(2)};
  }
  grid-gap: ${gridGapPx(2)};
  z-index: 1;
  ${fromDesktopMd} {
    &:after {
      content: "";
      display: block;
      /** cspell:disable */
      background-image: url("data:image/svg+xml,%3Csvg width='72' height='47' viewBox='0 0 72 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1603_1373)'%3E%3Cpath d='M0.5 0.5V46.5H0.85753L71.5 0.726562V0.5H0.5Z' fill='%23C2AAFA' stroke='%23D7C4FA'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1603_1373'%3E%3Crect width='72' height='47' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      /** cspell:enable */
      width: 72px;
      height: 47px;
      position: absolute;
      top: 100%;
      right: 0;
    }
  }
`;

const IconWrap = styled.div``;

const HeaderContent = styled.div`
  ${mediaFromTo("tablet", "desktopMd")} {
    margin-bottom: 3em;
  }
  h2 {
    ${uptoTablet} {
      span {
        display: block;
      }
    }
    ${fromDesktopMd} {
      span {
        display: block;
      }
    }
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
  > p + p {
    margin-top: 0.75em;
  }
  p {
    ${onlyPhones} {
      max-width: 20em;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const G2RatingsBannerStyle = css`
  border-radius: 8px;
  padding: 1em 1.25em;
  display: inline-flex;
  text-align: left;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  color: ${colors.purple800};
  ${uptoDesktopMd} {
    margin-top: 2em;
  }
  ${fromDesktopMd} {
    display: flex;
    &:hover {
      background-color: ${colors.purple100};
      border-color: ${colors.purple200};
    }
  }
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: background-color 0.1s;
  user-select: none;
  > div {
    display: flex;
    align-items: center;
    + * {
      margin-left: 2em;
    }
    > * + * {
      margin-left: 0.75em;
    }
    &:last-child {
      text-align: right;
    }
  }
  svg {
    display: block;
    flex: 0 0 auto;
  }
  p {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.4rem;
    svg {
      display: inline-block;
    }
    span {
      display: inline-block;
    }
  }
`;

const Content = styled.div`
  background-color: ${colors.lightest};
  color: ${colors.purple800};
  border: 1px solid ${colors.purple200};
  overflow: hidden;
  ${onlyPhones} {
    margin-top: -2em;
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
  ${fromTablet} {
    border-radius: 8px;
  }
  ${fromDesktopMd} {
    margin-top: 47px;
    margin-left: -72px;
    border-radius: 0 8px 8px 0;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 5em repeat(5, auto) 5em;
  grid-template-areas:
    "deco1 deco1"
    "quote1 quote1"
    "stat1 stat2"
    "quote2 quote2"
    "quote3 quote3"
    "stat3 stat3"
    "deco2 deco2";
  grid-gap: 1.2rem;
  ${uptoTablet} {
    margin-left: -32px;
    margin-right: -32px;
  }
  ${fromTablet} {
    grid-template-columns: 0 repeat(7, minmax(0, 1fr)) 0;
    grid-template-rows: auto;
    grid-template-areas:
      "${w("deco1", 4)} ${w(".", 4)} deco2"
      "${w("deco1", 4)} ${w("stat1", 2)} ${w("stat2", 2)} deco2"
      ". ${w("quote1", 3)} ${w("stat1", 2)} ${w("stat2", 2)} deco2"
      ". ${w("quote1", 3)} ${w("quote2", 3)} deco3 deco3"
      ". ${w("quote1", 3)} ${w("quote2", 3)} deco3 deco3"
      "${w("footer", 3)} ${w("quote3", 3)} ${w("stat3", 2)} deco4"
      "${w("footer", 3)} ${w("deco6", 3)} ${w("deco5", 2)} deco4";
    [id*="home-customer-section"] {
      transform: none !important;
    }
  }
  ${fromTabletLg} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(9, 1fr);
    margin-right: ${gridGapPx(-2)};
  }
  > * {
    border-radius: 8px;
  }
`;

const QuoteCardStyle = css`
  padding: 1em;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  text-decoration: none;
  border-radius: inherit;
  p {
    font-size: 1.8rem;
    font-family: ${serif};
    letter-spacing: -0.01em;
    line-height: 1.2;
    ${fromTabletMd} {
      font-size: 2rem;
    }
    svg {
      vertical-align: baseline;
      transform: translateY(0.2em);
      margin-right: 0.2em;
    }
  }
  > svg {
    margin-top: 1em;
  }
`;

const Quote1Wrap = styled.div`
  grid-area: quote1;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.green50};
    border: 1px solid ${colors.green200};
    color: ${colors.green700};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.green100};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 3fr minmax(260px, 10fr) 2fr;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.purple50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.pink50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const Quote2Wrap = styled.div`
  grid-area: quote2;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.orange50};
    border: 1px solid ${colors.orange200};
    color: ${colors.orange700};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.orange100};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 2fr minmax(260px, 10fr) 3fr;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.purple50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.orange50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const Quote3Wrap = styled.div`
  grid-area: quote3;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.pink50};
    border: 1px solid ${colors.pink200};
    color: ${colors.pink700};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.pink100};
      }
    }
  }
  ${onlyPhones} {
    grid-gap: inherit;
    grid-template-columns: 3fr minmax(260px, 10fr) 2fr;
    &:before {
      content: "";
      background-color: ${colors.orange50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.green50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const StatCardStyle = css`
  color: ${colors.white};
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  padding: 1em;
  min-height: 150px;
  ${fromTablet} {
    min-height: 16.8rem;
  }
  text-decoration: none;
  transition: background-color 0.1s;
  border-radius: inherit;
  svg {
    max-width: 100%;
    height: auto;
  }
  p {
    font-size: 1.3rem;
    strong {
      display: block;
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: -0.01em;
      line-height: 1;
    }
  }
  &[data-area="stat2"] {
    background-color: ${colors.pink};
    grid-area: stat2;
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.pink600};
      }
    }
  }
  &[data-area="stat3"] {
    background-color: ${colors.purple};
    grid-area: stat3;
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.purple600};
      }
    }
  }
`;

const Stat1Wrap = styled.div`
  grid-area: stat1;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.orange};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.orange600};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: minmax(0, 2fr) 150px;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.green50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
`;

const Stat2Wrap = styled.div`
  grid-area: stat2;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.pink};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.pink600};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 150px minmax(0, 2fr);
    grid-gap: inherit;
    &:after {
      content: "";
      background-color: ${colors.purple50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;
const Stat3Wrap = styled.div`
  grid-area: stat3;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.purple};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.purple600};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 2fr 150px 3fr;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.pink50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.purple50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const Deco1 = styled.div`
  display: grid;
  grid-area: deco1;
  grid-template-columns: 2fr 3fr 4fr;
  grid-gap: inherit;
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) ${gridGapPx(5)};
  }
  span {
    display: block;
    &:nth-child(1) {
      background-color: ${colors.orange50};
      border-bottom-right-radius: 8px;
    }
    &:nth-child(2) {
      background-color: ${colors.pink50};
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:nth-child(3) {
      ${fromTablet} {
        display: none;
      }
      background-color: ${colors.green50};
      border-bottom-left-radius: 8px;
    }
  }
`;

const Deco2 = styled.div`
  grid-area: deco2;
  display: block;
  ${fromTablet} {
    background-color: ${colors.green50};
    border-top-left-radius: 0;
  }
  ${onlyPhones} {
    display: grid;
    grid-template-columns: 4fr 3fr 2fr;
    grid-gap: inherit;
    span {
      display: block;
      &:nth-child(1) {
        background-color: ${colors.orange50};
        border-top-right-radius: 8px;
      }
      &:nth-child(2) {
        background-color: ${colors.pink50};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:nth-child(3) {
        ${fromTablet} {
          display: none;
        }
        background-color: ${colors.green50};
        border-top-left-radius: 8px;
      }
    }
  }
`;
const Deco3 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco3;
  }
  background-color: ${colors.purple50};
`;
const Deco4 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco4;
  }
  background-color: ${colors.orange50};
`;
const Deco5 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco5;
  }
  background-color: ${colors.pink50};
  height: ${rSize("gap")};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
const Deco6 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco6;
  }
  background-color: ${colors.green50};
  height: ${rSize("gap")};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Footer = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  ${onlyPhones} {
    position: absolute;
    bottom: 0;
    left: 1em;
    right: 1em;
    transform: translateY(50%);
  }
  ${fromTablet} {
    grid-area: footer;
    grid-template-columns: minmax(0, 1fr) ${gridGapPx(5)};
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: "span1 link" "span1 span2";
    grid-gap: inherit;
  }
  a {
    display: grid;
    border-radius: 8px;
    grid-template-areas: "text icon";
    padding: 1.5em;
    ${fromTablet} {
      grid-template-areas: "icon" "text";
      grid-template-rows: minmax(auto, 1fr) auto;
      padding: 0.75em;
    }
    grid-gap: ${rSize("gap")};
    background-color: ${colors.purple50};
    border: 1px solid ${colors.purple200};
    color: ${colors.purple700};
    text-decoration: none;
    ${fromTablet} {
      height: ${gridGapPx(5)};
    }
    transition: background-color 0.1s;
    user-select: none;
    &:hover {
      background-color: ${colors.purple100};
      svg {
        transform: translateX(2px);
      }
    }
    strong {
      font-size: 1.4rem;
      ${fromTabletMd} {
        font-size: 1.3rem;
        b {
          font-weight: inherit;
          display: block;
        }
      }
      line-height: 1;
      font-weight: 600;
    }
    svg {
      grid-area: icon;
      margin-left: auto;
      transition: transform 0.25s;
    }
  }
  span {
    display: none;
    ${fromTablet} {
      display: block;
      &:first-of-type {
        grid-area: span1;
        background-color: ${colors.purple50};
        border-top-right-radius: 8px;
      }
      &:last-of-type {
        grid-area: span2;
        background-color: ${colors.orange50};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
`;

export const HomeCustomersSection = () => {
  useOnMount(() => {
    const tl = gsap.timeline({ paused: true });
    [
      "#home-customer-section-deco-1",
      "#home-customer-section-quote-1",
      ["#home-customer-section-stat-1", "#home-customer-section-stat-2"],
      "#home-customer-section-quote-2",
      "#home-customer-section-quote-3",
      "#home-customer-section-stat-3",
      "#home-customer-section-deco-2",
    ].forEach((id, i) => {
      tl.fromTo(
        id,
        { x: (i % 2 === 0 ? 1 : -1) * 12 },
        {
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: id instanceof Array ? id[0] : id,
            scrub: 1,
            start: "top bottom",
            end: "bottom top",
            invalidateOnRefresh: true,
          },
        }
      );
    });
    return () => {
      tl.kill();
    };
  });
  return (
    <PageSection>
      <Positioner>
        <Wrap>
          <Header>
            <IconWrap>
              <EscherFrameIcon />
            </IconWrap>
            <HeaderContent>
              <SectionHeading2 lighter>
                <span>Sounds… </span>
                <span>hard to believe?</span>
              </SectionHeading2>
              <p>
                We get it. A lot of products make bold claims. There’s no way
                one product could do all this. Right?
              </p>
              <p>Don’t take our word for it.</p>
              <a
                className={G2RatingsBannerStyle}
                href="https://www.g2.com/products/tines/reviews"
                {...externalLinkAttr}
              >
                <div>
                  <G2Logo />
                  <p>
                    200+ reviews{" "}
                    <span>
                      on G2.com{}
                      <ExternalLinkIcon />
                    </span>
                  </p>
                </div>
                <div>
                  <RatingStarSet />
                  <p>4.8</p>
                </div>
              </a>
            </HeaderContent>
          </Header>
          <Content>
            <ContentGrid>
              <Quote1Wrap id="home-customer-section-quote-1">
                <Link
                  className={QuoteCardStyle}
                  to="/case-studies/oak-ridge-national-laboratory"
                >
                  <p>
                    <QuoteMarkIcon /> We can automate anything, the only thing
                    we’re limited by is our own mindset and time to do it.
                  </p>
                  <LogoOakRidge />
                </Link>
              </Quote1Wrap>
              <Quote2Wrap id="home-customer-section-quote-2">
                <Link className={QuoteCardStyle} to="/case-studies/mars">
                  <p>
                    <QuoteMarkIcon /> An opportunity to bring automation into
                    areas of the organization that just don’t have developers.
                  </p>
                  <LogoMars20h />
                </Link>
              </Quote2Wrap>
              <Quote3Wrap id="home-customer-section-quote-3">
                <Link className={QuoteCardStyle} to="/case-studies/intercom">
                  <p>
                    <QuoteMarkIcon /> No matter how many tools you change, you
                    can keep Tines in place.
                  </p>
                  <LogoIntercom />
                </Link>
              </Quote3Wrap>
              <Stat1Wrap id="home-customer-section-stat-1">
                <Link className={StatCardStyle} to="/case-studies/knowbe4">
                  <LogoKnowBe4 />
                  <p>
                    <strong>50% reduction</strong>{" "}
                    <span>in time spent per ticket</span>
                  </p>
                </Link>
              </Stat1Wrap>
              <Stat2Wrap id="home-customer-section-stat-2">
                <Link className={StatCardStyle} to="/case-studies/elastic">
                  <LogoElastic36h />
                  <p>
                    <strong>750 days</strong> <span>of work time saved</span>
                  </p>
                </Link>
              </Stat2Wrap>
              <Stat3Wrap id="home-customer-section-stat-3">
                <Link className={StatCardStyle} to="/case-studies/snowflake">
                  <LogoSnowflake />
                  <p>
                    <strong>10 hours of work time</strong>{" "}
                    <span>saved every day</span>
                  </p>
                </Link>
              </Stat3Wrap>
              <Deco1 id="home-customer-section-deco-1">
                <span />
                <span />
                <span />
              </Deco1>
              <Deco2 id="home-customer-section-deco-2">
                <span />
                <span />
                <span />
              </Deco2>
              <Deco3 />
              <Deco4 />
              <Deco5 />
              <Deco6 />
              <Footer>
                <Link to={Paths.caseStudies()}>
                  <strong>
                    <b>More </b>
                    <b>case studies</b>
                  </strong>
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none">
                    <path
                      d="M1 6H13M13 6L8 1M13 6L8 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <span />
                <span />
              </Footer>
            </ContentGrid>
          </Content>
        </Wrap>
      </Positioner>
    </PageSection>
  );
};

const G2Logo = () => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="#E14F4C">
    <path d="M18.0596 18.9451C19.0288 20.5811 19.9872 22.1986 20.9449 23.8142C16.7041 26.9723 10.1061 27.354 5.22168 23.7171C-0.39917 19.5287 -1.32301 12.3819 1.68631 7.23729C5.14746 1.31993 11.6244 0.0112661 15.7947 0.970857C15.6819 1.20918 13.1842 6.24931 13.1842 6.24931C13.1842 6.24931 12.9867 6.26192 12.8751 6.26403C11.6424 6.31484 10.7244 6.59382 9.74035 7.08869C8.66075 7.63664 7.73557 8.43347 7.04566 9.40952C6.35576 10.3856 5.92213 11.5111 5.78269 12.6878C5.63719 13.8811 5.80683 15.0909 6.27559 16.2027C6.67194 17.1427 7.23258 17.9775 7.98419 18.6823C9.13719 19.7645 10.5092 20.4346 12.0997 20.6565C13.6058 20.8668 15.0542 20.6586 16.4115 19.9951C16.9206 19.7467 17.3537 19.4722 17.86 19.0958C17.9245 19.0552 17.9817 19.0037 18.0596 18.9451Z" />
    <path d="M18.0713 4.59757C17.8252 4.36205 17.5971 4.14476 17.3701 3.92606C17.2347 3.79569 17.1042 3.66006 16.9655 3.53284C16.9158 3.48692 16.8574 3.42419 16.8574 3.42419C16.8574 3.42419 16.9046 3.32676 16.9248 3.2868C17.1903 2.76846 17.6065 2.3896 18.1001 2.08819C18.646 1.75237 19.282 1.5815 19.9276 1.59718C20.7538 1.61295 21.522 1.81307 22.1702 2.3521C22.6487 2.74988 22.8941 3.25456 22.9373 3.85702C23.0094 4.87339 22.577 5.65179 21.7184 6.19502C21.2139 6.51465 20.6699 6.76173 20.1244 7.05437C19.8235 7.21594 19.5662 7.35788 19.2722 7.65017C19.0135 7.94352 19.0009 8.22004 19.0009 8.22004L22.9092 8.21513V9.90826H16.8765C16.8765 9.90826 16.8765 9.79261 16.8765 9.74459C16.8535 8.91257 16.9533 8.12962 17.3449 7.374C17.7052 6.68077 18.2652 6.17329 18.9379 5.78251C19.456 5.48146 20.0015 5.22526 20.5207 4.92561C20.841 4.74091 21.0673 4.47 21.0655 4.07712C21.0655 3.73996 20.8133 3.44031 20.453 3.34674C19.6034 3.12384 18.7386 3.47956 18.2889 4.23588C18.2234 4.34628 18.1563 4.45598 18.0713 4.59757Z" />
    <path d="M25.6271 17.1453L22.3335 11.6128H15.8158L12.501 17.2025H19.0666L22.3065 22.7087L25.6271 17.1453Z" />
  </svg>
);

const RatingStarSet = () => (
  <svg width="72" height="12" viewBox="0 0 72 12" fill="currentColor">
    <path d="M4.83444 1.11108C5.14434 0.483158 6.03973 0.483156 6.34963 1.11108L7.42319 3.28631C7.54626 3.53566 7.7841 3.70849 8.05926 3.74847L10.4598 4.09729C11.1528 4.19798 11.4295 5.04955 10.928 5.53832L9.19097 7.2315C8.99185 7.42558 8.901 7.70523 8.94804 7.97931L9.35806 10.3701C9.47644 11.0602 8.75207 11.5866 8.13228 11.2607L5.98517 10.1319C5.73905 10.0026 5.44502 10.0026 5.1989 10.1319L3.05181 11.2607C2.43202 11.5866 1.70763 11.0603 1.826 10.3701L2.23605 7.97931C2.28306 7.70523 2.1922 7.42558 1.99308 7.2315L0.256053 5.53832C-0.24537 5.04955 0.0313209 4.19798 0.72427 4.09729L3.12479 3.74847C3.39996 3.70849 3.63784 3.53566 3.7609 3.28631L4.83444 1.11108Z" />
    <path d="M20.0385 1.11108C20.3484 0.483158 21.2438 0.483156 21.5537 1.11108L22.6273 3.28631C22.7504 3.53566 22.9882 3.70849 23.2634 3.74847L25.6639 4.09729C26.3569 4.19798 26.6336 5.04955 26.1321 5.53832L24.3951 7.2315C24.196 7.42558 24.1051 7.70523 24.1521 7.97931L24.5622 10.3701C24.6805 11.0602 23.9562 11.5866 23.3364 11.2607L21.1893 10.1319C20.9431 10.0026 20.6491 10.0026 20.403 10.1319L18.2559 11.2607C17.6361 11.5866 16.9117 11.0603 17.0301 10.3701L17.4402 7.97931C17.4872 7.70523 17.3963 7.42558 17.1972 7.2315L15.4602 5.53832C14.9587 5.04955 15.2354 4.19798 15.9284 4.09729L18.3289 3.74847C18.6041 3.70849 18.8419 3.53566 18.965 3.28631L20.0385 1.11108Z" />
    <path d="M35.2417 1.11108C35.5516 0.483158 36.447 0.483156 36.7569 1.11108L37.8304 3.28631C37.9535 3.53566 38.1913 3.70849 38.4665 3.74847L40.867 4.09729C41.56 4.19798 41.8367 5.04955 41.3352 5.53832L39.5982 7.2315C39.3991 7.42558 39.3082 7.70523 39.3553 7.97931L39.7653 10.3701C39.8837 11.0602 39.1593 11.5866 38.5395 11.2607L36.3924 10.1319C36.1463 10.0026 35.8522 10.0026 35.6061 10.1319L33.459 11.2607C32.8392 11.5866 32.1149 11.0603 32.2332 10.3701L32.6433 7.97931C32.6903 7.70523 32.5994 7.42558 32.4003 7.2315L30.6633 5.53832C30.1619 5.04955 30.4385 4.19798 31.1315 4.09729L33.532 3.74847C33.8072 3.70849 34.0451 3.53566 34.1681 3.28631L35.2417 1.11108Z" />
    <path d="M50.4458 1.11108C50.7557 0.483158 51.6511 0.483156 51.961 1.11108L53.0345 3.28631C53.1576 3.53566 53.3954 3.70849 53.6706 3.74847L56.0711 4.09729C56.7641 4.19798 57.0408 5.04955 56.5393 5.53832L54.8023 7.2315C54.6032 7.42558 54.5123 7.70523 54.5594 7.97931L54.9694 10.3701C55.0878 11.0602 54.3634 11.5866 53.7436 11.2607L51.5965 10.1319C51.3504 10.0026 51.0563 10.0026 50.8102 10.1319L48.6631 11.2607C48.0433 11.5866 47.319 11.0603 47.4373 10.3701L47.8474 7.97931C47.8944 7.70523 47.8035 7.42558 47.6044 7.2315L45.8674 5.53832C45.366 5.04955 45.6426 4.19798 46.3356 4.09729L48.7361 3.74847C49.0113 3.70849 49.2492 3.53566 49.3722 3.28631L50.4458 1.11108Z" />
    <path
      d="M67.7893 3.5076L67.7893 3.50761C67.9852 3.90449 68.3638 4.17962 68.8018 4.24327L68.8737 3.74847L68.8018 4.24327L71.2024 4.59209C71.4852 4.63319 71.5981 4.98076 71.3935 5.18026L71.3934 5.18028L69.6564 6.87345C69.6564 6.87345 69.6564 6.87346 69.6564 6.87346C69.3394 7.18242 69.1948 7.62757 69.2697 8.06383C69.2697 8.06384 69.2697 8.06386 69.2697 8.06387L69.6797 10.4546L69.6797 10.4546C69.728 10.7363 69.4324 10.9511 69.1794 10.8181L69.1794 10.8181L67.0323 9.68935L67.0323 9.68934C66.6405 9.48341 66.1725 9.48341 65.7807 9.68934L65.7807 9.68935L63.6336 10.8181L63.6336 10.8181C63.3806 10.9511 63.0849 10.7364 63.1333 10.4546L63.5433 8.06383L63.5433 8.06383C63.6181 7.62757 63.4735 7.18242 63.1565 6.87346C63.1565 6.87346 63.1565 6.87345 63.1565 6.87345L61.4195 5.18028C61.2148 4.98077 61.3278 4.63319 61.6106 4.59209L64.0111 4.24327C64.4492 4.17963 64.8278 3.90452 65.0237 3.50759L66.0973 1.33236C66.2238 1.07607 66.5892 1.07606 66.7157 1.33236L67.7893 3.5076Z"
      fill="none"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61 5.46109V4.4044C61.125 4.24656 61.309 4.13067 61.5387 4.09729L63.9392 3.74847C64.2144 3.70849 64.4523 3.53566 64.5754 3.28631L65.6489 1.11108C65.9588 0.483158 66.8542 0.483156 67.1641 1.11108L68.2376 3.28631C68.3607 3.53566 68.5986 3.70849 68.8737 3.74847L69 3.76682V7L69.5 10L69.1648 11.3407C69.0917 11.3247 69.0185 11.2984 68.9467 11.2607L66.7996 10.1319C66.5535 10.0026 66.2595 10.0026 66.0134 10.1319L63.8663 11.2607C63.2465 11.5866 62.5221 11.0603 62.6405 10.3701L63.0505 7.97931C63.0975 7.70523 63.0067 7.42558 62.8075 7.2315L61.0705 5.53832C61.045 5.51346 61.0215 5.48767 61 5.46109Z"
    />
  </svg>
);

const QuoteMarkIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C2.23858 0 0 2.23858 0 5V14C0 16.7614 2.23858 19 5 19H14C16.7614 19 19 16.7614 19 14V5C19 2.23858 16.7614 0 14 0H5ZM9.08997 8.36134H9.34131V7H8.92241C7.56518 7 6.74414 7.85714 6.74414 9.33613V11H8.36946V9.45378C8.36946 8.73109 8.63756 8.36134 9.08997 8.36134ZM12.0055 8.36134H12.2568V7H11.8379C10.464 7 9.64291 7.85714 9.64291 9.33613V11H11.2682V9.45378C11.2682 8.73109 11.5363 8.36134 12.0055 8.36134Z"
    />
  </svg>
);
